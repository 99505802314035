import React, { useCallback } from 'react'
import ImageContainer from '../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ListProducts from './components/list-products'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import withGroup from './hoc/withDailyNewGroup'
import { TabSwiperDaliyNew } from './components/swiper-tabs'
import { FormattedMessage } from 'react-intl'
import { Types } from './index'
import withObserver from '../hoc/withObserver'
import { getStyleObjectFromString } from '../../../../utils/style'
import { useApp } from '../../../../hooks/common'
import { getSensorsUrl } from '../../../../utils/sensor'








export default withGroup(withObserver(props => {
    useStyles(classes)

    const { loading, changeHandle, loadHandle, selectedMenu, menus, data, originMenus, sensors, abTest, innerRef } = props
    const isApp = useApp()
    const styles = getStyleObjectFromString(data?.style)

    const aspectRatio = data?.width && data?.height ? `${data?.width} / ${data?.height}` : undefined

    const url = isApp ? 'chic-me://chic.me/newarrival': `/i/new-arrivals?rangeId=${selectedMenu?.id}`

    const clickHandle = useCallback(e => {
        e.preventDefault()
        const href = e.currentTarget?.href
        window.location.href = getSensorsUrl(href, {
            resourcepage_title: sensors?.title,
            resource_type: '1',
            resource_content: data?.refId,
            resource_position: sensors?.position,
        })
    }, [])

  
    return <div ref={innerRef} id={data?.id} className={classes.ListContainer} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>

        {
            data?.styledTitle && <div className={classes.Title}>
                <span dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div>
        }

        {
            data?.src && <ImageContainer href={isApp ? data?.appHref : data?.href} style={{ aspectRatio, marginBottom: 2 }} sensors={{
                ...sensors,
                refId: data?.refId,
                type: '1',
            }}>
                <LazyLoadImage alt={data?.title} src={data?.src} />
            </ImageContainer>
        }

        <div>
            <TabSwiperDaliyNew
                sensors={
                    {
                        ...sensors,
                        type: '1',
                    }
                }
                items={originMenus} onTab={
                    item => {
                        changeHandle(item.id)
                    }
                } />
        </div>

        <div className={classes.ListProducts}>
            <ListProducts sensors={
                    {
                        resourcepage_title: sensors?.title,
                        resource_position: sensors?.position,
                        resource_content: selectedMenu?.refId ||  selectedMenu?.id,
                        resource_type: '1',
                    }
                } column={selectedMenu?.id} products={selectedMenu?.data?.products} />

            <div className={classes.ViewAll}>
                <a onClick={clickHandle} href={url}><FormattedMessage id="view_all" defaultMessage="View All" />{' >'}</a>
            </div>
        </div>
    </div>
}))