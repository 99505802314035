import Type1 from './type-1'
import Type2 from './type-2'


export const Types = {
    TYPES_1: 'video-1',
    TYPES_2: 'video-2'
}

export default type => {
    switch(type){
        case Types.TYPES_1:
            return Type1
        case Types.TYPES_2:
            return Type2    
        default:
            return
    }
}