import React, { useEffect, useRef, useState, useCallback } from 'react'
import withSource from '../../../../hocs/source-scoll'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getStyleObjectFromString } from '../../../../utils/style'
import Swiper from '../../../../components/swiper/autowidth'
import { useApp } from '../../../../hooks/common'
import { getSensorsUrl } from '../../../../utils/sensor'

const Image = withSource(props => {
    useStyles(classes)
    const isApp = useApp()
    const { image, sensors, innerRef, position, active } = props
    const { width, height, title } = image || {}
    const href = isApp ? image?.appHref : image?.href
    const aspectRatio = width && height ? `${width} / ${height}` : undefined

    const clickHandle = useCallback(e => {
        e.preventDefault()
        const href = e.currentTarget?.href
        window.location.href = getSensorsUrl(href, {
            resourcepage_title: sensors?.title,
            resource_type: sensors?.type,
            resource_content: image?.refId,
            resource_position: `${sensors?.position || 1}-${(position || 0) + 1}`,
        })
    }, [])

    return <a
        href={href}
        title={title}
        ref={innerRef}
        className={`${classes.Image} ${active ? classes.Active : ''}`}
        data-position={`${sensors?.position || 1}-${(position || 0) + 1}`}
        data-type={sensors?.type}
        data-content={image?.refId}
        data-title={sensors?.title}
        style={{
            aspectRatio,
        }}
        onClick={clickHandle}
    >
        <LazyLoadImage alt={image?.title} src={image.src} />
    </a>
})


export default props => {
    useStyles(classes)
    const isApp = useApp()
    const { data, sensors } = props
    
    const { images, style, src, width, height, href, appHref, background } = data
    const [activeIndex, setActiveIndex] = useState(0)
    const countRef = useRef(null)

    const styles = getStyleObjectFromString(style)

    const aspectRatio = width && height ? `${width} / ${height}` : undefined

    countRef.current = () => {
        const nextIndex = activeIndex + 1 >= images?.length ? 0 : activeIndex + 1
        setActiveIndex(nextIndex)
    }

    useEffect(() => {
        const id = setInterval(() => {
            countRef.current()
        }, 1500)
        return () => clearInterval(id)
    }, [])

    const url = isApp ? appHref : href

    return <div className={classes.Container5} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>


        {
            data?.styledTitle && <div className={classes.Title}>
                <a href={url}>
                    {
                        data?.styledTitle
                    }
                </a>
            </div>
        }

        <div>
            {
                src && <a href={url} className={classes.TitleImage} style={{ aspectRatio }}>
                    <LazyLoadImage src={src} />
                </a>
            }
        </div>
        <div className={classes.Images} style={{ background }}>
            <Swiper options={{
                spaceBetween: 10,
                slidesPerView: 'auto',
            }} items={images} render={(image, index) => {

                <Image active={activeIndex === index} image={image} position={index} sensors={{
                    ...sensors,
                    type: '1',
                }} />

            }} />
        </div>
    </div>
}