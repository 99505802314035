import React, { useState } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import ProductShare from "../../../details/components/product-share/index";

export default props =>{
    useStyles(classes);
    const { fetchParams, sharePrefix, shareImageurl } = props;
    const [show, setShow] = useState(false);

    const clickHandle = () => {
        setShow(true)
	}

    return <React.Fragment>
        <span onClick={clickHandle}>
			{
				props.render()
			}
		</span>

        <ProductShare 
            showShareModal={show} 
            onClose={() => setShow(false)} 
            fetchParams={fetchParams}
            sharePrefix={sharePrefix}
            shareImageurl={shareImageurl}
        />
    </React.Fragment>
}