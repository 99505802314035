import React from 'react'
import SiginAnniversary from '../../../../layout/fragments/sigin-anniversary'
import { getStyleObjectFromString } from '../../../../utils/style'

export default props => {
    const {data, sensors, innerRef} = props
    const styles = getStyleObjectFromString(data?.style)
    
    return <div ref={innerRef} 
                id={data?.id} 
                data-position={`${sensors?.position || 1}-${(0 || 0) + 1}`}
                data-type={sensors?.type}
                data-content={sensors?.refId}
                data-title={sensors?.title}
                style={{
                    paddingTop: `${data?.marginTop}`, 
                    ...styles,
                }}>
        <SiginAnniversary backgoundImage={data?.backgroundUrl} backgroundColor={data?.backgroundColor} backgroundImageBottom={data?.backgroundImageBottom}/>
    </div>
}