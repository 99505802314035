import Type1 from './type-1'
import Type2 from './type-2'
import Type3 from './type-3'
import Type5 from './type-5'
import Type6 from './type-6'

export const Types = {
    TYPES_1: 'images-1',
    TYPES_2: 'images-2',
    TYPES_3: 'images-3',
    TYPES_5: 'images-5',
    TYPES_6: 'images-6'
}

export default type => {
    switch(type){
        case Types.TYPES_1:
            return Type1
        case Types.TYPES_2:
            return Type2
        case Types.TYPES_3:
            return Type3
        case Types.TYPES_5:
            return Type5
        case Types.TYPES_6:
            return Type6    
        default:
            return
    }
}