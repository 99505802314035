import React from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Types } from './index'
import withObserver from '../hoc/withObserver'
import { getStyleObjectFromString } from '../../../../utils/style'
import Swiper from '../../../../components/swiper/autowidth'
import { FormattedMessage } from 'react-intl'
import withSource from '../../../../hocs/source-scoll'
import { useApp } from '../../../../hooks/common'


const Blog = withSource(props => {
    useStyles(classes)
    const isApp = useApp()
    const { image, sensors, innerRef, position } = props
    const { width, height, title } = image || {}
    const aspectRatio = width && height ? `${width} / ${height}` : undefined

    return <div className={classes.Blog}>
        <div className={classes.BlogImage} style={{
            aspectRatio,
        }}>
            <LazyLoadImage src={image.imgUrl} alt={title} />
        </div>
        <div className={classes.BlogTitle}>
            <span dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <div className={classes.LearnMore}>
            <a
                data-position={`${sensors?.position || 1}-${(position || 0) + 1}`}
                data-type={sensors?.type}
                data-content={image?.refId}
                data-title={sensors?.title}
                ref={innerRef}
                href={`/i/blog?messageId=${image.messageId}`}><FormattedMessage id="learn_more" defaultMessage={'Learn More'} />{' >'}</a>
        </div>
    </div>
})

export default withObserver(props => {
    useStyles(classes)
    const { data, sensors, innerRef } = props
    const images = data?.images
    const styledTitle = data?.styledTitle || data?.title
    const styles = getStyleObjectFromString(data?.style)

    return <div className={classes.Container} ref={innerRef} id={data?.id} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        <div className={classes.Title}>
            <span dangerouslySetInnerHTML={{ __html: styledTitle }} />
        </div>
        <div className={classes.Bd}>
            <Swiper options={{
                spaceBetween: 10,
                slidesPerView: 'auto',
            }} items={images || data?.blogs} render={(item, index) => {
                return <Blog image={item} position={index} sensors={{
                    ...sensors,
                    type: Types.TYPES_1
                }} />
            }} />
        </div>
    </div>
})