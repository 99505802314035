import React, { useCallback } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import GroupImages from '../../../../components/card/images/gourp-images'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { FullSwiper } from '../../components/swiper'
import withObserver from '../hoc/withObserver'
import withSource from '../../../../hocs/source-scoll'
import { getStyleObjectFromString } from '../../../../utils/style'
import { getSensorsUrl } from '../../../../utils/sensor'
import { useApp } from '../../../../hooks/common'

const PageImages = props => {
    const { images, sensors } = props
    return <GroupImages images={images} sensors={sensors} render={(item, index) => {
        return <LazyLoadImage alt={item?.title} src={item.src} />
    }} />
}

const Image = withSource(props => {
    const { image, sensors, innerRef, position } = props
    const { width, height, title, href, appHref } = image
    const aspectRatio = width && height ? `${width} / ${height}` : undefined
    const isApp = useApp()

    const imagePosition = `${sensors?.position || 1}-${position + 1}`

    let ratio
    if (width && height) {
        ratio = `${Math.floor(height * 100 / width)}%`
    }


    const clickHandle = useCallback(e => {
        e.preventDefault()
        const href = e.currentTarget?.href
        window.location.href = getSensorsUrl(href, {
            resourcepage_title: sensors?.title,
            resource_type: sensors?.type,
            resource_content: image.refId,
            resource_position: imagePosition,
        })
    }, [])

    return <a
        ref={innerRef}
        data-position={imagePosition}
        data-type={sensors?.type}
        data-content={image.refId || image.title}
        data-title={sensors?.title}
        onClick={clickHandle}
        href={isApp ? appHref : href} title={title}>
        <div className={`${classes.Image} ${!ratio ? classes.Static : ''}`} style={{
            aspectRatio,
            paddingTop: ratio,
        }}>
            <LazyLoadImage alt={image?.title} src={image.src} />
        </div>
    </a>
})

const SwiperImages = props => {
    useStyles(classes)
    const { images, sensors } = props

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return `
                <span class="${className}"></span>
            `
        },
        clickableClass: classes.SwiperPagination,
        bulletActiveClass: classes.Active,
        bulletClass: classes.Pagegation
    }

    return <FullSwiper className={classes.NormalSwiper} items={images} options={{
        pagination: pagination,
        loop: true,
        autoplay: {
            delay: 3000,
        },
    }} render={(item, index) => {
        return <Image sensors={sensors} image={item} position={index} />
    }} />
}



export default withObserver(props => {
    useStyles(classes)
    const { data, sensors, innerRef } = props
    const { groups, styledTitle } = data



    const styles = getStyleObjectFromString(data?.style)


    return <div ref={innerRef} id={data?.id} className={classes.Container} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        {
            styledTitle && <div className={classes.Title}>
                <span dangerouslySetInnerHTML={{ __html: styledTitle }} />
            </div>
        }

        <div className={classes.Groups}>

            {
                groups?.map?.((group, index) => group?.type === '1' ? <div key={index} style={{
                    width: isNaN(group.percent) ? group.percent : `${Number(group.percent) * 100}%`
                }}>
                    <SwiperImages sensors={{
                        ...sensors,
                        type: '1',
                        position: `${sensors.position}-${index + 1}`
                    }} images={group?.images} />
                </div> : <div key={index} style={{
                    width: isNaN(group.percent) ? group.percent : `${Number(group.percent) * 100}%`
                }}>
                    <PageImages sensors={{
                        ...sensors,
                        type: '1',
                        position: `${sensors.position}-${index + 1}`
                    }} images={group?.images} />
                </div>)
            }

        </div>
    </div>
})