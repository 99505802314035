import React, { useCallback, useState } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import GroupImages from '../../../../components/card/images/gourp-images'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import withObserver from '../hoc/withObserver'
import {getStyleObjectFromString} from '../../../../utils/style'
import PopupVideo from './fragments/popup-video'


export default withObserver(props => {
    useStyles(classes)
    const {data, sensors, innerRef} = props
    const images = data?.images
    const styledTitle = data?.styledTitle
    const styles = getStyleObjectFromString(data?.style);
    const [imageData, setImageData] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const showVideoEvent = useCallback((imageData) =>{
        setImageData(imageData);
        setShowModal(true);
    }, []);

    return <div ref={innerRef} id={data?.id} style={{paddingTop: `${data?.marginTop}`, ...styles}}>
        <GroupImages 
            special={data?.special} 
            direction={data?.direction} 
            images={images} 
            title={styledTitle ? <span dangerouslySetInnerHTML={{__html: styledTitle}}/>:''} 
            sensors={{
                ...sensors,
                type: '1',
            }} 
            render={(image, index) => {
                return sensors?.position == '1' && index === 0 ? <img alt={image?.title} src={image.src}/> :<LazyLoadImage alt={image?.title} src={image.src}/>
            }} 
            showVideoEvent={(data) =>showVideoEvent(data)}
        />

        <PopupVideo 
            show={showModal} 
            imageData={imageData} 
            onClose={() =>setShowModal(false)} 
        />
    </div>
})