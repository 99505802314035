import React, { useCallback } from 'react'
import withList from './hoc/withList'
import SwiperProducts from './components/swiper-products'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { getStyleObjectFromString } from '../../../../utils/style'
import { Types } from './index'
import withObserver from '../hoc/withObserver'
import CountDown from '../../../../components/count-down'
import { Iconfont } from '../../../../components/icon/iconfont'
import { useApp, useLanguage } from '../../../../hooks/common'
import { FormattedMessage } from 'react-intl'
import withSource from '../../../../hocs/source-scoll'
import { getSensorsUrl } from '../../../../utils/sensor'


const FlashTitle = withSource(props => {
    useStyles(classes)
    const { offset, innerRef, sensors, url } = props
    const language = useLanguage()

    const clickHandle = useCallback(e => {
        e.preventDefault()
        const href = e.currentTarget?.href
        window.location.href = getSensorsUrl(href, {
            resourcepage_title: sensors?.title,
            resource_type: sensors?.type,
            resource_content: sensors?.refId,
            resource_position: sensors?.position,
        })
    }, [])

    return <a ref={innerRef}
        data-position={sensors?.position}
        data-type={sensors?.type}
        data-content={sensors?.refId}
        data-title={sensors?.title}
        className={classes.Hd} href={url}
        onClick={clickHandle} >
        <div className={classes.Name}>
            <Iconfont className={classes.Icon}>&#xe76a;</Iconfont>
            <span className={classes.Text}>
                <FormattedMessage id="flash_sale" defaultMessage="Flash Deals" />
            </span>
        </div>
        <div className={classes.CountDown}>
            {
                language !== 'pt' && <span className={classes.Text}>
                    <FormattedMessage id="ends_in" defaultMessage="Ends In" />
                </span>
            }

            {
                offset && <CountDown showDay showHour offset={offset} />
            }
            <Iconfont className={classes.Icon}>&#xe690;</Iconfont>
        </div>
    </a>
})

export default withList(withObserver(props => {
    useStyles(classes)
    const { products, data, sensors, innerRef } = props

    const isApp = useApp()
    const url = isApp ? data?.appHref : data?.href


    const dark = !!data?.backgroundUrl
    let backgroundImage

    if (data?.backgroundUrl) {
        backgroundImage = `url(${data?.backgroundUrl})`
    }

    const styles = getStyleObjectFromString(data?.style)

    const offset = products?.[0]?.promotion?.endTime - products?.[0]?.promotion?.serverTime

    return <div ref={innerRef} id={data?.id} className={`${classes.FlashContainer} ${dark ? classes.Dark : ''}`} style={{ marginTop: `${data?.marginTop}`, ...styles, backgroundImage }}>

        <FlashTitle sensors={{
            ...sensors,
            refId: data?.refId,
            type: '1',
        }} offset={offset} url={url} />

        <div className={classes.SwiperProducts}>
            <SwiperProducts isTypeFlash={true} sensors={{
                resourcepage_title: sensors?.title,
                resource_position: sensors?.position,
                resource_content: data?.refId,
                resource_type: '1',
            }} column={data?.refId} products={products} moreUrl={getSensorsUrl(url, {
                resourcepage_title: sensors?.title,
                resource_type: '1',
                resource_content: data?.refId,
                resource_position: sensors?.position,
            })} />
        </div>
    </div>
}))