import React, { useEffect, useState, useCallback } from 'react'
import ImageContainer from '../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ListProducts from './components/list-products'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import withGroup from './hoc/withGroup'
import { TabSwiper4 } from './components/swiper-tabs'
import { FormattedMessage } from 'react-intl'
import withObserver from '../hoc/withObserver'
import { getStyleObjectFromString } from '../../../../utils/style'
import { useApp, useQuery } from '../../../../hooks/common'
import ViewMore from './components/view-more'

export default withGroup(withObserver(props => {
    useStyles(classes)
    const { collections, data, sensors, innerRef } = props
    const [collection, setCollection] = useState()
    const [position, setPosition] = useState(1)
    const isApp = useApp()
    const query = useQuery()

    const id = data?.id

    const selectedIndex = query?.locationModule === id ? Number(query?.selectedIndex) : 0

    const styles = getStyleObjectFromString(data?.style)

    useEffect(() => {
        setCollection(collections?.[selectedIndex || 0] || collections?.[0])
    }, [collections])

    const aspectRatio = data?.width && data?.height ? `${data?.width} / ${data?.height}` : undefined

    return <div ref={innerRef} id={data?.id} className={classes.ListContainer} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>

        {
            data?.styledTitle && <div className={classes.Title}>
                <span dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div>
        }

        {
            data?.src && <ImageContainer style={{ aspectRatio, marginBottom: 12 }} href={isApp ? data?.appHref : data?.href} sensors={{
                ...sensors,
                refId: data?.refId,
                type: '1',
            }}>
                <LazyLoadImage alt={data?.title} src={data?.src} />
            </ImageContainer>
        }

        <div>
            <TabSwiper4
                selectedIndex={selectedIndex}
                theme={data?.theme}
                sensors={
                    {
                        ...sensors,
                        type: '1',
                    }
                }
                items={collections} onTab={
                    (item, index) => {
                        setCollection(item)
                        setPosition(index + 1)
                    }
                } />
        </div>

        <div className={classes.ListProducts}>
            <ListProducts sensors={
                {
                    resourcepage_title: sensors?.title,
                    resource_position: `${sensors?.position}-${position}`,
                    resource_content: collection?.refId || collection?.id,
                    resource_type: '1',
                }
            } column={collection?.refId || collection?.id} products={collection?.products} />

            <div className={classes.ViewAll}>
                <ViewMore sensors={
                    {
                        resourcepage_title: sensors?.title,
                        resource_position: `${sensors?.position}-${position}`,
                        resource_content: collection?.refId || collection?.id,
                        resource_type: '1',
                    }
                } href={isApp ? collection?.appHref : collection?.href}><FormattedMessage id="view_all" defaultMessage="View All" />{' >'}</ViewMore>
            </div>
        </div>
    </div>
}))