import React, { useMemo, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Iconfont } from '../../components/icon/iconfont'
import { subscribe } from '../../../api'
import message from '../../components/alert'
import {BigButton} from '../../components/button'
import Cache from '../../../utils/cache'
import { IMAGE_GEEKO_LTD } from '../../../constants'


const SOCIAL_ICONS = id => {
	switch(id){
		case 'facebook':
			return <Iconfont>&#xe647;</Iconfont>
		case 'pintrest':
			return <Iconfont>&#xe65f;</Iconfont>
		case 'ins':
			return <Iconfont>&#xe64a;</Iconfont>	
		case 'tiktok':
			return <Iconfont>&#xe79b;</Iconfont>
		case 'youtube':
			return <Iconfont>&#xe79d;</Iconfont>
		default:
			return ''		
	}
}

const Social = props => {
	useStyles(classes)
	const {social} = props
	return <a className={`${classes.Social} ${social.id === 'youtube'?classes.youtube:''}`} href={social.url} title={social.id}>
		{SOCIAL_ICONS(social.id)}
	</a>
}

const SubscribeArea = props => {

	useStyles(classes)

	const intl = useIntl()

	const [email, setEmail] = useState()

	const [loading, setLoading] = useState(false)

	const global = useSelector((state) => state.global)
	const { config } = global



	const submitEmail = (evt) => {
		evt.preventDefault()
		setLoading(true)
		subscribe(email).then(data => {
			message(`${intl.formatMessage({id: 'thanks_subscribe'})} \n ${intl.formatMessage({id: 'thanks_enjoy'})}`)
			setLoading(false)
			Cache.set("customerEmail",email,(365*24*60*60)*1000);
		}).catch(data => {
			alert(data.result || data)
			setLoading(false)
		})
	}


	return <div>
		<div className={classes.SubscribeTitle}><FormattedMessage id="subscribe_title" defaultMessage="Sign-up for exclusive offers!"/></div>
		<form onSubmit={submitEmail} action="/">
			<div className={classes.SubscribeInput}>
				<div className={classes.Input}>
					<input placeholder={intl.formatMessage({id: 'email', defaultMessage:'Email'})} value={email} type="email" onChange={evt => {setEmail(evt.target.value)}}/>
				</div>
				<BigButton type="submit" loading={loading} className={classes.Button}><FormattedMessage id="register" defaultMessage="Register"/></BigButton>
			</div>
		</form>
		
		<div className={classes.FootDesc}>
			<input type="checkbox" checked/>
			<FormattedMessage id="smufcme" values={{ brand: config?.name }} defaultMessage="Sign me up for {brand} emails (you can unsubscribe at any time)."/>
		</div>

		<div className={classes.Socials}>
			{
				config?.socials?.map(social => <Social key={social.id} social={social}/>)
			}
		</div>
	</div>
}


const PolicyAcc = props => {

	const [show, setShow] = useState(false)

	const toggleShow = () => {
		setShow(!show)
	}

	return <div className={`${classes.PolicyAcc} ${show ? classes.Active : ''}`}>
		<div className={classes.Hd} onClick={() => { toggleShow() }}>
			{
				props.title
			}
		</div>
		<div className={classes.Bd}>
			{
				props.children
			}
		</div>
	</div>
}



export default props => {
	useStyles(classes)

	const global = useSelector((state) => state.global)
	const { config, country, payImageMessages } = global;

	const payImage = useMemo(() =>{
		if(country && payImageMessages?.length > 0){
			let data = payImageMessages.find(item =>item?.lang === country);
            if(data?.imageUrl) return data?.imageUrl
            let defaultData = payImageMessages.find(item =>item?.lang === 'other');
			return defaultData?.imageUrl || `${IMAGE_GEEKO_LTD}/chicme/20211130/1.png`;
		}

		return "https://s3-us-west-2.amazonaws.com/wanna/m_default.png";
	}, [country, payImageMessages]);


	return <div className={classes.Footer}>

		<PolicyAcc title={<FormattedMessage id="c_info" defaultMessage="{brand} Info" values={{ brand: config?.name }} />}>
			<div className={classes.PolicyLines}>
				<a className={classes.PolicyLine} href="/policy/about-us">
					<FormattedMessage id="about_us" defaultMessage="About Us" />
				</a>
				<a className={classes.PolicyLine} href="/policy/affiliate-policy">
					<FormattedMessage id="affiliate" defaultMessage="Affiliate" />
				</a>
				<a className={classes.PolicyLine} href="/policy/blogger-program">
					<FormattedMessage id="blogger_program" defaultMessage="Blogger Program" />
				</a>
			</div>

		</PolicyAcc>


		<PolicyAcc title={<FormattedMessage id="help_support" defaultMessage="Help & Support" />}>
			<div className={classes.PolicyLines}>
				<a className={classes.PolicyLine} href="/policy/shipping-policy">
					<FormattedMessage id="shipping_policy" defaultMessage="Shipping Policy" />
				</a>
				<a className={classes.PolicyLine} href="/user/support">
					<FormattedMessage id="faq" defaultMessage="FAQ" />
				</a>
				<a className={classes.PolicyLine} href="/policy/order-tracking">
					<FormattedMessage id="order_tracking" defaultMessage="Order Tracking" />
				</a>
				<a className={classes.PolicyLine} href="/policy/coupon-credits">
					<FormattedMessage id="coupons" defaultMessage="Coupons" />
				</a>
				<a className={classes.PolicyLine} href="/policy/bonus-point">
					<FormattedMessage id="bonus_points" defaultMessage="Bonus Points" />
				</a>
			</div>
		</PolicyAcc>

		<PolicyAcc title={<FormattedMessage id="customer_care" defaultMessage="Customer Care" />}>
			<div className={classes.PolicyLines}>
				<a className={classes.PolicyLine} href="/user/support">
					<FormattedMessage id="contact_us" defaultMessage="Contact Us" />
				</a>
				<a className={classes.PolicyLine} href="/policy/return-policy">
					<FormattedMessage id="return_policy" defaultMessage="Return Policy" />
				</a>
				<a className={classes.PolicyLine} href="/policy/out-of-stock">
					<FormattedMessage id="out_of_stock" defaultMessage="Out of stock" />
				</a>

				{
					!config?.hiddenFooterPolicy && <React.Fragment>
						<a className={classes.PolicyLine} href="/policy/wholesale-program">
							<FormattedMessage id="wholesale_policy" defaultMessage="Wholesale Policy" />
						</a>

						<a className={classes.PolicyLine} href="/policy/drop-shipping-policy">
							<FormattedMessage id="drop_shipping_policy" defaultMessage="Drop Shipping Policy" />
						</a>
					</React.Fragment>
				}

				<a className={classes.PolicyLine} href="/policy/copyright">
					<FormattedMessage id="i_p_rights" defaultMessage="Intellectual Property Rights" />
				</a>

				<a className={classes.PolicyLine} href="/policy/privacy-security-policy">
					<FormattedMessage id="privacy_policy" defaultMessage="Privacy & Security Policy Terms" />
				</a>

				<a className={classes.PolicyLine} href="/policy/terms-conditions-notice">
					<FormattedMessage id="term_notice" defaultMessage="Terms & Conditions Notice" />
				</a>

				<a className={classes.PolicyLine} href="/policy/how-to-pay-policy">
					<FormattedMessage id="how_to_pay" defaultMessage="How To Pay" />
				</a>
			</div>
		</PolicyAcc>

		<SubscribeArea/>

		<div className={classes.FooterImage}>
			<LazyLoadImage src={payImage} />
		</div>
		
	</div>
}