import React, { useCallback, useEffect, useRef, useState } from 'react'
import Showing from '../item'
import { Swiper, SwiperSlide } from 'swiper/react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getLowerPrice, unitPrice } from '../../../../../../utils/product'
import { IMAGE_GEEKO_LTD } from '../../../../../../constants'
import { useQuery, useWebp } from '../../../../../hooks/common'
import {withCartIcon} from '../../../../listing/components/cart/cart-icon'
import CLogLike from "../../../components/like/index";
import CLogComment from "../../../components/comment/index";
import CLogShare from "../../../components/share/index";
import { CLogShopLook } from "../../../components/shop/index";
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'


const getNumber = px => Number(px?.split('px')?.[0]) || 0

const Product = withCartIcon(props => {
    useStyles(classes)
    const { product, onCart } = props
    const lower = getLowerPrice(product)
    const isWebp = useWebp()
    const imageUrl = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp' : ''}/medium/${product?.pcMainImage}`

    return <div className={classes.Product} onClick={(e)=>onCart(e)}>
        <div className={classes.Image}>
            <LazyLoadImage src={imageUrl} />
        </div>
        <div className={classes.Price}>
            {unitPrice(lower)}
        </div>
    </div>
})

export const ProductSwiper = props => {
    useStyles(classes)
    const { products, sensors } = props
    return <Swiper
        slidesPerView="auto"
    >
        {
            products?.map((product, index) => <SwiperSlide className={classes.ProductSlide} key={`${product.id}-${index}`}>
                <Product product={product} productId={product.id} sensors={sensors} />
            </SwiperSlide>)
        }
    </Swiper>
}

export default props => {
    useStyles(classes)
    const { showings, request, loading, finished } = props

    const [swiper, setSwiper] = useState(null)
    const [activeIndex, setActiveIndex] = useState(0)
    const [showing, setShowing] = useState(showings?.[0])
    const [pageX, setPageX] = useState(0)
    const [pageY, setPageY] = useState(0)
    const [transX, setTransX] = useState(0)
    const [startOffsetX, setStartOffsetX] = useState(0)
    const [offsetX, setOffsetX] = useState(0)
    const [hideInfo, setHideInfo] = useState(false)
    const infoRef = useRef()
    const [canOffset, setCanOffset] = useState(true)
    const [fullScreen, setFullScreen] = useState(false)
    let ids = useRef([])
    const query = useQuery()

    useEffect(() =>{
        setShowing(showings?.[activeIndex])
        let showing = showings?.[activeIndex]
        exposeVideo(showing, activeIndex)
    },[showings])

    const exposeVideo = (showing, index) =>{
        if(showing){
            let { youtubeId }  = showing;
            let exposeed = ids.current.includes(youtubeId);
            if(!exposeed){
                if(window.GeekoSensors){
                    window.GeekoSensors.Track("ClogExpose", {
                        type: '视频',
                        clog_id: youtubeId,
                        position: index+1,
                        tab: query?.tab
                    })

                    if(showing?.products?.length > 0){
                        window.GeekoSensors.Track("PitPositionExposure", {
                            resourcepage_title: 'clog',
                            resource_position: '1',
                            resource_type: '20',
                            resource_content:'shop the look'
                        })
                    }
                    
                }

                ids.current.push(youtubeId)
            }
        }
    }

    const slideChangeHandle = async swiper => {
        // if (swiper.isEnd && !loading && !finished) {
        //     request()
        // }

        if (showings?.length - swiper?.activeIndex <= 3 && !loading && !finished) {
            request()
        }

        let showing = showings?.[swiper?.activeIndex];
        let index = swiper?.activeIndex;
        setActiveIndex(index)
        setShowing(showing)

        exposeVideo(showing, index);
    }

    const startHandle = event => {
        try {
            setHideInfo(false)
            setPageX(event.changedTouches?.[0]?.pageX)
            setPageY(event.changedTouches?.[0]?.pageY)
            startOffsetX(getNumber(infoRef.current?.style?.left))
            infoRef.current.classList.remove(classes.HideInfo)
        } catch (error) {

        }

    }

    const moveHandle = event => {

        if (canOffset) {
            const offset = event.changedTouches?.[0]?.pageX - pageX
            const offsetY = event.changedTouches?.[0]?.pageY - pageY

            const jiaodu = Math.atan(Math.abs(offsetY / offset)) / (Math.PI / 180)

            if (jiaodu < 30) {
                setTransX(offset)
                setOffsetX(startOffsetX + offset)
            }
        }

    }

    const endHandle = event => {
        infoRef.current?.classList.add(classes.HideInfo)
        setHideInfo(true)
        if (transX < -100) {
            setTransX(-document.body.clientWidth)
            setOffsetX(-document.body.clientWidth)
            setStartOffsetX(-document.body.clientWidth)
            setFullScreen(true)
        } else {
            setTransX(0)
            setOffsetX(0)
            setStartOffsetX(0)
            setFullScreen(false)
        }
    }

    const stopHandle = useCallback(event => {
        event.preventDefault()
        event.stopPropagation()
    }, [])

    const handleClick = (tag) =>{
        if(window.GeekoSensors){
			window.GeekoSensors.Track("ClogTagClick", {
				tab:tag?.value
			})
		}
    }

    return <div className={classes.VideoClog} onTouchMove={moveHandle} onTouchStart={startHandle} onTouchEnd={endHandle}>
        <CLogShare 
            fetchParams={{urlCode: "CLI1", params: JSON.stringify({ id: showing?.id })}}
            // sharePrefix={`${config?.name} ${selectedProduct?.name} `}
            shareImageurl={showing?.mainImage}
            render={() =><span className={classes.FixedIcon}>&#xe7b1;</span>}
        />

        
        <Swiper
            onSwiper={setSwiper}
            onSlideChange={slideChangeHandle}
            direction={"vertical"}
            className={classes.Swiper}

        >
            {showings?.map((item, index) => {
                return <SwiperSlide className={classes.SwiperSlide} key={`${item?.id}-${index}`}>
                    {
                        Math.abs(activeIndex - index) < 2 ? <Showing actived={activeIndex === index} showing={item} stopHandle={setCanOffset} /> : <React.Fragment />
                    }
                </SwiperSlide>
            
            })}

            {
                loading && <SwiperSlide className={classes.SwiperSlide}>
                    <span style={{ color: '#fff' }}><FormattedMessage id="loading" defaultMessage="Loading" /></span>
                </SwiperSlide>
            }

            {
                finished && <SwiperSlide className={classes.SwiperSlide}>
                    <span style={{ color: '#fff' }}><FormattedMessage id="finished" defaultMessage="Finished" /></span>
                </SwiperSlide>
            }

        </Swiper>

        {/* <Swiper className={classes.TransparentSwiper}>
            <SwiperSlide className={classes.TransparentSwiperSlide}>
                
            </SwiperSlide>
            <SwiperSlide className={classes.TransparentSwiperSlide}/>
        </Swiper> */}

        {
            showing && <React.Fragment>
                <div ref={infoRef} style={{
                    left: offsetX
                }} className={`${classes.Information} ${hideInfo ? classes.HideInfo : ''}`} onTouchStart={stopHandle} onTouchEnd={stopHandle} onTouchMove={stopHandle}>
                    <div className={classes.ContentBox}>
                        <div className={classes.Title}>@{showing?.customerName}</div>
                        <div className={classes.SubTitle}>
                            <span>{`${showing.views} views`}</span>{' . '}<span>{'25/07/2022'}</span>
                        </div>
                        <div className={classes.Description} onTouchStart={(e)=>{e.stopPropagation();}} onTouchEnd={(e)=>{e.stopPropagation();}} onTouchMove={(e)=>{e.stopPropagation();}}>
                            {
                                showing?.tags?.map((tag, index) => <Link onClick={(e)=>handleClick(tag)} to={`/v-shop/tag?tag=${tag.value}&tagname=${tag?.name}`} key={`${index}-${tag.value}`} className={classes.HashTag}>{'#'}{tag.name || tag.value}{' '}</Link>)
                            }
                            <span>{showing?.description}</span>
                        </div>
                    </div>
                    <div className={classes.ProductContainer} onTouchStart={(e)=>{e.stopPropagation();}} onTouchEnd={(e)=>{e.stopPropagation();}} onTouchMove={(e)=>{e.stopPropagation();}}>
                        <ProductSwiper 
                            products={showing?.products} 
                            sensors={{
                                clogId: showing?.youtubeId,
                                resourcepage_title: 'clog',
                                resource_type: '20',
                                resource_content: 'shop the look',
                                resource_position: '1'
                            }} 
                        />
                    </div>
                </div>


                <div className={`${classes.Tools} ${fullScreen? classes.Hide: ''}`}>
                    {
                        showing?.products?.length > 0 && <CLogShopLook 
                            key={showing}
                            products={showing?.products}
                            sensors={{
                                clogId: showing?.youtubeId,
                                resource_position: '1'
                            }}
                            render={()=>{
                                return <span className={classes.Bag}>
                                    <img src={`${IMAGE_GEEKO_LTD}/chicme/20220811023907389640.png`} />
                                </span>
                            }}
                        />
                    }
                    

                    <div className={classes.ToolIcons}>
                        <CLogLike 
                            liked={showing?.liked} 
                            showing={showing} 
                            numberOfLike={showing?.numberOfLike} 
                            className={`${classes?.MarinLeft0}`}
                            isWhite
                        />
                        
                        <CLogComment 
                            commentId={showing?.id} 
                            commentCount={showing?.numberOfComment} 
                            className={classes.MarinLeft35}
                            isWhite
                        />
                    </div>
                </div>
            </React.Fragment>
        }






    </div>
}