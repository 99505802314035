import React, {useCallback} from 'react'
import withCollections from './withCollections'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import Swiper from '../../../../components/swiper/autowidth'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getCollectionUrl } from '../../../../../utils/url'
import { IMAGE_GEEKO_LTD } from '../../../../../constants'
import { Types } from './index'
import {getStyleObjectFromString} from '../../../../utils/style'
import withSouce from '../../../../hocs/source-scoll'
import { getSensorsUrl } from '../../../../utils/sensor'


const Collection = withSouce(props => {
    useStyles(classes)
    const { collection, sensors, position, innerRef } = props
    const url = collection?.relatePcHref || getCollectionUrl(collection?.id, collection?.name)

    const clickHandle = useCallback(e => {
		e.preventDefault()
		const href = e.currentTarget?.href
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: sensors?.title,
			resource_type: '1',
			resource_content: sensors?.refId,
			resource_position: `${sensors?.position}-${(position || 0) + 1}`,
		})
	}, [])


    return <a
        ref={innerRef}
        data-position={`${sensors?.position || 1}-${(position || 0) + 1}`}
        data-type={sensors?.type}
        data-content={sensors?.refId}
        data-title={collection?.enName || collection?.name}
        href={url}
        onClick={clickHandle}
        className={classes.CollectionContainer}>
        <div className={classes.Image}>
            <LazyLoadImage alt={collection?.name} src={`${IMAGE_GEEKO_LTD}${collection?.pcImages?.[0]}`} />
        </div>
        <div className={classes.Name}>
            {
                collection?.name
            }
        </div>

    </a>
})


export default withCollections(props => {
    useStyles(classes)
    const { data, sensors, collections } = props
    const { styledDescription, styledTitle } = data
    const styles = getStyleObjectFromString(data?.style)

    return <div className={classes.Container3} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        {
            styledTitle && <div className={classes.Title}>
                <a href={'/i/collections'} dangerouslySetInnerHTML={{ __html: styledTitle }} />
            </div>
        }

        <div className={classes.Bd}>
            <Swiper items={collections} options={{
                slidesPerView: 'auto',
                spaceBetween: 12
                // pagination: true,
                // loop: true
            }} render={(item, index) => {
                return <Collection collection={item} position={index} sensors={{
                    ...sensors,
                    refId: item.enName|| item.name,
                    type: '1'
                }} />
            }} />
        </div>

    </div>
})