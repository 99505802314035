import React, { useEffect, useState } from 'react'

import { fetchBanner } from '../../../../../api'

import { FullSwiper } from '../../components/swiper'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import ImageContainer from '../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { getCollectionUrl } from '../../../../../utils/url'
import { IMAGE_GEEKO_LTD } from '../../../../../constants'

import { useSelector } from 'react-redux'

import { Types } from './index'
import withObserver from '../hoc/withObserver'
import { getStyleObjectFromString } from '../../../../utils/style'


export default withObserver(props => {
	useStyles(classes)

	const pageHome = useSelector((state) => state.pageHome)

	const { data, sensors, innerRef } = props
	const { width = 414, height = 386, style } = data
	const [banners, setBanners] = useState(data.data)

	const styles = getStyleObjectFromString(style)

	const aspectRatio = width && height ? `${width} / ${height}` : undefined

	useEffect(async () => {

		if (!data.data || data.data.length < 1) {
			const banners = await fetchBanner(pageHome?.currentPage || '1').then(data => data.result).catch(e => {
				console.error(e)
				return null
			})
			setBanners(banners)
		}

	}, [pageHome?.currentPage])

	return <div ref={innerRef} id={data?.id} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
		{
			banners?.length > 1 ? <FullSwiper items={banners} style={{
				aspectRatio,
				backgroundColor: '#f1f1f1'
			}} options={{
				pagination: banners?.length > 1,
				loop: banners?.length > 1,
				autoplay: true
			}} render={(item, index) => {
				const url = item.relatePcHref || getCollectionUrl(item.id, item.name)
				return <ImageContainer href={url} title={item.name} src={item.mobileImage} position={index} sensors={{
					...sensors,
					refId: item?.enName || item.name,
					type: '1'
				}}>
					{
						index === 0 ? <img alt={item.name} src={`${IMAGE_GEEKO_LTD}${item.mobileImage}`} /> : <LazyLoadImage alt={item.name} src={`${IMAGE_GEEKO_LTD}${item.mobileImage}`} />
					}
				</ImageContainer>
			}} /> : <ImageContainer href={banners?.[0]?.relatePcHref || getCollectionUrl(banners?.[0]?.id, banners?.[0]?.name)} title={banners?.[0]?.name} src={banners?.[0]?.mobileImage} sensors={{
				...sensors,
				refId: banners?.[0]?.enName || banners?.[0]?.name,
				type: '1'
			}}>
				<img alt={banners?.[0]?.name} src={`${IMAGE_GEEKO_LTD}${banners?.[0]?.mobileImage}`} />
			</ImageContainer>
		}


	</div>
})
