// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1ohG96-put6ZoyH0RT5Tfn{text-align:center;padding-top:.21333rem;padding-bottom:.32rem}._68tunult1kCko-6jpCyU8{font-size:.32rem;text-transform:uppercase;text-align:center;height:.32rem;line-height:.32rem;padding-left:.32rem;padding-right:.32rem;overflow:hidden;color:#fff;margin-bottom:.21333rem}\n", "",{"version":3,"sources":["webpack://src/client/pages/moduleable/modules/fetstival/count-down/style.module.scss","webpack://src/client/base.scss"],"names":[],"mappings":"AAEA,yBACI,iBAAkB,CAClB,qBCAoC,CDCpC,qBCDoC,CDEvC,wBAGG,gBCLoC,CDMpC,wBAAyB,CACzB,iBAAkB,CAClB,aCRoC,CDSpC,kBCToC,CDUpC,mBCVoC,CDWpC,oBCXoC,CDYpC,eAAgB,CAChB,UAAW,CACX,uBCdoC","sourcesContent":["@import \"../../../../../base.scss\";\n\n.Container{\n    text-align: center;\n    padding-top: actual(8);\n    padding-bottom: actual(12);\n}\n\n.Title{\n    font-size: actual(12);\n    text-transform: uppercase;\n    text-align: center;\n    height: actual(12);\n    line-height: actual(12);\n    padding-left: actual(12);\n    padding-right: actual(12);\n    overflow: hidden;\n    color: #fff;\n    margin-bottom: actual(8);\n}\n\n","$baseFontSize: 75; //基于视觉稿横屏尺寸/10得出的基准font-size\n$IMAGE_GEEKO_LTD: 'https://image.geeko.ltd';\n\n@function actual($px) {\n  @return 2 * $px / $baseFontSize * 1rem;\n}\n\n@function actualPc($px){\n  @return $px / 1920 * 100vw;\n}\n\n@function imageGeekoLtd($url){\n\t@return $IMAGE_GEEKO_LTD + $url;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "_1ohG96-put6ZoyH0RT5Tfn",
	"Title": "_68tunult1kCko-6jpCyU8"
};
module.exports = ___CSS_LOADER_EXPORT___;
