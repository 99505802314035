import React from 'react'
import withRec from './hoc/withGroupByInterface'
import { TabSwiper2 } from './components/swiper-tabs'
import { SimpleProducts, SimpleEmptyProducts } from '../../../listing/components/list'
import { FormattedMessage } from 'react-intl'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { Types } from './index'
import withObserver from '../hoc/withObserver'
import { getStyleObjectFromString } from '../../../../utils/style'
import { fetchNewCategoryList } from '../../../../../api'

const ListTypeNew = withRec(withObserver(props => {

    useStyles(classes)

    const { loading, changeHandle, loadHandle, selectedMenu, menus, data, originMenus, sensors, abTest, innerRef } = props

    const styles = getStyleObjectFromString(data?.style)

    return <div ref={innerRef} id={data?.id} className={classes.RecContainer} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>

        {
            data?.styledTitle && <div className={classes.Title}>
                <span href={data?.href} dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div>
        }

        <TabSwiper2 sensors={{
            ...sensors,
            type: '1',
        }} items={originMenus} onTab={
            item => {
                changeHandle(item.id)
            }
        } />

        <div>
            {
                (!selectedMenu?.data?.products || selectedMenu?.data?.products?.length < 1) && <SimpleEmptyProducts />
            }

            {
                selectedMenu?.data?.products?.length > 0 && <SimpleProducts sensors={
                {
                    resourcepage_title: sensors?.title,
                    resource_position: sensors?.position,
                    resource_content: selectedMenu?.refId || selectedMenu?.id,
                    resource_type: '1',
                }
            } similar={abTest === 'B'} column={selectedMenu?.id} products={selectedMenu?.data?.products} />
            }

            {
                selectedMenu?.data?.finished && <div className={classes.Finished}>
                    <FormattedMessage id="finished" defaultMessage="Finished" />
                </div>
            }

            {
                loading && !selectedMenu?.data?.finished && <div className={classes.Loading}>
                    <FormattedMessage id="loading" defaultMessage="Loading" />
                </div>
            }

            {
                !loading && !selectedMenu?.data?.finished && <div className={classes.ViewMore} onClick={() => {
                    loadHandle(selectedMenu?.id)
                }}>
                    <span><FormattedMessage id="view_more" defaultMessage="View More" />{' >'}</span>
                </div>
            }

        </div>

    </div>
}))

export default props => {

    const { data } = props

    data.groups = data.categories?.map(category => ({
        id: category.id,
        title: category.title,
        refId: category.refId
    }))

    return <ListTypeNew {...props} groups={data.groups} fetch={
        (type, data, params, headers) => {
            const fetchData = {
                categoryId: data.id
            }
            return fetchNewCategoryList(fetchData, params, headers)
        }  
    }/>
}