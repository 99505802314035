import React, { useEffect, useMemo, useRef, useState } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import RightToLeft from "../../../../../../components/transitions/right-to-left";
import VShopVideo from "../../../../../c-log/video-clogs/components/item/index";
import ReactDOM from 'react-dom'
import { Iconfont } from "../../../../../../components/icon/iconfont";
import { IMAGE_GEEKO_LTD } from "../../../../../../../constants";
import { fetchCategoryList, fetchCollectionList } from "../../../../../../../api";
import INTERFACE_TYPE from "../../../list/hoc/eumn";
import { ProductSwiper } from "../../../../../c-log/video-clogs/components/list";
import { FormattedMessage } from "react-intl";

const MoreProducts = props =>{
    const { imageData, show } = props;
    const { relatedId, productCount, interface: fetchType } = imageData;
    const [products, setProducts] = useState([]);
    const [toogle, setToogle] = useState(false);

    useEffect(() =>{
        if(!show){
            setToogle(false);
        }
    }, [show]);

    useEffect(async () =>{
        if(relatedId){
            let result;
            switch (fetchType) {
                case INTERFACE_TYPE.COLLECTION:
                    result = await fetchCollectionList({ collectionId: relatedId }, { skip: 0, limit: productCount || 12 }).then(data => data.result).catch(e => {
                        console.error(e)
                        return []
                    })
                    break
                case INTERFACE_TYPE.CATEGORY:
                    result = await fetchCategoryList({ categoryId: relatedId }, { skip: 0, limit: productCount || 12 }).then(data => data.result).catch(e => {
                        console.error(e)
                        return []
                    })
                    break
                default:
                    result = []
                    break
            }
            setProducts(result?.filter(p=> !p.isCollection && !p.isSmartCard))
        }
    }, [relatedId]);

    const productsLength = useMemo(() =>{
        return products?.length || "x";
    }, [products]);

    return <div className={classes.MoreProductsCon}>
        <div className={classes.ProductsContainer}>
            <div className={`${classes.ProductsCon} ${toogle ? classes.ShowCon : ""}`}>
                <ProductSwiper 
                    products={products} 
                />
            </div>
        </div>
        
        <div className={classes.MoreProducts} onClick={() =>{
            setToogle(state =>!state)
        }}>
            <div className={classes.IconContainer}>
                <span className={classes.Bag}>
                    <img src={`${IMAGE_GEEKO_LTD}/chicme/20220811023907389640.png`} />
                    
                    <span><span>{productsLength}</span></span>
                </span>

                <span className={classes.Font}><FormattedMessage id="more_products" defaultMessage="More Products" />({productsLength})</span>
            </div>
            
            <Iconfont className={`${classes.ToogleIcon} ${toogle ? classes.Active : ""}`}>&#xe693;</Iconfont>
        </div>
    </div>
}

const PopupVideo = props =>{
    useStyles(classes);
    const { imageData, onClose, show } = props;
    const { youtubeId, relatedId } = imageData;

    const [actived, setActived] = useState(true);

    useEffect(() =>{
        setActived(show);
    }, [show]);

    return <div className={classes.PopupVideo}>
        <Iconfont className={classes.CloseIcon} onClick={onClose}>&#xe693;</Iconfont>

        {
            youtubeId && <VShopVideo showing={{ youtubeId }} stopHandle={() =>{}} actived={actived} />
        }
        
        {
            relatedId && <MoreProducts imageData={imageData} show={show} />
        }
    </div>
}

const WrappedEditor = props => {
    useStyles(classes);
    const { show } = props;

	return <React.Fragment>
		<RightToLeft in={show} className={classes.PopupVideoContainer}>
			<div>
                <PopupVideo {...props} />
			</div>
		</RightToLeft>
	</React.Fragment>
}

export default props =>{
    if (typeof window === 'undefined')
		return null

	return ReactDOM.createPortal(
		<WrappedEditor {...props} />,
		document.getElementById('root')
	)
}