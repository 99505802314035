import React, { useCallback } from "react";
import classes from "./style.module.scss"
import useStyles from "isomorphic-style-loader/useStyles";
import { useDispatch } from "react-redux";
import { toggleLiked } from "../../../../../store/actions/pages/c-log";
import { removeCLogWish, addCLogWish } from "../../../../../api";
import { TOGGLE_UI_LOGIN } from "../../../../../store/actions/ui";

export default props =>{
    useStyles(classes);
    const { liked, showing, numberOfLike, isWhite } = props;
    const dispatch = useDispatch();

    const handleLike = useCallback(async (s, e) => {
        e.preventDefault()
        let response;
        if(liked){
            response = await removeCLogWish(showing?.id);
        }else{
            response = await addCLogWish(showing?.id);
        }

        if(response?.code === 300){
            dispatch({
                type: TOGGLE_UI_LOGIN,
                payload: {
                    showLogin:true,
                    callBack:()=>{
                        dispatch(toggleLiked(s?.id))
                    }
                }
            });

            return;
        }

        dispatch(toggleLiked({id: s?.id, count: liked?(numberOfLike-1):(numberOfLike+1)}))
    }, [liked, numberOfLike])

    return <span 
            className={`${classes.ToolIcon} ${liked ? classes.Active : ''} ${props?.className?props.className:""} ${isWhite?classes.WhiteLike:""}`} 
            onClick={e => handleLike(showing, e)}
        >
        {
            liked ? <span className={classes.Icon} icon="&#xe7ae;" /> : <span className={classes.Icon} icon="&#xe7af;" />
        }
        <span className={classes.Label}>{numberOfLike}</span>
    </span>
}