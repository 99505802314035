import React, { useEffect } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import BottomToTop from "../../../../components/transitions/bottom-to-top";
import { BlackMask } from "../../../../components/mask";
import { withList } from "../../../details/hoc/withList";
import { useState } from "react";
import { getCLogComments, addCLogComment, addCLogCommentWish, removeCLogCommentWish } from "../../../../../api";
import { Iconfont } from "../../../../components/icon/iconfont";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { TOGGLE_UI_LOGIN } from "../../../../../store/actions/ui";
import { FormattedMessage, useIntl } from "react-intl";
import { useCallback } from "react";
import { toggleComment } from "../../../../../store/actions/pages/c-log";

const CommentItem = props =>{
    const { comment, commentItemWish } = props;

    const handleLike = useCallback(async id =>{
        let response;
        if(comment?.liked){
            response = await removeCLogCommentWish(id);
        }else{
            response = await addCLogCommentWish(id);
        }
        
        if(response.code === 200){
            commentItemWish({id:id, count: (comment?.liked?comment?.numberOfLike-1:comment?.numberOfLike+1)});
        }
    },[comment?.liked]);

    return <div className={classes.CommentItem}>
        <div className={classes.Message}>
            <p className={classes.CustName}>{comment?.customerName?.firstName}{comment?.customerName?.lastName}</p>
            <p className={classes.Comments}>{comment?.comments}</p>
            <p className={classes.CommentDate}>{comment?.createDate}</p>
        </div>

        <div className={classes.CommentLike}>
            <div className={`${classes.ToolIcon} ${comment?.liked ? classes.Active : ''}`} onClick={()=>handleLike(comment.id)}>
                {
                    comment?.liked ? <span className={classes.Icon} icon="&#xe7ae;" /> : <span className={classes.Icon} icon="&#xe7af;" />
                }
            </div>
            
            <p className={classes.Num}>{comment?.numberOfLike}</p>
        </div>
    </div>
}

const CommentList = withList(props =>{
    const { innerRef, comments, loading, finished, commentItemWish } = props;

    return <div className={classes.CommentList} ref={innerRef}>
        {
            comments?.map((comment,index) =><CommentItem key={index} comment={comment} commentItemWish={commentItemWish} />)
        }

        {
            loading && !finished && <div className={classes.Loading}>
                <FormattedMessage id="loading" defaultMessage="Loading" />
            </div>
        }

        {
            finished && <div className={classes.Finished}>
                <FormattedMessage id="finished" defaultMessage="Finished" />
            </div>
        }
    </div>
})

const CommentConent = props =>{
    const { onClose, commentId, commentCount } = props;
    const [stateCount, setStateCount] = useState(commentCount);
    const [skip, setSkip] = useState(0);
    const [comments,setComments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const [comment, setComment] = useState('');
    const [commentLoading, setCommentLoading] = useState(false);
    const intl = useIntl();

    const dispatch = useDispatch();

    useEffect(()=>{
        onScroll();
    },[]);

    const onScroll = async () =>{
        setLoading(true);
        let response = await getCLogComments({commentId, skip, limit: 12});
        if(response?.code === 200){
            if(response?.result?.length >0){
                setComments(state=>[...state,...response?.result]);
                setSkip(skip =>skip+=12);
            }else{
                setFinished(true);
            }
            setLoading(false);
        }else{
            setLoading(true);
        }
    }

    const handleSub = async (e) =>{
        e.preventDefault();
        setCommentLoading(true);
        try {
            let response = await addCLogComment({id:commentId, comment:comment});
            setCommentLoading(false);
            if(response?.code === 200 && response?.result){
                setComments(state =>{
                    return [response.result, ...state]
                });
                setStateCount(state=>state+1);
                setComment('');
            }else{
                alert(response.result);
            }

            dispatch(toggleComment({id:commentId}));
        } catch (error) {
            setCommentLoading(false);
        }
    }

    const commentItemWish = ({id, count}) =>{
        setComments(comments =>comments?.map?.(s => s.id === id ? ({...s, liked: !s.liked, numberOfLike: count}): s));
    }

    return <div className={classes.CommentConent}>
        <div className={classes.Hd}>
            <span>{stateCount}  <FormattedMessage id="comments" defaultMessage="Comments" /></span>
            <Iconfont className={classes.CloseIcon} onClick={onClose}>&#xe7c9;</Iconfont>
        </div>

        <div className={classes.Bd}>
            <CommentList 
                request={onScroll} 
                comments={comments} 
                loading={loading} 
                finished={finished}
                commentItemWish={commentItemWish} 
            />
        </div>

        <div className={classes.Fd}>
            <form action="#" onSubmit={handleSub}>
                <input 
                    className={classes.Input} 
                    type="text" 
                    placeholder={`${intl.formatMessage({id: 'chart_ask_question', defaultMessage:'Chat or ask questions'})}…`}
                    value={comment}
                    onChange={(e) =>setComment(e.target.value)}
                    required
                />
                <button 
                    type="submit" 
                    className={classes.CommentBtn}
                    disabled={commentLoading}
                ><Iconfont>&#xe789;</Iconfont></button>
            </form>
        </div>
    </div>
}

const CommentsModal = props =>{
    const { show, onClose } = props;

    return <React.Fragment>
        <BottomToTop in={show} className={classes.CommentsModal}>
            <div>
                <CommentConent {...props} />
            </div>
        </BottomToTop>

        {
            show && <BlackMask onClick={onClose} />
        }
    </React.Fragment>
}

export default props =>{
    useStyles(classes);
    const { commentId, commentCount, isWhite } = props;
    const [show, setShow] = useState(false);
    const global = useSelector(state=>state.global);
    const dispatch = useDispatch();

	const clickHandle = () => {
        if(global?.user || global?.user?.id){
            setShow(true)
        }else{
            dispatch({
                type: TOGGLE_UI_LOGIN,
                payload: {
                    showLogin:true,
                    callBack:()=>{
                        setShow(true)
                    }
                }
            });
        }
	}

    return  <React.Fragment>
        <span className={`${classes.ToolIcon} ${props?.className?props.className:""} ${isWhite?classes.WhiteIcon:""}`} onClick={clickHandle}>
            <Iconfont className={classes.CommentIcon} >
                &#xe7b0;
            </Iconfont>

            <span className={classes.Label}>{commentCount}</span>
        </span>

        <CommentsModal show={show} onClose={() =>{setShow(false)}} commentId={commentId} commentCount={commentCount} />
    </React.Fragment>
}