// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aD9rO0QqhNQ1hYR7_cfRA{height:1.06667rem}._33NUZBJUDwOMez6wcpLOPN{height:1.06667rem;line-height:1.06667rem;text-align:center;display:block;color:#222;text-decoration:none}._33NUZBJUDwOMez6wcpLOPN ._10qno4Wo4tL6UWa0c0BhAR{font-size:.48rem;font-weight:bold;margin-right:.10667rem}\n", "",{"version":3,"sources":["webpack://src/client/pages/moduleable/modules/tips/style.module.scss","webpack://src/client/base.scss"],"names":[],"mappings":"AAEA,uBACI,iBCCoC,CDAvC,yBAGG,iBCHoC,CDIpC,sBCJoC,CDKpC,iBAAkB,CAClB,aAAc,CACd,UAAW,CACX,oBAAqB,CANzB,kDASQ,gBCXgC,CDYhC,gBAAiB,CACjB,sBCbgC","sourcesContent":["@import \"../../../../base.scss\";\n\n.Tips{\n    height: actual(40);\n}\n\n.Tip{\n    height: actual(40);\n    line-height: actual(40);\n    text-align: center;\n    display: block;\n    color: #222;\n    text-decoration: none;\n\n    .Icon{\n        font-size: actual(18);\n        font-weight: bold;\n        margin-right: actual(4);\n    }\n}","$baseFontSize: 75; //基于视觉稿横屏尺寸/10得出的基准font-size\n$IMAGE_GEEKO_LTD: 'https://image.geeko.ltd';\n\n@function actual($px) {\n  @return 2 * $px / $baseFontSize * 1rem;\n}\n\n@function actualPc($px){\n  @return $px / 1920 * 100vw;\n}\n\n@function imageGeekoLtd($url){\n\t@return $IMAGE_GEEKO_LTD + $url;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Tips": "aD9rO0QqhNQ1hYR7_cfRA",
	"Tip": "_33NUZBJUDwOMez6wcpLOPN",
	"Icon": "_10qno4Wo4tL6UWa0c0BhAR"
};
module.exports = ___CSS_LOADER_EXPORT___;
