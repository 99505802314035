// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3CMcO-SRoz3U5kwpWBPWsk{display:block;text-decoration:none;color:#222}._3CMcO-SRoz3U5kwpWBPWsk img{width:100%}._3CMcO-SRoz3U5kwpWBPWsk img._1a3wy2aqE0g9KH6JymK47W{display:block}._3CMcO-SRoz3U5kwpWBPWsk img.dwxNpvqVpduABxpnvUyiR{display:none}._3CMcO-SRoz3U5kwpWBPWsk._2khlWpCdzymRu3AlwKRm7h img._1a3wy2aqE0g9KH6JymK47W{display:none}._3CMcO-SRoz3U5kwpWBPWsk._2khlWpCdzymRu3AlwKRm7h img.dwxNpvqVpduABxpnvUyiR{display:block}.N6U_WP-pEFDKHxQXw5KAU{display:flex;flex-wrap:wrap}\n", "",{"version":3,"sources":["webpack://src/client/pages/moduleable/modules/fetstival/images/style.module.scss"],"names":[],"mappings":"AAEA,yBACI,aAAc,CACd,oBAAqB,CACrB,UAAW,CAHf,6BAKQ,UAAW,CALnB,qDAOY,aAAc,CAP1B,mDAUY,YAAa,CAVzB,6EAkBgB,YAAa,CAlB7B,2EAqBgB,aAAc,CACjB,uBAMT,YAAa,CACb,cAAe","sourcesContent":["@import \"../../../../../base.scss\";\n\n.Image{\n    display: block;\n    text-decoration: none;\n    color: #222;\n    img{\n        width: 100%;\n        &.Main{\n            display: block;\n        }\n        &.Secondary{\n            display: none;\n            \n        }\n    }\n\n    &.Active{\n        img{\n            &.Main{\n                display: none;\n            }\n            &.Secondary{\n                display: block;\n            }\n        }\n    }\n}\n\n.Images{\n    display: flex;\n    flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Image": "_3CMcO-SRoz3U5kwpWBPWsk",
	"Main": "_1a3wy2aqE0g9KH6JymK47W",
	"Secondary": "dwxNpvqVpduABxpnvUyiR",
	"Active": "_2khlWpCdzymRu3AlwKRm7h",
	"Images": "N6U_WP-pEFDKHxQXw5KAU"
};
module.exports = ___CSS_LOADER_EXPORT___;
