import React from "react";
import useStyles from "isomorphic-style-loader/useStyles";
import classes from "./style.module.scss";
import { Swiper, SwiperSlide } from 'swiper/react'
import { FormattedMessage } from "react-intl";


const LotteryDrawHistory = props => {
    useStyles(classes);
    const { drawHistory } = props;

    return <>
        {
            drawHistory?.length > 0 && <div className={classes.LuckDrawHistory} style={{...props.style}}>
                <Swiper
                    direction='vertical'
                    loop={true}
                    autoplay={{ disableOnInteraction: false, delay: 3000 }}
                    onSlideChange={(swiper) => console.log()}
                    onSwiper={(swiper) => console.log()}
                >
                    {
                        drawHistory?.map((item, index) => <SwiperSlide className={classes.LuckDrawHistoryItem} key={index}>
                            <div>{item?.email}</div>
                            <div><FormattedMessage id="get" defaultMessage="Get" /> {item?.prize?.name}</div>
                        </SwiperSlide>)
                    }

                </Swiper>
            </div>
        }
    </>
}

export default LotteryDrawHistory;