import TypeAnniversary from './type-anniversary'

export const Types = {
    TYPE_ANNIVERSARY: 'lottery-anniversary',
}

export default type => {
    switch(type){
        case Types.TYPE_ANNIVERSARY:
            return TypeAnniversary
        default:
            return
    }
}