import React from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import GroupImages from '../../../../components/card/images/gourp-images'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import {Types} from './index'
import withObserver from '../hoc/withObserver'
import {getStyleObjectFromString} from '../../../../utils/style'

const Media = props => {
    const { image } = props
    if(image.mediaType === 'video'){
        return <video src={image.src} autoPlay playsInline loop muted poster={image.poster}/>
    }
    return <LazyLoadImage alt={image?.title} src={image.src}/>
}


export default withObserver(props => {
    useStyles(classes)
    const {data, sensors, innerRef} = props
    const images = data?.images
    const styledTitle = data?.styledTitle
    const styles = getStyleObjectFromString(data?.style)

    return <div ref={innerRef} id={data?.id} style={{paddingTop: `${data?.marginTop}`, ...styles}}>
        <GroupImages special={data?.special} direction={data?.direction} sensors={{
            ...sensors,
            type: '1',
        }} images={images} title={
            styledTitle ? <span dangerouslySetInnerHTML={{__html: styledTitle}}/>:''
        } render={(image, index) => {
            return sensors?.position == '1' && index === 0 ? <img alt={image?.title} src={image.src}/> :<Media image={image}/>
        }}/>
    </div>
})