import React from 'react'
import ErrorWrapper from '../../../error-wrapper'
import Banner from './banner'
import Image from './image'
import ImageGroup from './image-group'
import List from './list'
import Tip from './tips'
import Collections from './collections'
import FestivalCountDown from './fetstival/count-down'
import FestivalImages from './fetstival/images'
import Blog from './blog'
// import Living from './living'
import Video from './video'
import Lottery from "./lottery";
import Sigin from './sign-in'


const linkedSelect = (funcs, type) => {
    let element
    let index = 0
    while (!element && index < funcs.length) {
        element = funcs[index](type)
        index++
    }
    return element
}

const funcs = [
    Banner,
    Image,
    ImageGroup,
    List,
    Tip,
    Collections,
    FestivalCountDown,
    FestivalImages,
    Blog,
    // Living
    Video,
    Lottery,
    Sigin
]

export default props => {
    const { data, sensors, abTest, entryView } = props
    const shouldRenderData = data?.sort((d1, d2) => d1.sort - d2.sort)?.filter(d => d.show)
    // shouldRenderData.push({
    //     "images": [
    //         {
    //             "src": "https://image.geeko.ltd/chicme/20221121024706684481.jpg",
    //             "width": 375.0,
    //             "id": "",
    //             "refId": "two",
    //             "href": "",
    //             "title": "",
    //             "percent": "50.0%",
    //             "height": 430.0,
    //             "youtubeId": "a4Ss0v01DLQ",
    //             "relatedId": "9b07e570-4896-4f95-aef2-85f36ddbe78a",
    //             "interface": "5"

    //         },
    //         {
    //             "src": "https://image.geeko.ltd/chicme/20221121024725491673.jpg",
    //             "width": 375.0,
    //             "id": "",
    //             "refId": "piece",
    //             "href": "",
    //             "title": "",
    //             "percent": "50.0%",
    //             "height": 430.0,
    //             "youtubeId": "Can1LuyGcmc",
    //             "relatedId": "f9229b43-7fc9-45c4-b380-b4407267caa8",
    //             "interface": "5"
    //         }
    //     ],
    //     "styledTitle": "",
    //     "show": true,
    //     "name": "msiteImages2",
    //     "id": "230214101840455456487527181",
    //     "refId": "",
    //     "sort": 50,
    //     "singleId": "modules_images-2_8",
    //     "type": "images-3",
    //     "title": "",
    //     "marginTop": "0px"
    // },);

    // console.log(shouldRenderData)

    // shouldRenderData.unshift({
    //     id:'test-3',
    //     show: true,
    //     sort: -2,
    //     type: 'sigin-1',
    // })


    return <React.Fragment>
        {
            shouldRenderData?.map((d, index) => {
                const Element = linkedSelect(funcs, d.type)

                return Element && <ErrorWrapper>
                    <Element {...props} abTest={abTest} key={`${d.id || index}-${index}`} data={d} sensors={{...sensors, position: index+1}}/>
                </ErrorWrapper>
            })
        }
    </React.Fragment>
}