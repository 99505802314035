import React from 'react'
import withCollections from './withCollections'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { FullSwiper } from '../../components/swiper'
import ImageContainer from '../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getCollectionUrl } from '../../../../../utils/url'
import { IMAGE_GEEKO_LTD } from '../../../../../constants'
import { Types } from './index'
import {getStyleObjectFromString} from '../../../../utils/style'


export default withCollections(props => {
    useStyles(classes)
    const { data, sensors, collections } = props
    const { styledDescription, styledTitle, width=375, height=300 } = data

    const aspectRatio = width && height ? `${width} / ${height}`: undefined

    const styles = getStyleObjectFromString(data?.style)

    return <div className={classes.Container} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        {
            styledTitle && <div className={classes.Title}>
                <span dangerouslySetInnerHTML={{ __html: styledTitle }} />
            </div>
        }

        {
            styledDescription && <div className={classes.Desc}>
                <span dangerouslySetInnerHTML={{ __html: styledDescription }} />
            </div>
        }

        <div className={classes.Bd}>
            <FullSwiper items={collections} style={{
                aspectRatio,
                backgroundColor: '#f1f1f1'
            }} options={{
                pagination: true,
                // loop: true
            }} render={(item, index) => {
                const url = item.relatePcHref || getCollectionUrl(item.id, item.name)
                return <ImageContainer href={url} title={item.name} src={item.mobileImage} position={index} sensors={{
                    ...sensors,
                    refId: item.enName || item.name,
                    type: '1',
                }}>
                    <LazyLoadImage alt={item.name} src={`${IMAGE_GEEKO_LTD}${item.mobileImage}`} />
                </ImageContainer>
            }} />
        </div>

    </div>
})