import React, { useCallback, useRef, useState } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import ReactPlayer from 'react-player/lazy'
import { Range, getTrackBackground } from 'react-range'


const Video = props => {
    useStyles(classes)
    const { showing, stopHandle, actived } = props
    const { youtubeId } = showing
    const player = useRef()
    const [ready, setReady] = useState(false)
    const [playing, setPlaying] = useState(false)
    const [muted, setMuted] = useState(false)
    const [duration, setDuration] = useState()
    const [state, setState] = useState({})
    const [playbackRate, setPlaybackRate] = useState(1)

    const handleReady = useCallback(() => {
        setReady(true)
    }, [])

    const handleStart = useCallback(() => {
        // setMuted(false)
    }, [])

    const durationHandle = (duration) => {
        // console.log('onDuration', duration)
        setDuration(duration)
    }

    const progressHandle = state => {
        // console.log('onProgress', state)
        // We only want to update time slider if we are not currently seeking
        // if (!this.state.seeking) {
        //   this.setState(state)
        // }

        // loaded: 1
        // loadedSeconds: 17.081
        // played: 0.7059107759358572
        // playedSeconds: 12.057661963760376
        setState(state)
    }

    const seekHandle = values => {
        // setPlaying(false)
        // console.log(values?.[0])
        setState({ ...state, played: values?.[0] })
        player.current?.seekTo(values?.[0])
    }


    const preventHandle = useCallback(event => {
        // event.preventDefault()
        // event.stopPropagation()
    }, [])

    const maskClickHandle = event => {
        setPlaying(!playing)
    }

    const getSecond = (second) =>{
        return second < 10 ? `0${second}` : second;
    }

    const getProgressSecond = (item) =>{
        let minute = item < 60 ? '00' : Math.floor(item/60);
        let second = item < 60 ? getSecond(item) : getSecond(item%60);

        return `${minute}:${second}`
    }

    return <div className={classes.Container} on>
        <div className={classes.Mask} onClick={maskClickHandle} />
        <ReactPlayer
            ref={player}
            playing={playing && actived}
            loop
            muted={muted}
            onStart={handleStart}
            onReady={handleReady}
            onDuration={durationHandle}
            onProgress={progressHandle}
            playbackRate={playbackRate}
            width='100%' height='100%' url={`https://www.youtube.com/watch?v=${youtubeId}`} />
        <div className={classes.Progress} onTouchStart={() => stopHandle(false)} onTouchEnd={() => stopHandle(true)}>
            {
                ready && <Range
                    values={[state.played]}
                    step={0.001}
                    min={0}
                    max={1}
                    onChange={seekHandle}
                    renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                display: "flex",
                                width: "100%"
                            }}
                            className={classes.TrackRender}
                        >
                            <div
                                ref={props.ref}
                                className={classes.Track}
                                style={{
                                    // height: "5px",
                                    // width: "100%",
                                    // borderRadius: "4px",
                                    background: getTrackBackground({
                                        values: [state.played],
                                        colors: ["#fff", "rgba(255, 255, 255, 0.5)"],
                                        min: 0,
                                        max: 1
                                    }),
                                    alignSelf: "center"
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                // height: "42px",
                                // width: "42px",
                                // borderRadius: "4px",
                                // backgroundColor: "#FFF",
                                // display: "flex",
                                // justifyContent: "center",
                                // alignItems: "center",
                                // boxShadow: "0px 2px 6px #AAA"
                            }}
                            className={`${classes.Thumb} ${isDragged ? classes.Dragged : ''}`}
                        >
                            {
                                isDragged && <span className={classes.ProgressSecond}>{getProgressSecond(Math.floor(state?.playedSeconds))} / {getProgressSecond(Math.floor(state?.loadedSeconds))}</span>
                            }
                        </div>
                    )}
                />
            }

        </div>


    </div>
}


// const Image = props => {
//     return <div className={classes.Container}>
//         <img src={props?.showing?.mainImage}/>
//     </div>
// }


export default props => {
    return <Video {...props}/>
}