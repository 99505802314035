import React from 'react'
import withList from './hoc/withList'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import {getStyleObjectFromString} from '../../../../utils/style'
import { Types } from './index'
import withObserver from '../hoc/withObserver'
import { FormattedMessage } from 'react-intl'
import { useApp } from '../../../../hooks/common'
import { useLocation } from 'react-router-dom'
import {unitPrice, getLowerPrice, getDelPrice, isPromotion, getProductUrl} from '../../../../../utils/product'
import { IMAGE_GEEKO_LTD } from '../../../../../constants/index'
import _ from 'lodash'
import {FullSwiper} from '../../components/swiper'
import { BuyIcon } from '../../../listing/components/cart/cart-icon.jsx'


const EmptyProduct = props => {
    useStyles(classes)
    return <div className={classes.LiveProduct}>
        <div className={classes.Image}>
            
        </div>
        <div className={classes.Info}>
            <div className={classes.Name}>
                -
            </div>
            <div className={classes.Prices}>
                <span className={classes.Price}>
                    -
                </span>
            </div>
            <div>
                <button className={classes.AddBtn}><FormattedMessage id="add_to_bag" defaultMessage={'Add to Bag'}/></button>
            </div>
        </div>
    </div>
}

const ProductItem = props => {
    useStyles(classes)

    const location = useLocation()
	const isApp = useApp()

	const { product, position } = props

	const [lowerPrice, delPrice, isProm] = [
		getLowerPrice(product),
		getDelPrice(product),
		isPromotion(product)
	]

    const url = isApp ? `chic-me://chic.me/product?id=${product?.id}` : getProductUrl(product?.id, product?.name)


    return <div className={classes.LiveProduct}>
        <div className={classes.Image}>
            <a href={url}>
                <LazyLoadImage src={`${IMAGE_GEEKO_LTD}/medium/${product.pcMainImage}`} alt={product?.name} />
            </a>
        </div>
        <div className={classes.Info}>
            <div className={classes.Name}>
                {product.name}
            </div>
            <div className={classes.Prices}>
                <span className={classes.Price}>
                    {unitPrice(lowerPrice)}
                </span>
                {
                    delPrice && <del className={classes.Del}>
                        {unitPrice(delPrice)}
                    </del>
                }
            </div>
            <div>
                 <BuyIcon sensors={
					{
						layerName: product?.layerName,
						trackingRecords: product?.trackingRecords,
						dataSource: product?.dataSource,
						geekoExperimentId: product?.geekoExperimentId,
						geekoRequsestId: product?.geekoRequsestId,
						aliRequestId: product?.aliRequestId,
						aliExperimentId: product?.aliExperimentId,
						position: position + 1
					}
				} productId={product?.id} render={() => <button className={classes.AddBtn}><FormattedMessage id="add_to_bag" defaultMessage={'Add to Bag'}/></button>} />
                
            </div>
        </div>
    </div>
}


const SwiperProducts = props => {
    useStyles(classes)
    const { products=[] } = props
    const groupedProducts = _.reduce(products, (result, value, key) => {
        result[Math.ceil((key+1)/3)] = result[Math.ceil((key+1)/3)] || []
        result[Math.ceil((key+1)/3)].push(value)
        return result
    }, [])



    return <div>

        {
            products?.length > 0 ? <FullSwiper className={classes.LiveSwiper} options={{
                pagination: true
            }} items={groupedProducts} render={(item, index) => {
                return <div key={index} className={classes.LiveProducts}>
                    {
                        item.map((product, i) => <ProductItem position={i} key={product.id} product={product}/>)
                    }
                </div>
            }}/>: <FullSwiper className={classes.LiveSwiper} options={{
                pagination: true
            }} items={[[1,1, 1]]} render={(item, index) => {
                return <div key={index} className={classes.LiveProducts}>
                    {
                        item.map((product, i) => <EmptyProduct key={i}/>)
                    }
                </div>
            }}/>
        }


        
    </div>
}



export default withList(withObserver(props => {
    useStyles(classes)
    const { products, data, sensors, innerRef, top, buyButton } = props
    const isApp = useApp()

    const styles = getStyleObjectFromString(data?.style)


    return <div ref={innerRef} id={data?.id} className={classes.ListContainer} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>

        {
            data?.styledTitle && <div className={classes.Title}>
                <span dangerouslySetInnerHTML={{__html: data.styledTitle}}/>
            </div>
        }


        
        <div className={classes.TypeVideo}>
            <div className={classes.VideoContainer}>
                <video controls preload="none" poster={data.poster}>
                    <source src={data.src} type="video/mp4"/>
                </video>
            </div>
            <div className={classes.VideoProductsContainer}>
                <SwiperProducts products={products}/>
            </div>
        </div>
    </div>
}))