import React, { useEffect, useState } from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import { Iconfont } from '../../../components/icon/iconfont'
import { useApp, useAppVersion, useDeviceType, useQuery } from '../../../hooks/common'
import { isIOS, isMacOs } from 'react-device-detect'
import { useSelector } from 'react-redux'
import ClickMask from './components/clickMask'
import { IMAGE_GEEKO_LTD } from '../../../../constants'
import { useIntl } from 'react-intl'
import { fetchGetMessage, fetchSignIn, fetchSignInData, fetchSignInRemind, subscribeWebPush } from '../../../../api'
import Mask from '../../../components/alert/mask'
import Toast from '../../../components/toast'
import { urlBase64ToUint8Array, registerServiceWorker, subscribeUserToPush, } from '../web-push'
import { isBeforeVersion } from '../../../../utils/version'

const Turn = props => {
    return <div {...props} className={`${classes.turnModule} ${props.turnAcitve ? classes.active : ''} ${props.disabled ? classes.disabled : ''}`}>
        <span></span>
    </div>
}

const NewDayItem = (props) => {
    const {data, index, today, modulePart, intl} = props
    const isBig = data?.dateIndex == 7 && !modulePart
    const imgBaseUrl = '/chicme/20220810/sign-in-icon/new1/'
    let imgUrl = (data?.signIn ? 
                   data?.hasGift ? imgBaseUrl+'gift-light.png' : imgBaseUrl+'point-light.png' : 
                   data?.hasGift ? imgBaseUrl+'gift-gray.png' : imgBaseUrl+'point-gray.png')
    // imgUrl = data.dateIndex == '1' ? (data?.signIn ? imgBaseUrl+'day1-light.png' : imgBaseUrl+'day1-dark.png'):
    //          data.dateIndex == '4' ? (data?.signIn ? imgBaseUrl+'day4-light.png' : imgBaseUrl+'day4-dark.png'):
    //          data.dateIndex == '7' ? modulePart ? (data?.signIn ? imgBaseUrl+'day7-light.png' : imgBaseUrl+'day7-dark.png') : 
    //                                               (data?.signIn ? imgBaseUrl+'day7-special.png' : imgBaseUrl+'day7-special.png') :
    //          imgUrl

    const dateList = [
                    intl.formatMessage({id:'today'}),
                    intl.formatMessage({id:'day1'}),
                    intl.formatMessage({id:'day2'}),
                    intl.formatMessage({id:'day3'}),
                    intl.formatMessage({id:'day4'}),
                    intl.formatMessage({id:'day5'}),
                    intl.formatMessage({id:'day6'}),
                    intl.formatMessage({id:'day7'}),
                ]
    const dayTxt = today ? dateList?.[0]: dateList?.[data?.dateIndex]

    const imgTxtColor = !data?.signIn ? '#C1C3C4': '#F37F90'
    const dayTxtColor = today ? '#FBE945': '#fff'
    

    return <div className={`${classes.dayItemBoxNew}`}>
        <div className={`${classes.dayItemCard}`}>
            <img src={IMAGE_GEEKO_LTD + imgUrl} alt="" />
        </div>
        <div className={`${classes.dayItemTxt}`} style={{color: dayTxtColor}}>
            {dayTxt}
        </div>
    </div>
}

let type = 0
export default props => {
    const {backgroundImage, backgroundColor, backgroundImageBottom} = props
    const dark = !!backgroundImage
    let backgroundImageStyle, backgroundImageBottomStyle
    backgroundImageStyle = backgroundImage ? `url(${backgroundImage})` : 'url(https://image.geeko.ltd/chicme/20220810/sign-in-icon/new1/m1.jpg)'
    backgroundImageBottomStyle = backgroundImageBottom ? `url(${backgroundImageBottom})` : 'url(https://image.geeko.ltd/chicme/20220810/sign-in-icon/new1/m2.jpg)'

    const [showSignIn, setShowSignIn] = useState(false)
    const [dailyData, setDailyData] = useState(null)
    const [appCodeMsg, setAppCodeMsg] = useState(null)
    const [signInToday, setSignInToday] = useState(null)
    const [remindTurn, setRemindTurn] = useState(false)
    const [rulesData1, setRulesData1] = useState(null)
    const [signInData, setSignInData] = useState(null)
    const [modalData, setModalData] = useState(null)
    const [hasSignedNum, setHasSignedNum] = useState(0)
    const global = useSelector((state) => state.global)
    const isLogin = global?.user?.id
    const isApp = useApp()
	const { config } = global
    const appVersion = useAppVersion()
    const deviceType = useDeviceType()
    const shouldShow = !isApp || !appVersion || (!isBeforeVersion(appVersion, '4.7.11') && deviceType === 'ios') || (!isBeforeVersion(appVersion, '3.8.211') && deviceType === 'android')

    useStyles(classes)
    const intl = useIntl()

    const [bigGiftData, setBigGiftData] = useState(null)
    // const bigGiftData = [
    //     {url:'https://image.geeko.ltd/chicme/20220810/sign-in-icon/1.png',txt: intl.formatMessage({id:'day1'})},
    //     {url:'https://image.geeko.ltd/chicme/20220810/sign-in-icon/3.png',txt: intl.formatMessage({id:'day3'})},
    //     {url:'https://image.geeko.ltd/chicme/20220810/sign-in-icon/5.png',txt: intl.formatMessage({id:'day5'})},
    //     {url:'https://image.geeko.ltd/chicme/20220810/sign-in-icon/7.png',txt: intl.formatMessage({id:'day7'})},
    // ]

    useEffect(() => {
        initSignInData()
        getAppCode()
        getBigGifts()
        try {
            registerServiceWorker()
        } catch (error) {
            console.error(error?.stack)
        }
    }, [])

    const getBigGifts = async () => {
        const response = await fetchGetMessage("M1694")
        if(response?.code ==200 && response?.result){
            // console.log(response?.result)
            let msg = JSON.parse(response?.result?.message)
            // console.log(msg)
            setBigGiftData(msg?.data)
        }
    }

    const getAppCode = async () => {
        const response = await fetchGetMessage("M1690")
        if(response?.code ==200 && response?.result){
            setAppCodeMsg(response?.result?.message)
        }
    }

    const initSignInData = async () => {
        const response = await fetchSignInData()
        
        if(response?.code == 200 && response?.result){
            setDailyData(response.result)
            let data = response.result?.dailySignIns
            let todayCheckItem = data.find(d => d.dateIndex == (response?.result?.todayIndex + 1))
            let num = 0
            data.forEach(d => {
                if(d.signIn){
                    num += 1
                }
            })
            setHasSignedNum(num)
            // let todayCheckItem = data.find(d => d.dateIndex == 0)
            setSignInData(data)
            setRulesData1(response?.result?.ruleDescription)
            setSignInToday(todayCheckItem)
            if(!remindTurn){
                setRemindTurn(response?.result?.openReminder)
            }
        } else {
            console.error(response?.result || response)
        }
    }

    const toDownload = () => {
        if (isIOS || isMacOs) {
            window.location.href = `https://itunes.apple.com/us/app/chic-me-shopping-sharing-saving/id${config?.app.ios.id}?l=zh&ls=1&mt=8`;
            return
        } else {
            window.location.href = `https://play.google.com/store/apps/details?id=${config?.app.android.id}`;
            return
        }
    }

    const closeMask = () => {
        setModalData(null)
    }

    const remindClick = async () => {
        if(remindTurn){
            const response = await fetchSignInRemind(false)
            if(response?.code == 200){
                setRemindTurn(false)
                // props?.updateData({...dailyData, openReminder:false})
            } else {
                alert(response?.result || response)
            }
        } else {
            if(isApp){
                openMask('notification')
            } else {
                openNotification()
            }
        }
        
    }

    const openMask = (type, imgUrl, isBig) => {
        const points = type == 'points' && signInToday?.points
        const hasCheckedDay = type == 'points' && signInToday?.dateIndex

        const dateList = [
            intl.formatMessage({id:'day1'}),
            intl.formatMessage({id:'day2'}),
            intl.formatMessage({id:'day3'}),
            intl.formatMessage({id:'day4'}),
            intl.formatMessage({id:'day5'}),
            intl.formatMessage({id:'day6'}),
            intl.formatMessage({id:'day7'}),
        ]
        
        // beforeOpen, gift, rules, points, notification
        // 实物弹窗
        let bigGiftData = {
            btn2: {
                label: intl.formatMessage({ id: "add_to_bag", defaultMessage: "Add to Bag" }),
                callback: () => {
                    if(isApp){
                        window.location.href = "chic-me://chic.me/shoppingcart";
                    } else {
                        window.location.href = '/cart';
                    }
                    return
                }
            },
            font1: {
                label: intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" }),
                style: {
                    fontSize: "14px"
                    // marginTop:"10px"
                }
            },
            font2: {
                label: isBig ? intl.formatMessage({id:'specialGift'}) : intl.formatMessage({id:'gift'}),
                style: {
                    fontSize: "20px",
                    // marginTop: "10px",
                    // textTransform: "uppercase"
                }
            },
            imageUrl: imgUrl,
            imageWidth:"35%",
            showTimes: ((24 * 60 * 60 * 1000) * 1),
        };

        // points弹窗
        let pointsData = {
            fontAboveImg:{
                label: intl.formatMessage({id:'checkInSuccess'}),
                style: {
                    fontSize: '14px'
                }
            },
            font1: {
                label: '+' + points + intl.formatMessage({id:'points'}),
                style: {
                    fontSize: "18px",
                    marginTop:"10px",
                    fontWeight:'bold',
                    lineHeight:'21px',
                }
            },
            tip: {
                label: intl.formatMessage({id:'checkDaysLeft'}, {daysHave:dateList[hasCheckedDay], daysNotHave:7-hasCheckedDay}),
                style: {
                    textAlign: 'center',
                    marginTop: '6px',
                }
            },
            btn2:{
                label: intl.formatMessage({ id: "confirm", defaultMessage: "Confirm" }),
                callback: () => {
                    closeMask()
                    return
                }
            },
            imageUrl: imgUrl,
        }

        // 规则弹窗
        let rulesData = {
            font1: {
                label: intl.formatMessage({id:'checkRules'}),
                style: {
                    fontSize: "14px",
                    marginBottom: '10px'
                    // marginTop:"10px"
                }
            },
            rulesContent:{
                content: rulesData1,
                style:{
                    fontSize: '12px',
                    fontFamily: 'Roboto-Regular, Roboto',
                    fontWeight: 400,
                    color: '#222222',
                    lineHeight: '14px',
                    textAlign:'left'
                }
            },
            btn2: {
                label: intl.formatMessage({ id: "confirm", defaultMessage: "Confirm" }),
                callback: () => {
                    closeMask()
                    return
                }
            },
        }

        // 开大奖弹窗
        let openSpecialGift = {
            fontAboveImg: {
                label: intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" }),
                style: {
                    fontSize: "14px"
                }
            },
            giftImg: {
                url: '/chicme/20220810/sign-in-icon/special-gift-mask.gif',
                style:{}
            },
            btn2: {
                label: intl.formatMessage({id:'openGift'}),
                callback: () => {
                    closeMask()
                    openMask('gift', imgUrl, true)
                    return
                }
            },
        }

        // 提醒打开消息通知弹窗
        let notificationData = {
            font1:{
                label: intl.formatMessage({id:'openNotificationTip'}),
                style:{
                    fontSize: '14px',
                    fontFamily: 'Roboto-Medium, Roboto',
                    fontWeight: 500,
                    color: '#222222',
                    lineHeight: '16px',
                    marginTop: '30px',
                    marginBottom: '30px',
                }
            },
            btn1: {
                label: intl.formatMessage({id:'cancel', defaultMessage:'Cancel'}),
                callback: () => {
                    closeMask()
                    return
                }
            },
            btn2: {
                label: intl.formatMessage({id:'ok', defaultMessage:'OK'}),
                callback: () => {
                    closeMask()
                    openNotification()
                    return
                }
            },
        }

        switch(type){
            case 'rules':
                setModalData({...rulesData})
                break
            case 'points':
                setModalData({...pointsData})
                break
            case 'gift':
                setModalData({...bigGiftData})
                break
            case 'beforeOpen':
                setModalData({...openSpecialGift})
                break
            case 'notification':
                setModalData({...notificationData})
                break
        }
    }

    const checkIn = () => {
        console.log(type)
        if(type == 0){
            openMask('rules')
            type += 1
        } else if(type == 1){
            openMask('points','/prize/1V6s6N0c0R3U4f4N4T4v898S0y')
            type += 1
        } else if(type == 2){
            openMask('gift','/prize/1V6s6N0c0R3U4f4N4T4v898S0y')
            type += 1
        } else if(type == 3){
            openMask('gift','/prize/1V6s6N0c0R3U4f4N4T4v898S0y', true)
            type += 1
        } else if(type == 4){
            openMask('beforeOpen','/prize/1V6s6N0c0R3U4f4N4T4v898S0y')
            type += 1
        } else if(type == 5){
            openMask('notification')
            type = 0
        }
    }

    const openNotification = async () => {
        const response = await fetchSignInRemind(true)
        if(response?.code == 200){
            setRemindTurn(true)
            // props?.updateData({...dailyData, openReminder:true})
        } else {
            alert(response?.result || response)
        }
        if(isApp){
            window.location.href = '/open-notification'
            return 
        } else {
            try {
                Notification.requestPermission().then(permission => {
                    return subscribeUserToPush().then(pushSubscription => {
                        subscribeWebPush(pushSubscription)
                        return pushSubscription
                    })
                }).catch(err => {
                    console.log(err)
                })
            } catch(err){
                console.error(err?.result || err)
                return
            }
        }
    }

    const toLogin = () => {
        if(isApp){
            window.location.href = 'chic-me://chic.me/loginRoot'
        } else {
            window.location.href = '/i/login?redirectUrl=' + encodeURI(window.location.pathname)
        }
        return
    }

    const signIn = async() => {
        if(signInToday?.signIn){
            Toast(intl.formatMessage({id:'alreadyChecked'}),{isDesktop:true})
            return
        }
        const response = await fetchSignIn()
        if(response?.code == 200 && response?.result){
            // props?.updateData(response?.result)
            let imgUrl = signInToday?.promptImage
            let data = response.result?.dailySignIns
            let todayCheckItem = data.find(d => d.dateIndex == (response?.result?.todayIndex + 1))
            let num = 0
            data.forEach(d => {
                if(d.signIn){
                    num += 1
                }
            })
            setHasSignedNum(num)
            setSignInData(data)
            setRulesData1(response?.result?.ruleDescription)
            setSignInToday(todayCheckItem)
            if(!remindTurn){
                setRemindTurn(response?.result?.openReminder)
            }
            if(signInToday?.dateIndex == 7){
                openMask('beforeOpen',imgUrl)
            } else if(signInToday?.hasGift){
                openMask("gift", imgUrl)
            } else {
                openMask("points",imgUrl)
            }
        } else {
            alert(response?.result || response)
        }
        
    }

    return <React.Fragment>
        <div className={classes.SignModuleBoxNew} 
             style={{
                backgroundColor: backgroundColor, 
                backgroundImage: backgroundImageStyle,
                }}>
                <div className={classes.SignModuleTitle}>
                    {intl.formatMessage({id:'checkedNow'})}
                </div>
                <div className={classes.SignModuleFlexBox}>
                    <div className={classes.SignModuleBigGift}>
                        {
                            bigGiftData?.map((item, index) => (
                                <div className={classes.BigGift} key={item?.url + index}>
                                    <img className={classes.BigGiftImg} src={item?.url} alt="" />
                                    <span className={classes.BigGiftTxt}>{item?.txt}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div className={classes.SignInBox}>
                        <div className={classes.SignInProcess}>
                            {
                                signInData?.length > 0 &&
                                signInData?.map((item, index) => (
                                    <NewDayItem intl={intl} data={item} index={index} today={(item?.dateIndex - 1) == dailyData?.todayIndex}/>
                                ))
                            }
                            <div className={classes.processLine}>
                                <div className={classes.processNow} style={{width: ((signInToday && (signInToday?.dateIndex)) || 0) * 16.7 + '%'}}></div>
                            </div>
                        </div>
                        <div className={classes.SignInButton} onClick={() => {
                            if(!isLogin){
                                toLogin()
                            } else {
                                if(signInToday){
                                    signIn()
                                } else {
                                    alert("The event has not started yet")
                                }
                            }
                        }}>
                            <div className={classes.Button}>
                                {intl.formatMessage({id:'checkInNow'})+' >'}
                            </div>
                        </div>
                        <div className={classes.SignInToolBox}>
                            <div className={classes.SigInHistory}>
                                <div className={classes.record}>
                                    {
                                        intl.formatMessage({id:'checkDays'}, {day: <span style={{color:'#fff'}}>{hasSignedNum || 0}</span>})
                                    }
                                </div>
                                <Iconfont className={classes.ModuleTip} 
                                onClick={() => {
                                    // checkIn()
                                    openMask('rules')
                                }}>&#xe73f;</Iconfont>
                            </div>
                            {
                                shouldShow &&
                                <div className={classes.RemindMeBox}>
                                    <span>{intl.formatMessage({id: 'remindMe'})}</span>
                                    <span className={classes.TurnBox} onClick={() => {
                                        if(isLogin){
                                            remindClick()
                                        } else {
                                            toLogin()
                                        }
                                    }}>
                                        <Turn turnAcitve={remindTurn} disabled={false}/>
                                    </span>
                                </div>
                            }
                            
                        </div>
                    </div>
                </div>
        {/* <img className={classes.SignModuleBackImg} src={backgroundImageStyle} alt="" /> */}
            {/* <div className={classes.SignModuleTitle}>
                {intl.formatMessage({id:'checkedNow'})}
            </div>
            <div className={classes.SignModuleDaily}>
                {
                    dailyData?.dailySignIns?.length > 0 &&
                    dailyData?.dailySignIns.map((item, index) => {
                        return <DayItem intl={intl} data={item} index={index} today={(item?.dateIndex - 1) == dailyData?.todayIndex} modulePart={true}></DayItem>
                    })
                }
            </div>
            <div className={classes.SignCheckOutBox}>
                <div className={classes.button} 
                    onClick={() => {
                        if(window.GeekoSensors){
                            window.GeekoSensors.Track("AnniCheckInClick",{})
                        }
                        if(isLogin){
                            setShowSignIn(true)
                        } else {
                            window.location.href = '/i/login?redirectUrl=' + encodeURI(window.location.pathname)
                        }
                        
                    }}>
                    {intl.formatMessage({id:'checkInNow'})+' >'}
                </div>
            </div> */}
        </div>
        {
            !isApp &&
            <div className={classes.DownloadBoxNew} style={{backgroundImage: backgroundImageBottomStyle,backgroundColor: backgroundColor, }}>
                <div className={classes.DownloadMessage} dangerouslySetInnerHTML={{__html:appCodeMsg || ''}}>
                </div>
                <div className={classes.DownloadBtn} onClick={() => toDownload()}>
                    {intl.formatMessage({id: 'downloadNow'})}
                </div>
            </div>
        }
        
        
        {/* {
            showSignIn &&
            <SignInModule 
                intl={intl}
                appCodeMsg={appCodeMsg}
                dailyData={dailyData}  
                updateData={(data) => setDailyData(data)}
                maskClose={() => setShowSignIn(false)}
                />
        } */}

            {
                modalData &&
                <ClickMask
                    onClose={() => closeMask()}
                    data={modalData}
                    />
            }
        
    </React.Fragment>
}