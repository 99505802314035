import React, { useState } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import BottomToTop from "../../../../components/transitions/bottom-to-top";
import { BlackMask } from "../../../../components/mask";
import { BuyIcon } from "../../../listing/components/cart/cart-icon";
import { getLowerPrice, getDelPrice, unitPrice } from "../../../../../utils/product";
import { IMAGE_GEEKO_LTD } from "../../../../../constants";
import { Iconfont } from "../../../../components/icon/iconfont";
import { FormattedMessage } from "react-intl";
import withSource from '../../../../hocs/source-scoll'

const ShopTheLookProduct = props =>{
    useStyles(classes);
    const { product, sensors } = props;

    const lower = getLowerPrice(product);
    const higher = getDelPrice(product);

    let off = 0
    if (higher) {
        off = Math.round((Number(higher.amount) - Number(lower.amount)) * 100 / Number(higher.amount))
    }

    return <div className={classes.ShopTheLookProduct}>
        <div className={classes.Image}>
            <img src={`${IMAGE_GEEKO_LTD}/medium/${product.pcMainImage}`} alt={`C-Log`} />
        </div>

        <div className={classes.Content}>
            <p className={classes.PName}>
                {product.name}
            </p>

            <div className={classes.PriceContainer}>
                    <div className={classes.Prices}>
                        <span className={classes.RealPrice}>{unitPrice(lower)}</span>

                        {
                            higher && <del className={classes.DealPrice}>{unitPrice(higher)}</del>
                        }
                        {
                            off > 0 && <span className={classes.DiscountPercent}>{`-${off}%`}</span>
                        }
                    </div>

                    <div className={classes.LinkTo}>
                        <BuyIcon
                            sensors={{
                                ...sensors,
                                resourcepage_title: 'clog',
                                resource_type: '20',
                                resource_content: 'shop the look'
                            }}
                            render={() => <Iconfont className={classes.CartIcon}>&#xe6a8;</Iconfont>}
                            productId={product.id} color={product?.variants?.[0]?.color} 
                        />
                    </div>
                </div>
        </div>
    </div>
}

const ShopTheLookItem = props =>{
    useStyles(classes);
    const { products, onClose } = props;

    return <div className={classes.ShopTheLookItem}>
        <div className={classes.Hd}>
            <span><FormattedMessage id="shop_the_look" defaultMessage="Shop the Look" /></span>
            <Iconfont className={classes.CloseIcon} onClick={onClose}>&#xe7c9;</Iconfont>
        </div>

        <div className={classes.Bd}>
            {
                products?.map((product,index) =><ShopTheLookProduct key={product?.id+index} {...props} product={product} />)
            }
        </div>
    </div>
}

const ShopTheLookModal = props =>{
    const { show, onClose } = props;

    return <React.Fragment>
        <BottomToTop in={show} className={classes.ShopTheLookModal}>
            <div>
                <ShopTheLookItem {...props} />
            </div>
        </BottomToTop>

        {
            show && <BlackMask onClick={onClose} />
        }
    </React.Fragment>
    
}

const ShopLookScroll = withSource(props =>{
    const { clickHandle, sensors, innerRef } = props;

    return <span 
        ref={innerRef}
        onClick={clickHandle}
        data-position={sensors?.resource_position}
        data-type={'20'}
        data-content={'shop the look'}
        data-title={'clog'}
    >
        {
            props.render()
        }
    </span>
})

export const CLogShopLook = props =>{
    const { products, sensors } = props;
    const [show, setShow] = useState(false);

    const clickHandle = () => {
        setShow(true);

        if(window.GeekoSensors){
            window.GeekoSensors.Track("PitPositionClick", {
                resourcepage_title: 'clog',
                resource_position: '1',
                resource_type: '20',
                resource_content:'shop the look'
            })
        }
	}

    return <React.Fragment>
        <span onClick={clickHandle}>
            {
                props.render()
            }
        </span>

        <ShopTheLookModal 
            show={show}
            products={products}
            sensors={sensors}
            onClose={()=>setShow(false)}
        />
    </React.Fragment>
    
}

export default props =>{
    useStyles(classes);
    const { products, sensors } = props;
    const [show, setShow] = useState(false);

    const clickHandle = () => {
        setShow(true)
	}

    return <React.Fragment>
        <ShopLookScroll sensors={sensors} render={props.render} clickHandle={clickHandle}  />
        
        <ShopTheLookModal 
            show={show}
            products={products}
            sensors={sensors}
            onClose={()=>setShow(false)}
        />
    </React.Fragment>
}