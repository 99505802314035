import React from "react";
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { BlackMask } from "../../../../../components/mask";
import ReactDOM from 'react-dom'
import { FormattedMessage } from "react-intl";
import CountDown from "../../../../../components/count-down";
import { IMAGE_GEEKO_LTD } from "../../../../../../constants";
import { Iconfont } from "../../../../../components/icon/iconfont";


const LotteryAlertModalItem = props => {
    useStyles(classes)
    const { onClose, modalData } = props;

    return <>
        <div className={classes.LotteryAlertModal}>
            <Iconfont onClick={onClose} className={classes.CloseModal}>&#xe69a;</Iconfont>
            {
                modalData?.font3 && <div className={classes.Font1} style={{ ...modalData?.font3?.style }}>
                    {modalData?.font3?.label}
                </div>
            }

            {
                modalData?.imageUrl && <div className={classes.LotteryAlertModalImage}>
                    <img 
                        src={`${IMAGE_GEEKO_LTD}${modalData?.imageUrl}`} 
                        alt="luckImage" 
                        style={{width:`${modalData?.imageWidth?modalData.imageWidth:'60%'}`}} 
                    />
                </div>
            }

            <div style={{...modalData?.customerMadeStyle}}>
                {
                    modalData?.font1 && <div className={classes.Font1} style={{ ...modalData?.font1?.style }}>
                        {modalData?.font1?.label}
                    </div>
                }

                {
                    modalData?.font2 && <div className={classes.Font1} style={{ ...modalData?.font2?.style }}>
                        <span dangerouslySetInnerHTML={{__html:modalData?.font2?.label}}></span>
                    </div>
                }

                {
                    modalData?.showTimes && <div className={classes.ExpireIn}>
                        <p><FormattedMessage id="expire_in" defaultMessage="Expire In" />:</p>
                        <CountDown 
                            offset={modalData?.showTimes} 
                            showLabel
                            showHour 
                            numberStyle={{backgroundColor: '#222',color:'#ffffff',padding:'2px 2px 2px 4px',fontSize: '12px',fontFamily: 'Roboto-Medium'}} 
                            labelStyle={{backgroundColor: '#222',color:'#ffffff',padding:'2px 4px 2px 0px',textTransform: 'uppercase',fontSize: '12px',fontFamily: 'Roboto-Medium'}} 
                            dotStyle={{color:'#222',margin:'0 3px'}} 
                        />
                    </div>
                }

                <div className={classes.ButtonContainer}>
                    {
                        modalData?.btn1 && <button onClick={modalData?.btn1?.callback} className={`${classes.Btn1} ${modalData?.btn1?.isUppercase?classes.TextUppercase:""}`}>{modalData?.btn1?.label}</button>
                    }

                    {
                        modalData?.btn2 && <button onClick={modalData?.btn2?.callback} className={`${classes.Btn2} ${modalData?.btn2?.isUppercase?classes.TextUppercase:""}`}>{modalData?.btn2?.label}</button>
                    }
                </div>
            </div>

            {/* <img onClick={onClose} className={classes.CloseModal} src={`${IMAGE_GEEKO_LTD}/chicme/2021_07_23/images/2021-07-27-lucky-circle-delete.png`} alt="" /> */}
        </div>
    </>
}

export default props => {
    const { showModal, onClose, modalData , showMaskBg } = props;

    return <React.Fragment>
        {
            showModal && modalData && ReactDOM.createPortal(<React.Fragment>
                <LotteryAlertModalItem {...props} />
                <BlackMask onClick={() => onClose()} style={{backgroundColor: !showMaskBg?'transparent':'rgba(0,0,0,0.5)' }} />
            </React.Fragment>, typeof document !== 'undefined' ? document.getElementById('root') : null)
        }
    </React.Fragment>
}