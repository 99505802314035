import React from 'react'
import SimpleProduct, {EmptyItem} from '../../../../listing/components/list/simple-item'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'

export default props => {
    useStyles(classes)
    const { products, column, productCount, sensors, festival } = props
    return <div className={classes.ListProducts}>
        
        {
           products && products.length > 0 ? products.map((product, index) => <div key={product?.id}>
                <SimpleProduct festival={festival} sensors={sensors} column={column} product={product} position={index}/>
            </div>): Array.from(new Array(productCount||6)).map((product) => <div key={product}>
                <EmptyItem/>
            </div>)
        }
    </div>
}