import React from 'react'
import CountDown from '../../../../../components/count-down'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import FlipClock from '../../../../../components/flip-clock'


export default props => {
    useStyles(classes)
    const {data} = props

    const {offset, endTime, title, backgroundColor, marginTop} = data

    const millils = offset? offset: endTime - new Date().getTime()


    return millils > 1000 ? <div id={data?.id} className={classes.Container} style={{
        backgroundColor,
        marginTop
    }}>
        <div className={classes.Title}>
            { title }
        </div>
        <FlipClock offset={millils}/>
    </div>: <React.Fragment/>
}