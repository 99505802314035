import React, {useCallback} from 'react'
import withList from './hoc/withList'
import SwiperProducts from './components/swiper-products'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { getStyleObjectFromString } from '../../../../utils/style'
import { Types } from './index'
import withObserver from '../hoc/withObserver'
import { useApp } from '../../../../hooks/common'
import { FormattedMessage } from 'react-intl'
import withSource from '../../../../hocs/source-scoll'
import { getSensorsUrl } from '../../../../utils/sensor'


const FlashTitle = withSource(props => {
    useStyles(classes)
    const { imageUlr, innerRef, sensors, url } = props

    return <a ref={innerRef}
        data-position={sensors?.position}
        data-type={sensors?.type}
        data-content={sensors?.refId}
        data-title={sensors?.title}
        className={classes.Hd} href={url}>
        {
            imageUlr && <div className={classes.HdImage}>
                <img src={imageUlr} />
            </div>
        }
        
        {/* <div className={classes.HdIcon}>
            <Iconfont className={classes.Icon}>&#xe690;</Iconfont>
        </div> */}
    </a>
})

export default withList(withObserver(props => {
    useStyles(classes)
    const { products, data, sensors, innerRef } = props

    const isApp = useApp()
    const url = isApp ? data?.appHref : data?.href


    const dark = !!data?.backgroundUrl
    let backgroundImage

    if (dark) {
        backgroundImage = `url(${data?.backgroundUrl})`
    }

    const styles = getStyleObjectFromString(data?.style)

    const clickHandle = useCallback(e => {
        e.preventDefault()
        const href = e.currentTarget?.href
        window.location.href = getSensorsUrl(href, {
            resourcepage_title: sensors?.title,
            resource_type:'1',
            resource_content: data?.refId,
            resource_position: sensors?.position,
        })
    }, [])

    return <div ref={innerRef} id={data?.id} className={`${classes.ListLandingContainer} ${dark ? classes.Dark : ''}`} style={{ paddingTop: `${data?.marginTop}`, ...styles, backgroundImage }}>

        <FlashTitle sensors={{
            ...sensors,
            refId: data?.refId,
            type: '1',
        }} url={url} imageUlr={data?.src} />

        <div className={classes.SwiperProducts}>
            <SwiperProducts sensors={
                {
                    resourcepage_title: sensors?.title,
                    resource_position: sensors?.position,
                    resource_content: data?.refId,
                    resource_type: '1',
                }
            } column={data?.refId} products={products} />

            <div className={classes.ViewAll}>
                <a onClick={clickHandle} href={url}><FormattedMessage id="view_all" defaultMessage="View All" />{' >'}</a>
            </div>
        </div>
    </div>
}))