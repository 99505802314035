import React, { useState, useEffect, useMemo } from "react";
import ReactDOM from 'react-dom'
import cls from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";

import { Iconfont } from "../../../components/icon/iconfont";
import { BigButton } from "../../../components/button";
import { BlackMask } from "../../../components/mask";
import moment from 'moment'
import { fetchBlackIndexCoupons, fetchCustomer, fetchHasLuckDraw, fetchOrderConfirmCoupons, fetchShareUrl, fetchSharedCouponById, getCouponByCouponMouldId, subscribe } from "../../../../api";
import { FormattedMessage, useIntl } from "react-intl";
import Cache from "../../../../utils/cache";
import { useApp, useAppVersion, useBot, useDeviceType, useLogin, useQuery } from "../../../hooks/common";
import { useSelector, useDispatch } from "react-redux";
import { TOGGLE_UI_LOGIN } from "../../../../store/actions/ui";
import { IMAGE_GEEKO_LTD } from "../../../../constants";
import ProductShare from '../../../pages/details/components/product-share/index'
import { isBeforeVersion } from "../../../../utils/version";

const CouponItem = props => {
    const { coupon, isShare, toShare, hideTime } = props;

    return isShare ?
        <GiftCard coupon={coupon} toShare={toShare} hideTime={hideTime} /> :
        <div className={cls.CouponItem}>
            <div className={cls.DiscountCon}>
                <div className={cls.ContentBox}>
                    {
                        coupon?.name && <p className={cls.ItemTitle}>{coupon.name}</p>
                    }

                    {
                        coupon?.condition && <p className={cls.Desc1}>
                            <span dangerouslySetInnerHTML={{ __html: coupon.condition }}></span>
                        </p>
                    }
                    {
                        coupon?.description && <p className={cls.Desc2}>
                            <span dangerouslySetInnerHTML={{ __html: coupon.description }}></span>
                        </p>
                    }

                    {
                        coupon?.infoMsg && <p className={cls.Desc2}>
                            <span dangerouslySetInnerHTML={{ __html: coupon.infoMsg }}></span>
                        </p>
                    }
                </div>
            </div>

            <div className={cls.MeetCon}>
                {
                    coupon?.beginDate && coupon?.endDate && <p className={cls.Desc1}>
                        <span className={cls.Dot}>.</span>
                        <span>{moment(coupon?.beginDate).format('DD/MM/YYYY HH:mm')}~{moment(coupon?.endDate).format('DD/MM/YYYY HH:mm')}</span>
                    </p>
                }

                {/* {
                coupon?.usageReminder && <p className={cls.Desc2}>
                    <span className={cls.Dot}>.</span>
                    <span>{coupon.usageReminder}</span>
                </p>
            } */}
            </div>
        </div>
}

export const GiftCard = props => {
    useStyles(cls)
    const { coupon, toShare, showShareBtn, hideTime } = props

    return coupon && (
        <div className={cls.GiftCardCoupon} style={{ ...props?.style }} onClick={() => toShare(coupon)}>
            <div className={cls.GiftCardBox}>
                <div className={cls.GiftBox}>
                    <img src={`${IMAGE_GEEKO_LTD}/chicme/20230413/gift.png`} alt="" />
                </div>
                <div className={cls.GiftCardInfo}>
                    {
                        coupon?.name && <p className={cls.ItemTitle}>{coupon.name}</p>
                    }

                    {
                        coupon?.condition && <p className={cls.Desc1}>
                            <span dangerouslySetInnerHTML={{ __html: coupon.condition }}></span>
                        </p>
                    }
                    {
                        coupon?.description && <p className={cls.Desc2}>
                            <span dangerouslySetInnerHTML={{ __html: coupon.description }}></span>
                        </p>
                    }

                    {
                        coupon?.infoMsg && <p className={cls.Desc2}>
                            <span dangerouslySetInnerHTML={{ __html: coupon.infoMsg }}></span>
                        </p>
                    }

                    {
                        showShareBtn && <div className={cls.ShareBtn}>
                            <FormattedMessage id="share" defaultMessage={"Share"} />
                        </div>
                    }
                    {
                        !hideTime && coupon?.beginDate && coupon?.endDate && <p className={cls.DateDesc}>
                            <span className={cls.Dot}>·</span>
                            <span>{moment(coupon?.beginDate).format('DD/MM/YYYY HH:mm')}~{moment(coupon?.endDate).format('DD/MM/YYYY HH:mm')}</span>
                        </p>
                    }
                </div>
            </div>
        </div>
    )
}

const CouponAlert = props => {
    const { coupons, handleClose, orderId, refreshCoupon, isConfirm, handleConfirmClose, setShowShare, isShare, errorCode, orderConfirmTitle, lotteryConfig, unsubscribeCustomer } = props;
    const isApp = useApp();
    const global = useSelector(state => state.global);
    const { config } = global;
    const { isiPhone, isAndroid } = global
    const appVersion = useAppVersion()
    const deviceType = useDeviceType()
    const androidShouldShow = deviceType === 'android' ? !isBeforeVersion(appVersion, '3.8.233') : true

    const dispatch = useDispatch();
    const intl = useIntl();
    const query = useQuery();

    const isLogin = useMemo(() => {
        // isRefresh 是app登录完会加在链接上的参数
        return query?.isRefresh || !!global?.user?.id
    }, [global?.user?.id])

    useEffect(() => {
        let sensorData = {
            resourcepage_title: 'coupon',
            resource_position: '1',
            resource_type: '22',
            resource_content: unsubscribeCustomer ? "resubscribe": "coupon"
        };

        if (orderId) {
            sensorData = {
                resourcepage_title: 'coupon',
                resource_position: '1',
                resource_type: '22',
                resource_content: "coupon",
                page_title: 'order_confirm',
                page_sort: 'order_confirm'
            };
        }

        if (window.GeekoSensors) {
            if (isShare && !errorCode) {
                // 首页领取分享优惠券弹窗曝光
                window.GeekoSensors.Track("Popup_GiftCard_Exposure")
            } else {
                window.GeekoSensors.Track("PitPositionExposure", sensorData)
            }
        }
    }, []);

    const couponMessage = useMemo(() => {
        if (isLogin) {
            if (isShare) {
                return <React.Fragment>
                    <div>{intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" })}!</div>
                    <div>{intl.formatMessage({ id: 'gift_card_receive', defaultMessage: 'An exclusive gift card has been placed in your coupon pack' })}!</div>
                </React.Fragment>
            } else if (orderId) {
                // if(isConfirm){
                return <React.Fragment><div dangerouslySetInnerHTML={{ __html: orderConfirmTitle }}></div></React.Fragment>
                // return `${intl.formatMessage({ id: "got_extra_chance_to_win", defaultMessage: "You've got an exclusive VIP offer and a EXTRA CHANCE to win big prize!" })}`
                // }else{
                //     return `${intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" })}!${intl.formatMessage({ id: "received_vip_exclusive_discount", defaultMessage: "You've received VIP Exclusive Discounts" })}`
                // }
            } else if (unsubscribeCustomer) {
                return <FormattedMessage id="unsubscribe_message" defaultMessage="Resubscribe with {email} on {website} and get coupons." values={{ email: unsubscribeCustomer.email, website: config.name }} />
            } else {
                return `${intl.formatMessage({ id: "received_following_discount", defaultMessage: "You've received the Following Discounts" })}`;
            }
        } else {
            if (isShare) {
                return <React.Fragment>
                    <div>{intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" })}!</div>
                    <div>{intl.formatMessage({ id: 'get_gift_card_from_share', defaultMessage: "You'll get an exclusive gift card shared by your friends" })}!</div>
                </React.Fragment>
            }
            return `${intl.formatMessage({ id: "sing_receive_discount_now", defaultMessage: "Sign in to receive the following discounts now!" })}`
        }
    }, [isLogin, orderId, isShare, isConfirm, orderConfirmTitle])

    const handleClick = async (type) => {
        let sensorData = {
            resourcepage_title: 'coupon',
            resource_position: '1',
            resource_type: '22',
            resource_content: unsubscribeCustomer ? "resubscribe": "coupon"
        };

        if (orderId) {
            sensorData = {
                resourcepage_title: 'coupon',
                resource_position: '1',
                resource_type: '22',
                resource_content: "coupon",
                page_title: 'order_confirm',
                page_sort: 'order_confirm'
            };
        }

        if (window.GeekoSensors) {
            // 下单页 有分享券 且不是点抽奖按钮
            if (orderId && coupons?.[0]?.giftCard && !type) {
                window.GeekoSensors.Track("GiftCardShare", { button: 'clickshare' })
            } else if (isShare) {
                window.GeekoSensors.Track("Popup_GiftCard", { button: isLogin ? 'goshopping' : 'collect' })
            } else {
                window.GeekoSensors.Track("PitPositionClick", sensorData)
            }
        }
        if (isLogin) {
            if (type == 'lottery') {
                if (isConfirm) {
                    handleConfirmClose?.();
                } else if (isApp && androidShouldShow) {
                    window.location.href = `chic-me://chic.me/web?href=${config?.root}/sp/applottery`;
                    return
                } else {
                    handleClose();
                }
            } else {
                // 弹窗分享优惠券
                if ((isConfirm && coupons?.[0]?.giftCard) || (orderId && coupons?.[0]?.giftCard)) {
                    let shareParams = {
                        urlCode: "CPN0",
                        couponId: coupons?.[0]?.id,
                        couponMouldId: coupons?.[0]?.couponMouldId
                    }
                    let fetchParams = {
                        urlCode: shareParams?.urlCode || '',
                        params: JSON.stringify({ couponId: shareParams?.couponId || '', couponMouldId: shareParams?.couponMouldId || '' })
                    }
                    if (isApp && isiPhone) {
                        window.location.href = `chic-me://chic.me/shareRoot?couponId=${shareParams?.couponId}&couponMouldId=${shareParams?.couponMouldId}&urlCode=${shareParams?.urlCode}`;
                        return;
                    } else if (isApp && isAndroid) {
                        const response = await fetchShareUrl(fetchParams)
                        if (response?.code == 200) {
                            const data = {
                                "mShareUrlEntity": { ...response?.result },
                                "mUseCouponEntity": coupons?.[0],
                                "messageType": "1"
                            }
                            if (window.andriodFunc) {
                                window?.andriodFunc?.showShare(JSON.stringify(data))
                            } else {
                                alert(intl.formatMessage({ id: 'your_app_is_outdated', defaultMessage: 'Your App Version is OutDated' }))
                            }
                        } else {
                            window.location.href = '/close'
                            return
                        }
                    } else {
                        setShowShare(coupons?.[0])
                    }
                } else {
                    handleClose();
                    if(unsubscribeCustomer){
                        subscribe(unsubscribeCustomer.email)
                    }
                }
            }
        } else {
            if (isApp) {
                window.location.href = "chic-me://chic.me/loginRoot";
                return;
            } else {
                dispatch({
                    type: TOGGLE_UI_LOGIN,
                    payload: {
                        showLogin: true,
                        eventType: "领券",
                        callBack: () => {
                            refreshCoupon();

                            // 做一些类似弹出抽奖之类的处理
                            if (type == 'lottery') {
                                handleConfirmClose?.();
                            } else if (isConfirm && coupons?.[0]?.giftCard) {
                                setShowShare(coupons?.[0])
                            }

                            // refresh parent login status
                            window.parent?.closeHandle?.("1");
                        }
                    }
                })
            }
        }
    }

    return errorCode ?
        <ErrorMask errorCode={errorCode} intl={intl} onClose={handleClose} /> :
        <div className={cls.CouponAlert}>
            <div className={cls.Container}>
                <Iconfont className={cls.Close} onClick={handleClose}>&#xe7c9;</Iconfont>

                <div className={`${cls.Title} ${isShare && cls.TitleUnLimitHeight}`}>
                    {couponMessage}
                </div>

                {
                    !isShare && coupons && coupons?.length >= 1 ?
                        <ul className={cls.CouponList}>
                            {
                                coupons?.map((coupon, index) => (
                                    <li key={coupon?.id + index} className={`${coupon?.giftCard ? cls.ShareLi : ''}`}>
                                        <CouponItem
                                            coupon={coupon}
                                            isShare={coupon?.giftCard}
                                        />
                                    </li>
                                ))
                            }
                        </ul> :
                        <div className={`${cls.GiftCard} ${cls.GiftCardOnly}`}>
                            <CouponItem coupon={coupons?.[0]} isShare={true} hideTime={true} />
                        </div>
                }

                <div className={cls.MainBtn}>


                    {
                        unsubscribeCustomer ? <React.Fragment>
                            <BigButton onClick={handleClick}>
                                <FormattedMessage id="subscribe_and_get" defaultMessage="Subscribe & Get Coupons" />
                            </BigButton>
                        </React.Fragment> : <React.Fragment>
                            {
                                !(orderId && !coupons?.[0]?.giftCard && lotteryConfig && androidShouldShow) &&
                                <BigButton onClick={handleClick}>
                                    {
                                        orderId ?
                                            (coupons?.[0]?.giftCard ?
                                                <FormattedMessage id="collect_share" defaultMessage="Collect & Share" /> :
                                                <FormattedMessage id="collect_all" defaultMessage="Collect All" />) :
                                            isLogin ?
                                                <FormattedMessage id="go_shopping" defaultMessage="Go Shopping" /> :
                                                isShare ?
                                                    <FormattedMessage id="collect_now" defaultMessage="Collect Now" /> :
                                                    <FormattedMessage id="collect_all" defaultMessage="Collect All" />
                                    }
                                </BigButton>
                            }

                            {
                                lotteryConfig && orderId && androidShouldShow &&
                                <BigButton style={{ marginTop: 10 }} onClick={() => handleClick('lottery')}>
                                    <FormattedMessage id="collect_and_draw" defaultMessage="Collect & Draw now" />
                                </BigButton>
                            }
                            {/* <span className={cls.BlackIcon}></span> */}
                        </React.Fragment>
                    }



                </div>
            </div>
        </div>
}

const ErrorMask = props => {
    const { errorCode, onClose, intl } = props
    useStyles(cls)

    // https://image.geeko.ltd/chicme/20230516/coupon-share-link-expired-1.svg
    // https://image.geeko.ltd/chicme/20230516/coupon-share-link-expired.svg
    // https://image.geeko.ltd/chicme/20230516/coupon-share-link-recived.svg
    // https://image.geeko.ltd/chicme/20230516/coupon-share-time-limit.svg

    const errorMsg = useMemo(() => {
        let title, tip, imgUrl, buttonMsg
        // 1 链接过期 2：分享券已失效，3：已达到最大领取数量
        switch (errorCode) {
            case 1:
            case "1":
                title = intl.formatMessage({ id: 'link_expired', defaultMessage: 'Sorry, this link has expired' })
                tip = intl.formatMessage({ id: 'link_valid_for_7_days', defaultMessage: 'It was only valid for 7 days after it was shared.' })
                imgUrl = `${IMAGE_GEEKO_LTD}/chicme/20230516/coupon-share-link-expired-1.svg`
                buttonMsg = intl.formatMessage({ id: 'go_shopping', defaultMessage: 'Go Shopping' })
                break
            case 2:
            case "2":
                title = intl.formatMessage({ id: 'share_coupon_expired', defaultMessage: 'Sorry, the Shared Coupon has Expired' })
                imgUrl = `${IMAGE_GEEKO_LTD}/chicme/20230516/coupon-share-link-expired.svg`
                buttonMsg = intl.formatMessage({ id: 'go_shopping', defaultMessage: 'Go Shopping' })
                break
            case 3:
            case "3":
                title = intl.formatMessage({ id: 'reach_coupon_limit', defaultMessage: 'Sorry, You\'ve Reached the Limit for Coupon Claims' })
                imgUrl = `${IMAGE_GEEKO_LTD}/chicme/20230516/coupon-share-time-limit.svg`
                buttonMsg = intl.formatMessage({ id: 'go_shopping', defaultMessage: 'Go Shopping' })
                break
            case 4:
            case "4":
                title = intl.formatMessage({ id: 'user_time_limit', defaultMessage: 'Sorry,You\'ve Already Received the Coupon.Cannot Be Claimed Again' })
                imgUrl = `${IMAGE_GEEKO_LTD}/chicme/20230516/coupon-share-link-recived.svg`
                buttonMsg = intl.formatMessage({ id: 'go_shopping', defaultMessage: 'Go Shopping' })
                break
        }

        return { title, tip, imgUrl, buttonMsg }
    }, [errorCode])

    return (<React.Fragment>
        <div className={cls.ErrorMask}>
            <Iconfont className={cls.Close} onClick={() => onClose()}>&#xe7c9;</Iconfont>

            <div className={`${cls.Title} ${(errorCode == 4 || errorCode == "4") && cls.MinTitle}`}>
                {errorMsg?.title || ''}
            </div>

            <div className={cls.Tip}>
                {errorMsg?.tip || ''}
            </div>

            <div className={cls.Img}>
                <img src={errorMsg?.imgUrl || ''} alt="" />
            </div>

            <div className={cls.Button} onClick={() => onClose()}>
                <BigButton>
                    {errorMsg?.buttonMsg || ''}
                </BigButton>
            </div>
        </div>
    </React.Fragment>)
}

export default props => {
    useStyles(cls);
    const { orderId, onClose, onConfirmClose } = props;
    const isApp = useApp();
    const query = useQuery();
    const dispatch = useDispatch();
    const [coupons, setCoupons] = useState(null);
    const [show, setShow] = useState(false);
    const [unsubscribeCustomer, setUnsubscribeCustomer] = useState(null)
    const [showShare, setShowShare] = useState(false);
    const [errorCode, setErrorCode] = useState(); // 领取分享优惠券时可能会报错的弹窗
    const [orderConfirmTitle, setOrderConfirmTitle] = useState(); // 下单弹窗文案 html
    const [lotteryConfig, setLotteryConfig] = useState(false); // 是否开了抽奖
    const isShareCoupon = query?.couponMouldId
    const isLogin = useLogin()

    useEffect(async () => {
        try {
            if (isShareCoupon || query?.couponShareErrorCode) {
                // 领取分享优惠券弹窗
                isShareCoupon && shareCouponAlert()
                setShow(true)
                setErrorCode(Cache.get("couponShareErrorCode") || query?.couponShareErrorCode)
            } else if (orderId) {
                orderCouponAlert(orderId);
            } else {
                indexCouponAlert();
            }
        } catch (error) {
            console.log('error', error)
        }
    }, [])

    // app或者嵌入iframe的地方调用外部关闭的通用方法
    const iframeDispose = () => {
        if (isApp) {
            window.location.href = '/close'
            return;
        } else {
            window.parent?.closeHandle?.();
            return;
        }
    }

    // 获取orderconfirm优惠券弹窗
    const orderCouponAlert = async (orderId) => {
        let response = await fetchOrderConfirmCoupons(orderId);
        if (response?.code === 200 && response?.result?.coupons?.length > 0) {
            setShow(true);
            setCoupons(response.result?.coupons);
            setOrderConfirmTitle(response.result?.title);
        } else {
            iframeDispose();
        }
        let response1 = await fetchHasLuckDraw();
        if (response1?.code === 200 && response1?.result) {
            setLotteryConfig(response1?.result || false)
        } else {
            setLotteryConfig(false)
        }
    }


    // 获取首页优惠券弹窗
    const indexCouponFetch = async () => {
        const couponPromise = fetchBlackIndexCoupons()
        const customerPromise = isLogin ? fetchCustomer().catch(() => null) : Promise.resolve(null)
        // const customerPromise = isLogin ? Promise.resolve({
        //    result: {
        //     email: 'test@13.com',
        //     subscription: false
        //    },
        //    code: 200
        // }) : Promise.resolve(null)
        const [couponResponse, customerResponse] = await Promise.all([couponPromise, customerPromise])

        if (couponResponse?.code === 200 && couponResponse?.result?.length > 0) {
            if (customerResponse?.code === 200 && customerResponse.result && !!!customerResponse.result.subscription) {
                // 未订阅邮件
                setUnsubscribeCustomer(customerResponse.result)
            }
            setShow(true);
            setCoupons(couponResponse.result);
        } else {
            iframeDispose();
        }
    }

    const indexCouponAlert = async () => {
        const couponAlert = Cache.get('black-coupons');
        // 与首页show_lottery弹出抽奖逻辑互斥
        // isLimit 是只有app首页弹出才会携带 落地页不弹出coupon alert 不走我们缓存逻辑
        // app登录之后会携带isRefresh参数
        if (query?.isLimit || query?.isRefresh || (!couponAlert && !query?.show_lottery)) {
            Cache.set('black-coupons', 1);
            indexCouponFetch()
        } else {
            iframeDispose();
        }
    }

    const shareCouponAlert = async () => {
        if ((query?.isRefresh) || !query?.show_lottery) {
            const res = await getCouponByCouponMouldId({ couponMouldId: isShareCoupon })
            if (res && res?.code == 200 && res?.result) {
                // 根据id获取coupon信息
                setShow(true);
                setCoupons([res?.result])
            } else {
                iframeDispose();
            }
        } else {
            iframeDispose();
        }
    }

    const handleClose = () => {
        if (errorCode) {
            failLink()
        }
        iframeDispose();
        setShow(false);
        onClose?.();
    }

    const failLink = () => {
        Cache.remove("couponShareErrorCode")
        window.location.href = '/'
        return
    }

    const handleConfirmClose = () => {
        // 不要关闭总体的iframe
        // iframeDispose();
        setShow(false);

        // 区分上面的onClose方法 形成两套可以一个按钮做的事情 一个整体关闭的事情
        onConfirmClose?.();
    }

    const refreshCoupon = () => {
        if (isShareCoupon) {
            setErrorCode(Cache.get("couponShareErrorCode") || query?.couponShareErrorCode)
            shareCouponAlert()
        } else if (orderId) {
            orderCouponAlert(orderId);
        } else {
            indexCouponFetch();
        }
    }

    const fetchParams = useMemo(() => {
        if (showShare) {
            return { urlCode: 'CPN0', params: JSON.stringify({ couponId: showShare?.id, couponMouldId: showShare?.couponMouldId }) }
        } else {
            return {}
        }
    }, [showShare])

    const isBot = useBot()

    return <React.Fragment>
        {
            show && (coupons?.length > 0 || query?.couponShareErrorCode) && !isBot && ReactDOM.createPortal(<React.Fragment>
                <CouponAlert
                    {...props}
                    unsubscribeCustomer={unsubscribeCustomer}
                    isShare={isShareCoupon}
                    errorCode={errorCode}
                    handleClose={handleClose}
                    coupons={coupons}
                    refreshCoupon={refreshCoupon}
                    setShowShare={setShowShare}
                    lotteryConfig={lotteryConfig}
                    orderConfirmTitle={orderConfirmTitle}
                    handleConfirmClose={handleConfirmClose} />
                <BlackMask style={{ zIndex: 6 }} onClick={handleClose} />

                <ProductShare
                    showShareModal={showShare}
                    fetchParams={fetchParams}
                    onClose={() => setShowShare(null)}
                    isShare={true}
                >
                    <div className={cls.GiftCardShareBox}>
                        <GiftCard coupon={showShare} toShare={() => { }} />
                    </div>
                </ProductShare>

            </React.Fragment>, typeof document !== 'undefined' ? document.getElementById('root') : null)
        }
    </React.Fragment>
}