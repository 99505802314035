import React from 'react'
import withList from './hoc/withList'
import ImageContainer from '../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ListProducts from './components/list-products'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { getStyleObjectFromString } from '../../../../utils/style'
import { Types } from './index'
import withObserver from '../hoc/withObserver'
import { useApp } from '../../../../hooks/common'

const List6 = withList(withObserver(props => {
    useStyles(classes)
    const { products, data, sensors, innerRef, top, buyButton } = props
    const isApp = useApp()

    const styles = getStyleObjectFromString(data?.style)

    const url = isApp ? data?.appHref : data?.href

    const aspectRatio = data?.width && data?.height ? `${data?.width} / ${data?.height}` : undefined

    return <div ref={innerRef} id={data?.id} className={classes.ListNewContainer} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        <div className={classes.Bd}>
            {
                data?.src && <ImageContainer style={{ aspectRatio }} href={url} sensors={{
                    ...sensors,
                    refId: data?.refId,
                    type: '1',
                }} position={0}>
                    <LazyLoadImage alt={data?.title} src={data?.src} />
                </ImageContainer>
            }

            <div className={classes.ListProducts}>
                <ListProducts sensors={
                    {
                        resourcepage_title: sensors?.title,
                        resource_position: sensors?.position,
                        resource_content: data?.refId,
                        resource_type: '1',
                    }
                } column={data?.refId} productCount={3} products={products} />
            </div>
        </div>
    </div>
}))


export default props => <List6  {...props} data={{ ...props.data, productCount: 3 }} />