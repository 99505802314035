import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Iconfont } from '../../components/icon/iconfont'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import { useSelector } from 'react-redux'
import Badge from '../../components/badge'
import { Link, useLocation } from 'react-router-dom'
import { useCallback } from 'react'
import { IMAGE_GEEKO_LTD } from '../../../constants'
import Cookies from 'js-cookie'

const Bubble = props => {
    useStyles(classes)
    const { msg, onClose, meetFreeShipping } = props

    return (
        <div className={classes.BubbleBox}>
            {
                meetFreeShipping == 1 &&
                <img className={classes.BubbleIcon} src={`${IMAGE_GEEKO_LTD}/chicme/20230530/free-shipping-icon.svg`}></img>
            }
            <div className={classes.BubbleMsg}>
                {
                    msg ?
                    <span dangerouslySetInnerHTML={{__html: msg}}></span>:
                    <React.Fragment>
                        <FormattedMessage id="enjoy_free_shipping" defaultMessage={'Enjoy Free Shipping'}/>!
                    </React.Fragment>
                }
            </div>
            <Iconfont className={classes.BubbleClose} onClick={onClose}>&#xe7c9;</Iconfont>
        </div>
    )
}

const BubbleMenu = props => {
    useStyles(classes)
    const {
        to,
        href,
        selected,
        className,
        title,
        dotted,
        count,
        icon,
        sensorType,
        meetFreeShipping,
        msg
    } = props;

    const [showBubble, setShowBubble] = useState(false)

    const handleClick = useCallback((evt, sensorType) => {
        // evt.preventDefault();
        if (window.GeekoSensors) {
            window.GeekoSensors.Track('ClickBottomTab', {
                tab: sensorType,
                is_freeshipping: meetFreeShipping == 1 ? true: false
            })
        }
    }, [meetFreeShipping]);

    useEffect(() => {
        const hasCookie = Cookies.get("free_shipping_bubble")
        if(meetFreeShipping && !hasCookie){
            setShowBubble(true)
            setTimeout(() => {
                closeBubble()
            }, 3000)
        }
    }, [meetFreeShipping])

    const closeBubble = () => {
        setShowBubble(false)
        let expires = new Date(new Date() * 1 + 24 * 60 * 60 * 1000)
        Cookies.set("free_shipping_bubble", 1, { path: '/', expires: expires })
    }

    return to ?
    <Link to={to} className={`${classes.IconMenu} ${selected ? classes.Active : ''} ${className ? className : ''}`} onClick={(e) => handleClick(e, sensorType)}>
        <Badge count={count} dotted={dotted}>
            <Iconfont className={classes.Icon} dangerouslySetInnerHTML={{ __html: icon }} />
        </Badge>
        <div className={classes.Title}>
            {title}
        </div>
        {
            showBubble && meetFreeShipping &&
            <Bubble msg={msg} meetFreeShipping={meetFreeShipping} onClose={() => closeBubble()}/>
        }
    </Link> :
    <a href={href} className={`${classes.IconMenu} ${selected ? classes.Active : ''} ${className ? className : ''}`} onClick={(e) => handleClick(e, sensorType)}>
        <Badge count={count} dotted={dotted}>
            <Iconfont className={classes.Icon} dangerouslySetInnerHTML={{ __html: icon }} />
        </Badge>
        <div className={classes.Title}>
            {title}
        </div>
        {
            showBubble && meetFreeShipping &&
            <Bubble msg={msg} meetFreeShipping={meetFreeShipping} onClose={() => closeBubble()}/>
        }
    </a>
}

const ImageMenu = props => {
    useStyles(classes)
    const {
        selected,
        normalImage,
        activeImage,
        className,
        iconClassName
    } = props;

    return props.to ? <Link to={props.to} className={`${classes.ImageMenu} ${className ? className : ''}`} >
        <Badge count={props.count} dotted={props.dotted}>
            <span className={`${classes.Icon} ${iconClassName ? iconClassName : ''}`} style={{ backgroundImage: `url(${selected ? activeImage : normalImage})` }}></span>
        </Badge>
        <div className={classes.Title}>
            {props?.title}
        </div>
    </Link> : <a href={props.href} className={`${classes.ImageMenu} ${className ? className : ''}`} >
        <Badge count={props.count} dotted={props.dotted}>
            <span className={`${classes.Icon} ${iconClassName ? iconClassName : ''}`} style={{ backgroundImage: `url(${selected ? activeImage : normalImage})` }}></span>
        </Badge>
        <div className={classes.Title}>
            {props?.title}
        </div>
    </a>
}

const IconMenu = props => {
    useStyles(classes)

    const handleClick = useCallback((evt, sensorType) => {
        // evt.preventDefault();
        if (window.GeekoSensors) {
            window.GeekoSensors.Track('ClickBottomTab', {
                tab: sensorType
            })
        }
    }, []);

    return props.to ? <Link to={props.to} className={`${classes.IconMenu} ${props.selected ? classes.Active : ''} ${props.className ? props.className : ''}`} onClick={(e) => handleClick(e, props.sensorType)}>
        <Badge count={props.count} dotted={props.dotted}>
            <Iconfont className={classes.Icon} dangerouslySetInnerHTML={{ __html: props.icon }} />
        </Badge>
        <div className={classes.Title}>
            {props.title}
        </div>
    </Link> : <a href={props.href} className={`${classes.IconMenu} ${props.selected ? classes.Active : ''} ${props.className ? props.className : ''}`} onClick={(e) => handleClick(e, props.sensorType)}>
        <Badge count={props.count} dotted={props.dotted}>
            <Iconfont className={classes.Icon} dangerouslySetInnerHTML={{ __html: props.icon }} />
        </Badge>
        <div className={classes.Title}>
            {props.title}
        </div>
    </a>
}

export default props => {
    useStyles(classes);
    const global = useSelector(state => state.global)
    const { config, language } = global;
    const location = useLocation()

    const isCurrentPath = useCallback((p, targePath) => {
        return p?.indexOf(targePath) >= 0
    }, [])

    const isHomePath = useCallback(p => {
        return p === '/' || /^\/(en|de|es)\/$/.test(p)
    }, [])

    const isVShop = useCallback((l) => {
        const pathName = l?.pathname;
        return pathName?.indexOf('/v-shop') >= 0 || pathName?.indexOf('/user') >= 0 || pathName?.indexOf('/i/black-friday') >= 0;
    }, [])

    const BagTitle = useMemo(() => {
        let dom = ''
        switch(language){
            case "de":
                dom = <img style={{height:16}} src={`${IMAGE_GEEKO_LTD}/chicme/20230530/free-shipping-de.svg`}/>
                break
            case "es":
                dom = <img style={{height:16}} src={`${IMAGE_GEEKO_LTD}/chicme/20230530/free-shipping-es.svg`}/>
                break
            case "fr":
                dom = <img style={{height:16}} src={`${IMAGE_GEEKO_LTD}/chicme/20230530/free-shipping-fr.svg`}/>
                break
            case "pt":
                dom = <img style={{height:16}} src={`${IMAGE_GEEKO_LTD}/chicme/20230530/free-shipping-pt.svg`}/>
                break
            default:
                dom = <img style={{height:16}} src={`${IMAGE_GEEKO_LTD}/chicme/20230530/free-shipping-en.svg`}/>
                break
        }
        return dom
    }, [language])

    return <div className={classes.BottomNavigation}>
        <IconMenu to={!isVShop(location) ? "/" : undefined} href={isVShop(location) ? "/" : undefined} selected={isHomePath(location.pathname)} icon={'&#xe6b3;'} title={<FormattedMessage id="home" defaultMessage="home" />} sensorType="home" />
        <IconMenu to={!isVShop(location) ? "/i/menu" : undefined} href={isVShop(location) ? "/i/menu" : undefined} selected={isCurrentPath(location.pathname, '/i/menu')} icon="&#xe6b1;" title={<FormattedMessage id="category" defaultMessage="Category" />} sensorType="category" />

        {/* {
            config?.showBlackFriday && <React.Fragment>
                <ImageMenu
                    href={`/i/black-friday`}
                    selected={isCurrentPath(location.pathname, '/i/black-friday')}
                    // title={'Black Friday'}
                    normalImage={`${IMAGE_GEEKO_LTD}/chicme/20221126/cm-cybermonday-active.svg`}
                    activeImage={`${IMAGE_GEEKO_LTD}/chicme/20221126/cm-cybermonday-active.svg`}
                    className={classes.BlackFridayIcon}
                    iconClassName={classes.BlackFridayIconItem}
                />
            </React.Fragment>
        } */}

        <IconMenu to={!isVShop(location) ? '/landing-page/new' : undefined} href={isVShop(location) ? '/landing-page/new' : undefined} selected={isCurrentPath(location.pathname, '/landing-page/new')} icon="&#xe6bf;" dotted="show" title={<FormattedMessage id="new_in" defaultMessage="New" />} sensorType="new" />

        {/* <IconMenu href="/cart" selected={isCurrentPath(location.pathname, '/cart')} count={global.countCart} icon="&#xe6b2;" title={<FormattedMessage id="bag" defaultMessage="Bag" />} sensorType="cart" /> */}
        <BubbleMenu
            href="/cart"
            selected={isCurrentPath(location.pathname, '/cart')}
            count={global.countCart}
            meetFreeShipping={global.meetFreeShipping}
            icon="&#xe6b2;"
            title={global.meetFreeShipping? BagTitle: <FormattedMessage id="bag" defaultMessage="Bag" />}
            msg={global.meetFreeShippingMsg}
            sensorType="cart" />
        {/* {
            !config?.showBlackFriday && <React.Fragment>
                {
                    config?.showClog ? <React.Fragment>
                        <IconMenu href={`/v-shop`} selected={isCurrentPath(location.pathname, '/v-shop')} icon="&#xe787;" title={<FormattedMessage id="v_shop" defaultMessage="V-Shop" />} className={classes.VShopIcon} sensorType="vshop" />
                    </React.Fragment> : <React.Fragment>
                    </React.Fragment>
                }
            </React.Fragment>
        }
        <IconMenu href="/user" selected={isCurrentPath(location.pathname, '/user')} icon="&#xe6b0;" title={<FormattedMessage id="account" defaultMessage="Account" sensorType="account"/>} />
        } */}

        <IconMenu href="/user" selected={isCurrentPath(location.pathname, '/user')} icon="&#xe6b0;" title={<FormattedMessage id="account" defaultMessage="Account" />} sensorType="account" />
    </div>
}