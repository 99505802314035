import React, { useEffect, useRef, useState, useCallback } from "react";
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { IMAGE_GEEKO_LTD } from "../../../../../constants";
import { fetchGetLotteryPrizes, fetchLuckDraw, fetchGetUserLastTimes, fetchLuckLastesthistory } from "../../../../../api";
import { isIOS, isMacOs } from 'react-device-detect'
import { useSelector } from "react-redux";
import { v4 } from 'uuid'
import LotteryAlertModal from "./components/remind-modal";
import Cache from "../../../../../utils/cache";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from 'react-redux'
import { TOGGLE_UI_LOGIN } from '../../../../../store/actions/ui'
import LotteryRulesPrizes from "./components/lottery-rules-prizes";
import LotteryDrawHistory from "./components/lottery-draw-history";
import { useApp, useQuery } from "../../../../hooks/common";

const RowItem = props => {
    const { content, activedId, lottery } = props;

    return <div className={`${classes.AreaRowItem} ${activedId === content ? classes.Active : ""}`} id={`row_item_${content}`}>
        <div className={classes.Image}>
            <img src={`${IMAGE_GEEKO_LTD}${lottery?.pcImageUrl}`} alt="lottery" />
        </div>

        <div className={classes.Font}>
            {lottery?.name}
        </div>
    </div>
}

const LotteryContainer = props => {
    useStyles(classes);
    const { allPrizes, letteryCount, handleClick } = props;
    const timeRef = useRef();
    const normalData = useRef({
        times: 0,
        actTimes: 0,
        speed: 40,
        activedId: '',
        prizeId: null
    });
    const [data, setData] = useState({
        activedId: '',
        isRolling: false,
    });

    const intl = useIntl();

    const dispatch = useDispatch();

    const [lotteryResult, setLotteryResult] = useState(null);

    const [showModal, setShowModal] = useState(false);

    const [modalData, setModalData] = useState(null);

    const global = useSelector(state => state.global);

    const isApp = useApp();

    useEffect(() => {
        return () => {
            clearInterval(timeRef.current)
        }
    }, []);

    useEffect(() => {
        if (lotteryResult) {
            let prize = allPrizes?.findIndex(item => item?.id === lotteryResult?.id);

            console.log(prize)

            normalData.current.times = allPrizes.length * Math.floor(Math.random() * 5 + 4);
            normalData.current = { ...normalData.current, prizeId: prize };

            prize >= 0 && setTimeOutEvent();

            props?.getUserLastTimes();
        }
    }, [lotteryResult?.id]);

    const handleBegin = async () => {
        // data.isRolling为false的时候才能开始抽，不然会重复抽取，造成无法预知的后果
        if (!data.isRolling) {
            let luckShowed = Cache.get("__luckshowed");
            // 点击抽奖之后，我个人做法是将于九宫格有关的状态都还原默认
            if (!global?.user || !global?.user.id) {
                if(isApp){
                    window.location.href = "chic-me://chic.me/loginRoot"
                }else{
                    dispatch({
                        type: TOGGLE_UI_LOGIN,
                        payload: {
                            showLogin:true,
                            callBack:()=>{
                                props?.getUserLastTimes?.();
                            }
                        }
                    });
                }
                return;
            }

            //无抽奖机会提示
            if (letteryCount == 0) {
                setShowModal(true);

                setModalData({
                    btn2: {
                        label: `${intl.formatMessage({ id: "ok", defaultMessage: "OK" })}`,
                        callback: () => {
                            setShowModal(false);
                        }
                    },
                    font1: {
                        label: `${intl.formatMessage({ id: "today_no_change", defaultMessage: "You've run out of chances today, go shopping" })}!`,
                        style: {
                            fontSize: "14px",
                            marginTop:"15px"
                        }
                    },
                    imageUrl: "/chicme/2022052401/no_times_mask.png",
                });

                return;
            }

            if ((global?.user || global?.user.id)) {
                if (!luckShowed) {
                    Cache.set("__luckshowed", 1, 24 * 60 * 60);
                }

                if (letteryCount > 0) {
                    await setData(state => ({
                        ...state,
                        isRolling: true,
                    }))

                    let initData = {
                        times: 0,
                        actTimes: 0,
                        speed: 40,
                        prizeId: null,
                    };

                    normalData.current = { ...normalData.current, ...initData };

                    handlePlay();
                }
            }
        }

        if (window.GeekoSensors) {
            window.GeekoSensors.Track('PopUp', {
                type: "转盘抽奖",
                page_content: "popup",
                is_join: true
            })
        }
    }

    const collectCart = (webHref, appHref) =>{
        if(isApp){
            window.location.href = appHref;
        }else{
            window.location.href = webHref;
        }
    }

    const setTimeOutEvent = () => {
        let num;

        if (normalData.current.activedId === normalData.current.prizeId && normalData.current.actTimes > normalData.current.times) {
            // 符合上述所有条件时才是中奖的时候，两个ID相同并且动画执行的次数大于(或等于也行)设定的最小次数
            clearInterval(timeRef.current)
            setData(state => ({ ...state, isRolling: false }));

            // 积分 type:1
            // 优惠券 type  2
            // 礼物实物 type 3 
            // thanks  type 4
            // 现金券 type 5 送到优惠券卡包的现金券
            // 美妆实物  type 6
            // 现金券 type 7 送到钱包的现金券
            let modalItem;
            if (lotteryResult?.type == 1) {
                modalItem = {
                    btn1: {
                        label: intl.formatMessage({ id: "got_it", defaultMessage: "Got it" }),
                        isUppercase:true,
                        callback: () => {
                            setShowModal(false);
                        }
                    },
                    btn2: {
                        label: intl.formatMessage({ id: "view_prizes", defaultMessage: "View Prizes" }),
                        isUppercase:true,
                        callback: () => {
                            setShowModal(false);

                            collectCart("/user/credits", "chic-me://chic.me/credits");
                        }
                    },
                    font1: {
                        label: `${intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" })}!`,
                        style: {
                            fontSize: "12px"
                        }
                    },
                    font2: {
                        label: lotteryResult?.name,
                        style: {
                            fontSize: "24px",
                            marginTop: "5px",
                            textTransform: "uppercase"
                        }
                    },
                    // showTimes: 24 * 60 * 60 * 1000,
                    imageUrl: lotteryResult?.imageUrl
                };
            } else if (lotteryResult?.type == 2) {
                modalItem = {
                    btn1: {
                        label: intl.formatMessage({ id: "got_it", defaultMessage: "Got it" }),
                        isUppercase:true,
                        callback: () => {
                            setShowModal(false);
                        }
                    },
                    btn2: {
                        label: intl.formatMessage({ id: "view_prizes", defaultMessage: "View Prizes" }),
                        isUppercase:true,
                        callback: () => {
                            setShowModal(false);

                            collectCart("/user/coupons", "chic-me://chic.me/coupon");
                        }
                    },
                    font1: {
                        label: `${intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" })}!`,
                        style: {
                            fontSize: "12px"
                        }
                    },
                    font2: {
                        label: lotteryResult?.name,
                        style: {
                            fontSize: "24px",
                            marginTop: "5px",
                            textTransform: "uppercase"
                        }
                    },
                    showTimes: 24 * 60 * 60 * 1000,
                    imageUrl: lotteryResult?.imageUrl
                };
            } else if (lotteryResult?.type == 3) {
                modalItem = {
                    btn1: {
                        label: intl.formatMessage({ id: "got_it", defaultMessage: "Got it" }),
                        callback: () => {
                            setShowModal(false);
                        }
                    },
                    btn2: {
                        label: intl.formatMessage({ id: "view_prizes", defaultMessage: "View Prizes" }),
                        callback: () => {
                            setShowModal(false);

                            collectCart("/cart", "chic-me://chic.me/shoppingcart");
                        }
                    },
                    font1: {
                        label: `${intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" })}!`,
                        style: {
                            fontSize: "12px",
                            marginTop:"10px"
                        }
                    },
                    font2: {
                        label: lotteryResult?.name,
                        style: {
                            fontSize: "22px",
                            marginTop: "5px",
                            textTransform: "capitalize"
                        }
                    },
                    imageUrl: lotteryResult?.imageUrl,
                    imageWidth:"35%",
                    showTimes: 24 * 60 * 60 * 1000,
                }
            } else if(lotteryResult?.type == 5){
                modalItem = {
                    btn1: {
                        label: intl.formatMessage({ id: "got_it", defaultMessage: "Got it" }),
                        isUppercase:true,
                        callback: () => {
                            setShowModal(false);
                        }
                    },
                    btn2: {
                        label: intl.formatMessage({ id: "view_prizes", defaultMessage: "View Prizes" }),
                        isUppercase:true,
                        callback: () => {
                            setShowModal(false);

                            collectCart("/user/coupons", "chic-me://chic.me/coupon");
                        }
                    },
                    font3: {
                        label: `${intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" })}!`,
                        style: {
                            fontSize: "12px",
                            marginBottom:"10px"
                        }
                    },
                    imageUrl: lotteryResult?.imageUrl,
                    // imageWidth:"35%",
                    showTimes: 24 * 60 * 60 * 1000,
                }
            }else if (lotteryResult?.type == 6) {
                modalItem = {
                    btn1: {
                        label: intl.formatMessage({ id: "got_it", defaultMessage: "Got it" }),
                        callback: () => {
                            setShowModal(false);
                        }
                    },
                    btn2: {
                        label: intl.formatMessage({ id: "view_prizes", defaultMessage: "View Prizes" }),
                        callback: () => {
                            setShowModal(false);

                            collectCart("/cart", "chic-me://chic.me/shoppingcart");
                        }
                    },
                    font1: {
                        label: `${intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" })}!`,
                        style: {
                            fontSize: "12px",
                            marginTop:"10px"
                        }
                    },
                    font2: {
                        label: lotteryResult?.name,
                        style: {
                            fontSize: "22px",
                            marginTop: "5px",
                            textTransform: "capitalize"
                        }
                    },
                    imageUrl: lotteryResult?.imageUrl,
                    imageWidth:"35%",
                    showTimes: 24 * 60 * 60 * 1000,
                }
            } else if(lotteryResult?.type == 7){
                modalItem = {
                    btn1: {
                        label: intl.formatMessage({ id: "got_it", defaultMessage: "Got it" }),
                        isUppercase:true,
                        callback: () => {
                            setShowModal(false);
                        }
                    },
                    btn2: {
                        label: intl.formatMessage({ id: "view_prizes", defaultMessage: "View Prizes" }),
                        isUppercase:true,
                        callback: () => {
                            setShowModal(false);

                            collectCart("/user/wallet", "chic-me://chic.me/wallet");
                        }
                    },
                    font3: {
                        label: `${intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" })}!`,
                        style: {
                            fontSize: "12px",
                            marginBottom:"10px"
                        }
                    },
                    imageUrl: lotteryResult?.imageUrl,
                    // imageWidth:"35%",
                    showTimes: 24 * 60 * 60 * 1000,
                }
            }else {
                modalItem = {
                    btn2: {
                        label: intl.formatMessage({ id: "player_again", defaultMessage: "play again" }),
                        callback: () => {
                            // props?.onDisplayClose(true);
                            setShowModal(false);
                        }
                    },
                    font1: {
                        label: `${intl.formatMessage({ id: "thanks", defaultMessage: "Thanks" })}!`,
                        style: {
                            fontSize: "12px",
                            fontFamily: 'Roboto-Medium'
                        }
                    },
                    font2: {
                        label: `${intl.formatMessage({ id: "please_try_again", defaultMessage: "Oops, please try again" })}!`,
                        style: {
                            fontSize: "16px"
                        }
                    },
                    imageUrl: lotteryResult?.imageUrl
                };
            }

            setModalData(modalItem);
            setShowModal(true);
            setLotteryResult(null);
            props?.getDrawHistory?.();
            return;
        }

        if ((normalData.current.times - normalData.current.actTimes) > 0 && (normalData.current.times - normalData.current.actTimes) <= 4) {
            normalData.current.speed += 90;
        }

        // 以下是动画执行时对id的判断
        if (normalData.current.activedId === '') {
            num = 0
        } else {
            num = normalData.current.activedId;
            if (num === 8) {
                num = 0
            } else {
                num = num + 1
            }
        }

        normalData.current.activedId = num;
        setData(state => ({ ...state, activedId: num }));


        normalData.current.actTimes += 1;

        timeRef.current = setTimeout(setTimeOutEvent, normalData.current.speed);
    }

    const handlePlay = async () => {
        // let prize = Math.floor(Math.random() * 8);
        // setLotteryResult(allPrizes[prize]);
        // return;
        const randomid = v4();
        let response = await fetchLuckDraw(randomid);
        if (response?.code === 200) {
            let result = response?.result;
            setLotteryResult(result);

            if (window.GeekoSensors) {
                let lottery_result = response?.result?.name || '接口返回为空'
                if(response?.result?.physicalInfo){
                    lottery_result += '_' + response?.result?.physicalInfo
                }
                window.GeekoSensors.Track('PopUp', {
                    type: "转盘抽奖",
                    lottery_result: lottery_result,
                    is_join: false,
                    is_view: false
                })
            }
        } else {
            alert(response?.result);
            props?.getUserLastTimes();
        }
    }


    return <>
        <div className={classes.AreaContainer}>
            <div className={classes.AreaRow}>
                <RowItem lottery={allPrizes[0]} activedId={data?.activedId} content={0} />
                <RowItem lottery={allPrizes[1]} activedId={data?.activedId} content={1} />
                <RowItem lottery={allPrizes[2]} activedId={data?.activedId} content={2} />
            </div>
            <div className={classes.AreaRow}>
                <RowItem lottery={allPrizes[7]} activedId={data?.activedId} content={7} />
                <div className={`${classes.AreaRowItem} ${classes.StartCenter}`} onClick={() => handleBegin()}></div>
                <RowItem lottery={allPrizes[3]} activedId={data?.activedId} content={3} />
            </div>
            <div className={classes.AreaRow}>
                <RowItem lottery={allPrizes[6]} activedId={data?.activedId} content={6} />
                <RowItem lottery={allPrizes[5]} activedId={data?.activedId} content={5} />
                <RowItem lottery={allPrizes[4]} activedId={data?.activedId} content={4} />
            </div>
        </div>

        <LotteryAlertModal 
            showMaskBg
            modalData={modalData} 
            showModal={showModal} 
            onClose={() => {
                setShowModal(false)
            }} 
        />
    </>
}


const IndexLottery = props => {
    useStyles(classes);
    const [letteryCount, setLotteryCount] = useState('?');
    const [showRules, setShowRules] = useState(false);
    const [drawHistory, setDrawHistory] = useState(null);
    const global = useSelector(state => state.global);
    const { config } = global;

    const getUserLastTimes = async () => {
        let response = await fetchGetUserLastTimes();
        if (response?.code === 200) {
            setLotteryCount(response?.result);
        }
    }

    const getDrawHistory = async () => {
        let response = await fetchLuckLastesthistory();
        if (response?.code === 200) {
            setDrawHistory(response?.result);
        }
    }

    useEffect(() => {
        if (!global?.user || !global?.user.id) {
            setLotteryCount('?');
        } else {
            getUserLastTimes();
        }

        getDrawHistory();
    }, []);

    const handleClick = useCallback(() => {
        if (isIOS || isMacOs) {
            setTimeout(() => {
                window.location.href = `https://itunes.apple.com/us/app/chic-me-shopping-sharing-saving/id${config?.app.ios.id}?l=zh&ls=1&mt=8`;
            }, 200);
            window.location.href = `chic-me://chic.me/`;
        } else {
            setTimeout(() => {
                window.location.href = `https://play.google.com/store/apps/details?id=${config?.app.android.id}`;
            }, 200);
            window.location.href = `chic-me://chic.me/`;
        }

    }, [])

    return <div className={classes.LotteryContainer}>
        <div className={classes.LotteryHd}>
            <span className={classes.LotteryHdItem}>
                <span className={classes.Font}><FormattedMessage id="you_have" defaultMessage="You Have" /> {letteryCount} <FormattedMessage id="changes" defaultMessage="Chances" /></span>
                {/* <Iconfont className={classes.Icon}>&#xe73f;</Iconfont> */}
            </span>
        </div>

        <div className={classes.LotteryBox}>
            <div className={classes.LotteryDraw}>
                <div className={classes.LotteryDrawItem}>
                    <LotteryContainer
                        {...props}
                        getUserLastTimes={getUserLastTimes}
                        letteryCount={letteryCount}
                        getDrawHistory={getDrawHistory}
                        handleClick={handleClick}
                    />
                </div>

                {/* <form action="#" className={classes.LotteryForm}>
                    <input className={classes.LotteryFormInput} type="email" required placeholder={"Enter Your Email!"} />

                    <BigButton className={classes.LotteryFormButton}>Go!</BigButton>
                </form> */}
            </div>
        </div>
        
        <LotteryDrawHistory drawHistory={drawHistory} />

        <div className={classes.MoreRules}>
            <a onClick={() => setShowRules(true)} className={classes.RulesButton}> <FormattedMessage id="my_prizes_rules" defaultMessage="My Prizes & Rules" /></a>
        </div>

        <LotteryRulesPrizes 
            showRules={showRules}
            handleClick={handleClick}
            onClose={() => setShowRules(false)}
        />
    </div>
}

export default (props) => {
    useStyles(classes);
    const { data } = props;
    const [message, setMessage] = useState(null)
    const dispatch = useDispatch()
    const query = useQuery();

    useEffect(async () => {
        const message = await fetchGetLotteryPrizes().then(data =>data.result).catch(e => {
            console.error(e)
            return null
        })
        setMessage(message)
    }, []);

    return <div id={data?.id}>
        {
            message?.length > 0 ? <React.Fragment>
                <IndexLottery
                    {...props}
                    allPrizes={message}
                />
            </React.Fragment> : <React.Fragment>
                <div className={classes.EmptyAnniversary}></div>
            </React.Fragment>
        }
    </div>
}