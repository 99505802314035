import React from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import withStyles from 'isomorphic-style-loader/withStyles'

// function component
const AnimatedCard = ({ animation, digit }) => {
    useStyles(classes)
    return (
        <div className={`${classes.flipCard} ${animation}`}>
            <span>{digit}</span>
        </div>
    )
};

// function component
const StaticCard = ({ position, digit }) => {
    useStyles(classes)
    return (
        <div className={position}>
            <span>{digit}</span>
        </div>
    )
}

// function component
const FlipUnitContainer = ({ digit, shuffle, unit, leftShuffle }) => {
    useStyles(classes)
    // assign digit values
    let currentDigit = digit;
    let previousDigit = digit + 1;

    // to prevent a negative value
    if (unit !== 'hours') {
        previousDigit = previousDigit === 60
            ? 0
            : previousDigit;
    } else {
        previousDigit = previousDigit === 24
            ? 0
            : previousDigit;
    }

    // add zero
    if (currentDigit < 10) {
        currentDigit = `0${currentDigit}`;
    } else {
        currentDigit = `${currentDigit}`;
    }
    if (previousDigit < 10) {
        previousDigit = `0${previousDigit}`;
    } else {
        previousDigit = `${previousDigit}`;
    }





    const leftCurrentDigit = currentDigit?.split('')?.[0]
    const leftPreviousDigit = previousDigit?.split('')?.[0]
    const leftDigit1 = leftShuffle ? leftPreviousDigit : leftCurrentDigit
    const leftDigit2 = !leftShuffle ? leftPreviousDigit : leftCurrentDigit
    // shuffle animations
    const leftAnimation1 = leftShuffle ? classes.fold : classes.unfold;
    const leftAnimation2 = !leftShuffle? classes.fold : classes.unfold;

    const rightCurrentDigit = currentDigit?.split('')?.[1]
    const rightPreviousDigit = previousDigit?.split('')?.[1]
    const rightDigit1 = shuffle ? rightPreviousDigit : rightCurrentDigit
    const rightDigit2 = !shuffle ? rightPreviousDigit : rightCurrentDigit

    const rightAnimation1 = shuffle ? classes.fold : classes.unfold;
    const rightAnimation2 = !shuffle? classes.fold : classes.unfold;

    return (

        <React.Fragment>
            <div className={classes.flipUnitContainer}>
                <StaticCard
                    position={classes.upperCard}
                    digit={leftCurrentDigit}
                />
                <StaticCard
                    position={classes.lowerCard}
                    digit={leftPreviousDigit}
                />
                <AnimatedCard
                    digit={leftDigit1}
                    animation={leftAnimation1}
                />
                <AnimatedCard
                    digit={leftDigit2}
                    animation={leftAnimation2}
                />
            </div>


            <div className={classes.flipUnitContainer}>
                <StaticCard
                    position={classes.upperCard}
                    digit={rightCurrentDigit}
                />
                <StaticCard
                    position={classes.lowerCard}
                    digit={rightPreviousDigit}
                />
                <AnimatedCard
                    digit={rightDigit1}
                    animation={rightAnimation1}
                />
                <AnimatedCard
                    digit={rightDigit2}
                    animation={rightAnimation2}
                />
            </div>


        </React.Fragment>

    );
};

const __houroffset = 1000 * 60 * 60, __minuteoffset = 1000 * 60

export default withStyles(classes)(class extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            hours: 0,
            leftHoursShuffle: true,
            hoursShuffle: true,
            leftMinutesShuffle: true,
            minutes: 0,
            minutesShuffle: true,
            seconds: 0,
            leftSecondsShuffle: true,
            secondsShuffle: true,
            lefting: props?.offset
        }
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.updateTime(),
            50
        )
    }

    componentWillUnmount() {
        clearInterval(this.timerID)
    }

    updateTime() {
        const { lefting } = this.state
        // get new date



        // set time units
        // const hours = time.getHours();
        // const minutes = time.getMinutes();
        // const seconds = time.getSeconds();

        const hours = Math.floor(lefting / __houroffset)
        const _lefthours = lefting % __houroffset
        const minutes = Math.floor(_lefthours / __minuteoffset)
        const seconds = Math.floor((lefting % __minuteoffset) / 1000)


        // on hour chanage, update hours and shuffle state
        if (hours !== this.state.hours) {
            const hoursShuffle = !this.state.hoursShuffle;
            const leftHoursShuffle = Math.floor(hours / 10) !== Math.floor(this.state.hours / 10)? !this.state.leftHoursShuffle: leftHoursShuffle

            this.setState({
                hours,
                hoursShuffle,
                leftHoursShuffle
            });
        }
        // on minute chanage, update minutes and shuffle state
        if (minutes !== this.state.minutes) {
            const minutesShuffle = !this.state.minutesShuffle;
            const leftMinutesShuffle = Math.floor(minutes / 10) !== Math.floor(this.state.minutes / 10) ? !this.state.leftMinutesShuffle: leftMinutesShuffle
            this.setState({
                minutes,
                minutesShuffle,
                leftMinutesShuffle
            });
        }
        // on second chanage, update seconds and shuffle state
        if (seconds !== this.state.seconds) {
            const secondsShuffle = !this.state.secondsShuffle;
            const leftSecondsShuffle = Math.floor(seconds / 10) !== Math.floor(this.state.seconds / 10) ? !this.state.leftSecondsShuffle: leftSecondsShuffle
            this.setState({
                seconds,
                secondsShuffle,
                leftSecondsShuffle
            });
        }

        this.setState({ lefting: this.state.lefting - 50 })

        if (lefting <= 1000) {
            clearInterval(this.timerID)
        }
    }

    render() {

        // state object destructuring
        const {
            hours,
            minutes,
            seconds,
            hoursShuffle,
            minutesShuffle,
            secondsShuffle,
            leftHoursShuffle,
            leftMinutesShuffle,
            leftSecondsShuffle
        } = this.state;

        return (
            <div className={classes.flipClock}>
                <FlipUnitContainer
                    unit={'hours'}
                    digit={hours}
                    shuffle={hoursShuffle}
                    leftShuffle={leftHoursShuffle}
                />
                <span className={classes.Dotted}>:</span>
                <FlipUnitContainer
                    unit={'minutes'}
                    digit={minutes}
                    shuffle={minutesShuffle}
                    leftShuffle={leftMinutesShuffle}
                />
                 <span className={classes.Dotted}>:</span>
                <FlipUnitContainer
                    unit={'seconds'}
                    digit={seconds}
                    shuffle={secondsShuffle}
                    leftShuffle={leftSecondsShuffle}
                />
            </div>
        );
    }
})


