import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { fetchHomePagePopup, fetchPinterestAlert, subscribe } from '../../../../api'
import { BlackMask } from '../../../components/mask'
import Cache from '../../../../utils/cache'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { Iconfont } from '../../../components/icon/iconfont'
import { useIntl } from 'react-intl'
import { BigButton } from '../../../components/button'
import { FormattedMessage } from 'react-intl';
import deeplink from '../../../../utils/deeplink'
import Alert from '../../../components/alert'
import { useQuery } from '../../../hooks/common'
import { useSelector } from 'react-redux'
import CountDown from '../../../components/count-down/count-down'
import withSource from '../../../hocs/source-scoll' 
import { getSensorsUrl } from '../../../utils/sensor'

const AlertSubscribe = props => {
    useStyles(classes);
    const { message, handleClose } = props;
    const { backgroundImage, successImage, configs } = message;

    const intl = useIntl()
    const [email, setEmail] = useState()
    const [loading, setLoading] = useState(false);
    const [successShow, setSuccessShow] = useState(false);

    const handleSub = async evt => {
        evt.preventDefault()
        if (!email) return
        setLoading(true)

        const response = await subscribe(email)

        if (response?.code === 200) {
            setLoading(false)
            setSuccessShow(true);
            Cache.set("customerEmail", email, (365 * 24 * 60 * 60) * 1000);

            try {

                if (window.GeekoSensors) {
                    window.GeekoSensors.Track('Register', {
                        register_scenes: '弹窗',
                        register_method: 'email',
                        is_success: true
                    })

                    window.GeekoSensors.Track('PopUp', {
                        type: '首页订阅',
                        page_content:"popup",
                        is_subsribe: true,
                    })
                }

                if (window.fbq) {
                    window.fbq('track', "CompleteRegistration")
                }

            } catch (e) {
                console.warn(e)
            }
        } else {
            if(response?.code === 400){
                Alert(intl.formatMessage({id: 'has_subscribe_to', defaultMessage:'The Email has been used by others. Please specify another Email.'}));
                setLoading(false)
            }else{
                alert(response?.result || response)
                setLoading(false)
            }
        }

    }

    return <>
        {
            !successShow ? <div className={classes.AlertSubscribe} style={{
                backgroundImage: `url(${backgroundImage?.url})`,
            }}>
                <Iconfont style={{color:configs?.closeIconColor || '#fff'}} onClick={handleClose} className={classes.Close}>&#xe6af;</Iconfont>
                <form action="#" className={classes.SubscribeArea} onSubmit={handleSub}>
                    <div className={classes.SubInput}>
                        <input type='email' value={email} placeholder={intl.formatMessage({id: 'enter_email_address', defaultMessage:'Enter you email address'})} onChange={evt => { setEmail(evt.target.value) }} required />
                    </div>
                    <BigButton style={{backgroundColor:configs?.btnBackColor || '#000',color:configs?.btnFontColor || '#fff'}} className={classes.SubBtn} loading={loading}><FormattedMessage id="get_offer" defaultMessage="Get offer" /></BigButton>
                </form>

                {/* <a href='/i/login' onClick={loginEvent} className={classes.SubScribeDescript}></a> */}
            </div> : <a href={deeplink(message?.deepLink, "msite")} className={`${classes.AlertSubscribe} ${classes.SuccessSubscribe}`} style={{
                backgroundImage: `url(${successImage?.url})`,
            }}>
                <Iconfont onClick={(e) =>{
                    e.preventDefault();
                    handleClose();
                }} className={classes.Close} style={{color:configs?.closeIconColor || '#fff'}}>&#xe6af;</Iconfont>
            </a>
        }

    </>
}

const AlertDiscount = withSource(props =>{
    const { handleClose, message, innerRef } = props;
    const { backgroundImage, endDate, serverTime, style, refId } = message;

    const normalDiscountEvent = (event) =>{
        event.preventDefault();
        const { currentTarget } = event;

        if(window.GeekoSensors){
            window.GeekoSensors.Track('PopUp', {
                type: '促销',
                page_content:"popup",
                is_click:true
            })
        }

        window.location.href = getSensorsUrl(currentTarget?.href, {
			resourcepage_title: currentTarget.getAttribute("data-title"),
			resource_type: currentTarget.getAttribute("data-type"),
            resource_position: currentTarget.getAttribute("data-position"),
			resource_content: currentTarget.getAttribute("data-content"),
		})
    }

    let offset = 0;
    if(endDate && serverTime){
        offset = message?.endDate - message?.serverTime;
    }

    return <div className={classes.IndexAlert}>
        <a 
            onClick={normalDiscountEvent} 
            href={deeplink(message?.deepLink, "msite")}
            ref={innerRef}
            data-title={'popup-activity'}
            data-position={'1'}
            data-type={'22'}
            data-content={refId}
        ><img src={backgroundImage?.url} /></a>
        <Iconfont className={classes.Close} onClick={handleClose}>&#xe7c9;</Iconfont>

        {
            offset > 1000 && <div className={`${classes.CountDownContainer}`}>
                <div className={classes.EndsIn} style={{color:`${style?.timeDownBackgroudColor || '#222'}`}}>
                    <FormattedMessage id="ends_in" defaultMessage="Ends in" />
                </div>

                {
                    offset > 1000 && <div className={classes.CountDown}>
                        <CountDown 
                            offset={offset} 
                            showHour 
                            dotStyle={{color:style?.timeDownBackgroudColor || '#222'}}
                            numbereStyle={{color:style?.timeDownFontColor || '#fff', backgroundColor: style?.timeDownBackgroudColor || '#222'}}
                        />
                    </div>
                }
            </div>
        }
        
    </div>
})

export const PinterestDiscount = props => {
    const { handleClose, message } = props;
    const { backgroundImage, endDate, serverTime, style } = message;

    let offset = 0;
    if(endDate && serverTime){
        offset = message?.endDate - message?.serverTime;
    }

    return <div className={classes.IndexAlert}>
        <div onClick={handleClose}>
            <img src={backgroundImage?.url} />
        </div>
        <Iconfont className={classes.Close} onClick={handleClose}>&#xe7c9;</Iconfont>

        {
            offset > 1000 && <div className={`${classes.CountDownContainer} ${classes.Pinterest}`}>
                <div className={classes.EndsIn} style={{color:`${style?.timeDownBackgroudColor || '#222'}`}}>
                    <FormattedMessage id="ends_in" defaultMessage="Ends in" />
                </div>

                {
                    offset > 1000 && <div className={classes.CountDown}>
                        <CountDown
                            offset={offset}
                            showHour
                            dotStyle={{color:style?.timeDownBackgroudColor || '#222'}}
                            numbereStyle={{color:style?.timeDownFontColor || '#fff', backgroundColor: style?.timeDownBackgroudColor || '#222'}}
                        />
                    </div>
                }
            </div>
        }
    </div>
}

const IndexAlert = props => {
    useStyles(classes)
    const { message, handleClose } = props;
    
    const subribeEvent = () =>{
        handleClose();

        if(window.GeekoSensors){
            window.GeekoSensors.Track('PopUp', {
                type: '首页订阅',
                page_content:"popup",
                popupclose: true,
            })
        }
    }

    return <>
        {/* type;//1： 订阅 ， 2：普通弹出 */}
        {
            message?.type === "1" && <AlertSubscribe {...props} handleClose={subribeEvent} />
        }

        {
            message?.type === "2" && <AlertDiscount {...props} handleClose={subribeEvent} />
        }
    </>
}

export const PinterestAlert = props => {
    const [message, setMessage] = useState()
    const [showAlert, setShowAlert] = useState(false)
    const ui = useSelector(state => state.ui)
    useStyles(classes)

    const handleClose = () => {
        setShowAlert(false)
        Cache.set('pinterest-alert', true)
        if(window.GeekoSensors){
            window.GeekoSensors.Track('CloseCoupon', {
                clicks: 'middle-coupon-1'
            })
        }
    }

    useEffect( async () => {
        //  请求图片
        const storedIndexAlert = Cache.get('pinterest-alert')
        if(!storedIndexAlert){
            const res = await fetchPinterestAlert()
            if(res?.code == 200){
                setMessage(res?.result? {...res?.result, serverTime:res?.serverTime}:null)
                setShowAlert(true)
                if(window.GeekoSensors){
                    window.GeekoSensors.Track('ELExpose', {
                        clicks: 'middle-coupon'
                    })
                }
            }
        }
    }, [])

    return <React.Fragment>
        {
            message && showAlert && !ui.showLottery && ReactDOM.createPortal(<React.Fragment>
                <PinterestDiscount {...props} handleClose={handleClose} message={message}/>
                <BlackMask onClick={handleClose} />
            </React.Fragment>, typeof document !== 'undefined' ? document.getElementById('root') : null)
        }
    </React.Fragment>
}


export default props => {

    const { ignoreTouch } = props
    const [message, setMessage] = useState()
    const [showAlert, setShowAlert] = useState(false)
    const touchedRef = useRef(false)
    const query = useQuery();
    const ui = useSelector(state => state.ui)

    const handleClose = evt => {
        setShowAlert(false)

        if(message?.type === "1"){
            if(window.GeekoSensors){
                window.GeekoSensors.Track('PopUp', {
                    type: '首页订阅',
                    page_content:"popup",
                    popupclose: true,
                })
            }
        }else if(message?.type === "2"){
            if(window.GeekoSensors){
                window.GeekoSensors.Track('PopUp', {
                    type: '促销',
                    page_content:"popup",
                    popupclose: true,
                })
            }
        }
    }


    useEffect(() => {

        if (!ignoreTouch) {
            window.addEventListener('touchstart', loadHandle)
        } else {
            loadHandle()
        }

        return () => {
            window.removeEventListener('touchstart', loadHandle)
        }
    }, [])


    const loadHandle = async evt => {
        if(!touchedRef.current && !query?.show_lottery){
            touchedRef.current = true
            const storedIndexAlert = Cache.get('index-alert')
            if (!storedIndexAlert) {
                Cache.set('index-alert', 1)
                const message = await fetchHomePagePopup().then(data => (data.result?{...data.result, serverTime:data?.serverTime}:null)).catch(e => {
                    console.error(e)
                    return null
                })
                setMessage(message)
                setShowAlert(true)
            }
        }

    }


    return <React.Fragment>
        {
            message && showAlert && !ui.showLottery && ReactDOM.createPortal(<React.Fragment>
                <IndexAlert {...props} handleClose={handleClose} message={message} />
                <BlackMask onClick={handleClose} />
            </React.Fragment>, typeof document !== 'undefined' ? document.getElementById('root') : null)
        }
    </React.Fragment>
}