export const isBeforeVersion = (current, version) => {

    if(!current || !version) return false

    const splitCurrentVersions = current?.split?.('.')
    const splitVersions = version?.split?.('.')

    if(Number(splitCurrentVersions?.[0]) < Number(splitVersions?.[0])){
        return true
    }else if(Number(splitCurrentVersions?.[0]) > Number(splitVersions?.[0])){
        return false
    }

    if(Number(splitCurrentVersions?.[1]) < Number(splitVersions?.[1])){
        return true
    }else if(Number(splitCurrentVersions?.[1]) > Number(splitVersions?.[1])){
        return false
    }

    return Number(splitCurrentVersions?.[2]) <= Number(splitVersions?.[2])
}