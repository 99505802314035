import React, { useEffect, useState } from 'react'
import ImageContainer from '../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import withGroup from './hoc/withGroup'
import { TabSwiper5 } from './components/swiper-tabs'
import withObserver from '../hoc/withObserver'
import { getStyleObjectFromString } from '../../../../utils/style'
import { useApp, useQuery } from '../../../../hooks/common'
import { getSensorsUrl } from '../../../../utils/sensor'
import SwiperProducts from './components/swiper-products'


export default withGroup(withObserver(props => {
    useStyles(classes)
    const { collections, data, sensors, innerRef } = props
    const [collection, setCollection] = useState()

    const isApp = useApp()
    const query = useQuery()

    const id = data?.id

    const selectedIndex = query?.locationModule === id ? Number(query?.selectedIndex) : 0

    const [position, setPosition] = useState(selectedIndex + 1)

    const styles = getStyleObjectFromString(data?.style)

    const dark = !!styles?.backgroundImage || !!styles?.backgroundColor

    useEffect(() => {
        setCollection(collections?.[selectedIndex || 0] || collections?.[0])
    }, [collections])

    const aspectRatio = data?.width && data?.height ? `${data?.width} / ${data?.height}` : undefined

    return <div ref={innerRef} id={data?.id} className={classes.ListContainer} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>

        {
            data?.styledTitle && <div className={classes.Title}>
                <span dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div>
        }

        {
            data?.src && <ImageContainer style={{ aspectRatio }} href={isApp ? data?.appHref : data?.href} sensors={{
                ...sensors,
                refId: data?.refId,
                type: '1',
            }}>
                <LazyLoadImage alt={data?.title} src={data?.src} />
            </ImageContainer>
        }

        {
            collections?.length > 1 && <div style={{ marginTop: 12 }}>
                <TabSwiper5
                    selectedIndex={selectedIndex}
                    theme={data?.theme}
                    sensors={
                        {
                            ...sensors,
                            type: '1',
                        }
                    }
                    items={collections} onTab={
                        (item, index) => {
                            setCollection(item)
                            setPosition(index + 1)
                        }
                    } />
            </div>
        }

        <div className={classes.SwiperProducts}>
            <SwiperProducts 
                outline={dark} 
                sensors={{
                    resourcepage_title: sensors?.title,
                    resource_position: `${sensors?.position}-${position}`,
                    resource_content: collection?.refId || collection?.id,
                    resource_type: '1',
                }} 
                column={collection?.refId || collection?.id}  
                products={collection?.products} 
                moreUrl={getSensorsUrl(collection?.href, {
                    resourcepage_title: sensors?.title,
                    resource_position: `${sensors?.position}-${position}`,
                    resource_content: collection?.refId || collection?.id,
                    resource_type: '1',
                })}
            />
        </div>
    </div>
}))