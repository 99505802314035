import React, { useEffect, useRef, useState, useCallback } from 'react'
import withSource from '../../../../../hocs/source-scoll'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Types } from './index'
import {getStyleObjectFromString} from '../../../../../utils/style'
import { useApp } from '../../../../../hooks/common'
import { getSensorsUrl } from '../../../../../utils/sensor'

const Image = withSource(props => {
    useStyles(classes)
    const isApp = useApp()
    const { image, sensors, innerRef, position, active } = props
    const { percent, width, height, title } = image || {}

    const href = isApp ? image?.appHref : image?.href


    const aspectRatio = width && height ? `${width} / ${height}` : undefined

    const clickHandle = useCallback(e => {
		e.preventDefault()
		const href = e.currentTarget?.href
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: sensors?.title,
			resource_type: '1',
			resource_content: image?.refId,
			resource_position: `${sensors?.position || 1}-${(position || 0) + 1}`,
		})
	}, [])

    return <a
        href={href}
        title={title}
        ref={innerRef}
        className={`${classes.Image} ${active ? classes.Active : ''}`}
        data-position={`${sensors?.position || 1}-${(position || 0) + 1}`}
        data-type={sensors?.type}
        data-content={image?.refId}
        data-title={sensors?.title}
        onClick={clickHandle}
        style={{
            width: isNaN(percent) ? percent : `${Number(percent) * 100}%`,
            aspectRatio,
        }}
    >
        <LazyLoadImage alt={image?.title} className={classes.Main} src={image.src} />
        <LazyLoadImage alt={image?.title} className={classes.Secondary} src={image.secondarySrc} />
    </a>
})


export default props => {
    useStyles(classes)
    const { data, sensors } = props
    const { images, style } = data
    const [activeIndex, setActiveIndex] = useState(0)
    const countRef = useRef(null)

    const styles = getStyleObjectFromString(style)

    countRef.current = () => {
        const nextIndex = activeIndex + 1 >= images?.length ? 0 : activeIndex + 1
        setActiveIndex(nextIndex)
    }

    useEffect(() => {
        const id = setInterval(() => {
            countRef.current()
        }, 1000)
        return () => clearInterval(id)
    }, [])

    return <div className={classes.Images} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        {
            images?.map((image, index) => {
                return <Image active={activeIndex === index} image={image} position={index} sensors={{
                    ...sensors,
                    type:Types.TYPES_1
                }} />
            })
        }
    </div>
}