import React, {useState} from 'react'
import withRec from './hoc/withDymaticGroup'
import { TabSwiper2 } from './components/swiper-tabs'
import { Products, EmptyProducts } from '../../../listing/components/list'
import { FormattedMessage } from 'react-intl'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { Types } from './index'
import withObserver from '../hoc/withObserver'
import { getStyleObjectFromString } from '../../../../utils/style'
import { useLocation } from 'react-router-dom'

export default withRec(withObserver(props => {

    useStyles(classes)


    const { loading, changeHandle, loadHandle, selectedMenu, menus, data, originMenus, sensors, abTest, innerRef } = props
    const location = useLocation()
    const styles = getStyleObjectFromString(data?.style)
    const [position, setPosition] = useState(1)
    const isFestival = location.pathname === '/i/black-friday'

    return <div ref={innerRef} id={data?.id} className={classes.RecContainer} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>

        {
            (data?.styledTitle || data?.title) && <div className={classes.Title}>
                <span href={data?.href} dangerouslySetInnerHTML={{ __html: (data?.styledTitle || data?.title) }} />
            </div>
        }


        {
            originMenus && originMenus.length > 1 && <TabSwiper2 sensors={{
                ...sensors,
                type: '1',
            }} items={originMenus} onTab={
                (item, index) => {
                    changeHandle(item.id)
                    setPosition(index+1)
                }
            } />
        }

        

        <div>
            {
                (!selectedMenu?.data?.products || selectedMenu?.data?.products?.length < 1) && <EmptyProducts />
            }

            {
                selectedMenu?.data?.products?.length > 0 && <Products festival={isFestival} sensors={{
                    resourcepage_title: sensors?.title,
                    resource_position: `${sensors?.position}-${position}`,
                    resource_content:selectedMenu?.refId || selectedMenu?.id,
                    resource_type: '1',
                }} similar={abTest === 'B'} column={selectedMenu?.id} products={selectedMenu?.data?.products} />
            }

            {
                selectedMenu?.data?.finished && <div className={classes.Finished}>
                    <FormattedMessage id="finished" defaultMessage="Finished" />
                </div>
            }

            {
                loading && !selectedMenu?.data?.finished && <div className={classes.Loading}>
                    <FormattedMessage id="loading" defaultMessage="Loading" />
                </div>
            }

            {
                !loading && !selectedMenu?.data?.finished && <div className={classes.ViewMore} onClick={() => {
                    loadHandle(selectedMenu?.id)
                }}>
                    <span><FormattedMessage id="view_more" defaultMessage="View More" />{' >'}</span>
                </div>
            }

        </div>

    </div>
}))