import React from 'react'
import { Iconfont } from '../../../../components/icon/iconfont'
import { FullSwiper } from '../../components/swiper'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'


const Tip = props => {
    useStyles(classes)
    const { tip, sensors, innerRef, position } = props
    return tip?.href ? <a
            className={classes.Tip}
            ref={innerRef}
            data-position={`${sensors?.position || 1}-${(position || 0) + 1}`}
            data-type={sensors?.type}
            data-content={tip.title}
            data-title={sensors?.title}
            href={tip?.href}
        >
            <Iconfont className={classes.Icon} dangerouslySetInnerHTML={{ __html: tip.icon }} />
            <span dangerouslySetInnerHTML={{ __html: tip.styledTitle }} />
        </a>:<div
        className={classes.Tip}
        ref={innerRef}
        data-position={`${sensors?.position || 1}-${(position || 0) + 1}`}
        data-type={sensors?.type}
        data-content={tip.title}
        data-title={sensors?.title}
    >
        <Iconfont className={classes.Icon} dangerouslySetInnerHTML={{ __html: tip.icon }} />
        <span dangerouslySetInnerHTML={{ __html: tip.styledTitle }} />
    </div>
}

export default props => {
    useStyles(classes)
    const { data, sensors } = props
    const { tips } = data

    return <FullSwiper className={classes.Tips} options={
        {
            direction: 'vertical',
            loop: true,
            autoplay:{
                delay: 5000
            }
        }
    } items={tips} render={(tip, index) => {
        return <Tip key={index} position={index} tip={tip} sensors={sensors}/>
    }} />
}