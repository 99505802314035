import Type5 from './type-5'
import Type6 from './type-6'
import Type7 from './type-7'
import TypeTop from './type-top-1'
import TypeGroup3 from './type-group-3'
import TypeGroup1 from './type-group-1'
import TypeGroupFestival from './type-group-festival'
import TypeRec1 from './type-rec-1'
import TypeFlash from './type-flash'
import TypeGroupRec from './type-group-rec'
import TypeVideo1 from './type-video-1'
import TypeNew1 from './type-new-1'
import TypeNew2 from './type-new-2'
import TypeLanding1 from './type-landing-1'
import TypeGroup10 from './type-group-10'
import TypeGroup2 from './type-group-2'
import TypeGroupFestival2 from './type-group-festival-2'
import TypePreOrder from './type-preorder'


export const Types = {
    TYPES_7: 'list-7',
    TYPES_6: 'list-6',
    TYPES_5: 'list-5',
    TYPES_4: 'list-4',
    TYPES_2: 'list-2',
    TYPES_FLASH: 'list-flash',
    TYPES_LANDING_3: 'list-landing-3',
    TYPES_GROUP_FESTIVAL: 'list-friday-festival',
    TYPES_GROUP_FESTIVAL_2: 'list-friday-festival-2',
    TYPES_GROUP_2: 'list-friday-2',
    TYPES_GROUP_3: 'list-friday-3',
    TYPES_GROUP_8: 'list-friday-8',
    TYPES_GROUP_1: 'list-firday',
    TYPES_REC_1: 'recommendation',
    TYPES_GROUP_REC: 'list-group-1',
    TYPES_TOP_1: 'list-top-1',
    TYPES_VIDEO_1: 'list-video-1',
    TYPES_NEW_1: 'new-rec',
    TYPES_NEW_2: 'new-1',
    TYPES_LANDING_1: 'list-landing-1',
    TYPES_GROUP_10: 'list-friday-10',
    TYPES_PRE_ORDER: 'list-preorder',
}

export default type => {
    switch (type) {
        case Types.TYPES_LANDING_3:
        case Types.TYPES_2:
        case Types.TYPES_4:
        case Types.TYPES_5:
            return Type5
        case Types.TYPES_PRE_ORDER:
            return TypePreOrder
        case Types.TYPES_6:
            return Type6   
        case Types.TYPES_7:
            return Type7
        case Types.TYPES_TOP_1:     
            return  TypeTop
        case Types.TYPES_FLASH:
            return TypeFlash
        case Types.TYPES_GROUP_2:
            return TypeGroup2
        case Types.TYPES_GROUP_8:    
        case Types.TYPES_GROUP_3:
            return TypeGroup3
        case Types.TYPES_GROUP_1:
            return TypeGroup1
        case Types.TYPES_GROUP_FESTIVAL:
            return TypeGroupFestival   
        case Types.TYPES_GROUP_FESTIVAL_2:
            return TypeGroupFestival2     
        case Types.TYPES_GROUP_REC:
            return TypeGroupRec
        case Types.TYPES_REC_1:
            return TypeRec1
        case Types.TYPES_VIDEO_1:
            return TypeVideo1    
        case Types.TYPES_NEW_1:
            return TypeNew1   
        case Types.TYPES_NEW_2:
            return TypeNew2    
        case Types.TYPES_LANDING_1:
            return TypeLanding1
        case Types.TYPES_GROUP_10:
            return TypeGroup10
        default:
            return
    }
}