import React, { useMemo, useState } from 'react'
import withRec from './hoc/withMenuRecomdation'
import { TabSwiper2 } from './components/swiper-tabs'
import { Products, EmptyProducts } from '../../../listing/components/list'
import { FormattedMessage } from 'react-intl'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { Types } from './index'
import withObserver from '../hoc/withObserver'
import { getStyleObjectFromString } from '../../../../utils/style'
import Affix from '../../../../components/affix'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default withRec(withObserver(props => {

    useStyles(classes)

    const { loading, changeHandle, loadHandle, selectedMenu, menus, data, originMenus, sensors, abTest, innerRef } = props

    const styles = getStyleObjectFromString(data?.style)

    const [position, setPosition] = useState(1)

    const pageHome = useSelector((state) => state.pageHome) // 44
    const pageGlobal = useSelector((state) => state.global) // 28
    const location = useLocation();

    const isHomePage = location.pathname === '/' || location.pathname === '/index' || location.pathname === '/i/index'

    const affixNumber = useMemo(() =>{
        let number = 44;
        if(isHomePage && (pageGlobal?.websites?.length > 1 || pageHome?.pages?.length > 1)){
            number += 28;
        }

        return number;
    }, [isHomePage, pageGlobal?.websites, pageHome?.pages]);

    return <div ref={innerRef} id={data?.id} className={classes.RecContainer} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>

        {
            data?.styledTitle && <div className={classes.Title}>
                <span href={data?.href} dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div>
        }

        {
            originMenus?.length > 1 && <Affix offsetTop={affixNumber}>
                <TabSwiper2 sensors={{
                    ...sensors,
                    type: '9',
                }} items={originMenus} onTab={
                    (item, index) => {
                        changeHandle(item.id)
                        setPosition(index)
                    }
                } isSpecial={originMenus.length === 2} />
            </Affix>
            
        }


        <div>
            {
                (!selectedMenu?.data?.products || selectedMenu?.data?.products?.length < 1) && <EmptyProducts />
            }

            {
                selectedMenu?.data?.products?.length > 0 && <Products sensors={{
                    resourcepage_title: sensors?.title,
                    resource_position: `${sensors?.position}-${position}`,
                    resource_content: selectedMenu?.enName || selectedMenu?.refId || selectedMenu?.id,
                    resource_type: '9',
                }} similar={abTest === 'B'} column={selectedMenu?.id} products={selectedMenu?.data?.products} />
            }

            {
                selectedMenu?.data?.finished && <div className={classes.Finished}>
                    <FormattedMessage id="finished" defaultMessage="Finished" />
                </div>
            }

            {
                loading && !selectedMenu?.data?.finished && <div className={classes.Loading}>
                    <FormattedMessage id="loading" defaultMessage="Loading" />
                </div>
            }

            {
                !loading && !selectedMenu?.data?.finished && <div className={classes.ViewMore} onClick={() => {
                    loadHandle(selectedMenu?.id)
                }}>
                    <span><FormattedMessage id="view_more" defaultMessage="View More" />{' >'}</span>
                </div>
            }

        </div>

    </div>
}))