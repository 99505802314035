import React, { useEffect, useState, createRef } from 'react'
import { fetchTimeRanges, getProductsByTimeRange } from '../../../../../../api'

export default Wrapped => {
    const LIMIT = 6
    return props => {
        const [menus, setMenus] = useState([])
        const [originMenus, setOriginMenus] = useState([])
        const [selectedMenu, setSelectedMenu] = useState()
        const [loading, setLoading] = useState(false)
        const ref = createRef()
        let inited = false

        useEffect(() => {
            let ob = new IntersectionObserver(obHandle)
            ob.observe(ref.current)
            return () => {
                ob.disconnect()
            }
        }, [])

        const obHandle = entries => {
            const entry = entries?.[0]
            if (entry?.intersectionRatio > 0) {
                initData()
            }
        }


        const initData = async () => {

            if (!inited) {
                inited = true
                const tempMenus = await fetchTimeRanges().then(data => data.result).catch(data => {
                    console.error(data)
                    return []
                })

                const menu = tempMenus?.[0]

                if (menu) {
                    setLoading(true)
                    const products = await getProductsByTimeRange({ skip: 0, limit: LIMIT, index: menu.id }).then(data => data.result?.map(p => ({
                        ...p,
                        geekoRequsestId: data.requestId,
                        geekoExperimentId: data.experimentId,
                        layerName: data.layerName,
                        trackingRecords: data.trackingRecords
                    }))?.filter(p => !p.isCollection && !p.isSmartCard)).catch(data => {
                        console.error(data)
                        return []
                    })
                    const menus = tempMenus.map(m => {
                        if (m.id === menu.id)
                            return {
                                ...menu, data: {
                                    skip: 0,
                                    products,
                                    finished: !!!products || products?.length < 1
                                }
                            }
                        return m
                    })

                    setMenus(menus)
                    setOriginMenus(menus)
                    setSelectedMenu(menus?.[0])
                    setLoading(false)
                }
            }
        }


        const changeMenu = async id => {
            const menu = menus?.find(m => m.id === id)
            if (menu) {
                if (!menu?.data?.products) {
                    setLoading(true)
                    setSelectedMenu(menu)
                    const products = await getProductsByTimeRange({ skip: 0, limit: LIMIT, index: menu.id }).then(data => data.result?.map(p => ({
                        ...p,
                        geekoRequsestId: data.requestId,
                        geekoExperimentId: data.experimentId,
                        layerName: data.layerName,
                        trackingRecords: data.trackingRecords
                    }))?.filter(p => !p.isCollection && !p.isSmartCard)).catch(data => {
                        console.error(data)
                        return []
                    })
                    setMenus(menus.map(m => {
                        if (m.id === menu.id)
                            return {
                                ...m, data: {
                                    skip: 0,
                                    products,
                                    finished: !!!products || products?.length < 1
                                }
                            }
                        return m
                    }))
                    setSelectedMenu({
                        ...menu, data: {
                            skip: 0,
                            products,
                            finished: !!!products || products?.length < 1
                        }
                    })
                    setLoading(false)
                } else {
                    setSelectedMenu(menu)
                }
            }
        }

        const loadHandle = async id => {
            const tempMenu = menus.find(m => m.id === id)
            setLoading(true)
            const products = await getProductsByTimeRange({ skip: (tempMenu?.data?.skip || 0) + LIMIT, limit: LIMIT, index: id }).then(data => data.result?.map(p => ({
                ...p,
                geekoRequsestId: data.requestId,
                geekoExperimentId: data.experimentId,
                layerName: data.layerName,
                trackingRecords: data.trackingRecords
            }))?.filter(p => !p.isCollection && !p.isSmartCard)).catch(data => {
                console.error(data)
                return []
            })
            const newMenus = menus.map(m => {
                if (m.id === id)
                    return {
                        ...m, data: {
                            products: [...m?.data?.products, ...products],
                            skip: (tempMenu?.data?.skip || 0) + LIMIT,
                            finished: !!!products || products?.length < 1
                        }
                    }
                return m
            })
            setMenus(newMenus)
            setSelectedMenu(newMenus.find(m => m.id === id))
            setLoading(false)
        }

        return <div ref={ref}>
            <Wrapped {...props} loading={loading} changeHandle={changeMenu} loadHandle={loadHandle} selectedMenu={selectedMenu} menus={menus} originMenus={originMenus} />
        </div>

    }
}