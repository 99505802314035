import React, { createRef, useCallback, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import withSource from '../../../../hocs/source-scoll'
import { Types } from './index'


const VideoImage = withSource(props => {
    useStyles(classes)
    const { image, sensors, innerRef, position } = props
    const ref = createRef()

    const clickHandle = e => {
        if (ref.current.requestFullScreen) {
            ref.current.requestFullScreen()
        } else if (ref.current.webkitRequestFullScreen) {
            ref.current.webkitRequestFullScreen()
        } else if (ref.current.mozRequestFullScreen) {
            ref.current.mozRequestFullScreen()
        }
        ref.current.play()
    }

    const handleFullScreenChange = useCallback(e => {
        const isFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen
        if (!isFullScreen) {
            e.target.pause()
        }
    }, [])

    const endHandle = useCallback(e => {
        const elem = e.target || document
        if (elem.cancelFullScreen) {
            elem.cancelFullScreen();
        } else if (elem.mozCancelFullScreen) {
            elem.mozCancelFullScreen();
        } else if (elem.webkitCancelFullScreen) {
            elem.webkitCancelFullScreen();
        } else if (elem.webkitExitFullScreen) {
            elem.webkitExitFullScreen()
        }
    }, [])


    useEffect(() => {
        ref.current?.addEventListener('webkitfullscreenchange', handleFullScreenChange)
        ref.current?.addEventListener('ended', endHandle)
        return () => {
            ref.current?.removeEventListener('webkitfullscreenchange', handleFullScreenChange)
            ref.current?.removeEventListener('ended', endHandle)
        }
    }, [])


    return <div 
        className={classes.VideoImage} 
        onClick={clickHandle} 
        ref={innerRef}
		data-position={`${position + 1}`}
		data-type={sensors?.type}
		data-content={image.refId}
		data-title={sensors?.title}
        >
        <video ref={ref} src={image.url} poster={image.post} />
    </div>
})

export default props => {
    useStyles(classes)
    const {data, sensors} = props
    const images = data?.images

    const clickHandle = (swiper, e) => {
        swiper.autoplay.stop()
    }

    return <div className={classes.VideoContainer}>

        <Swiper
            slidesPerView="auto"
            autoplay={{
                delay: 2500,
            }}
            loop={true}
            centeredSlides={true}
            pagination={{
                type: "fraction"
            }}
            onClick={clickHandle}

            className={classes.VideoSwiper}
        >
            {
                images?.map((item, index) => <SwiperSlide position={index} sensors={{
                    ...sensors,
                    type: `${Types.TYPES_1}@${data.refId}`,
                }} className={classes.VideoSwiperSlide} key={index}>
                    <VideoImage image={item} />
                </SwiperSlide>)
            }
        </Swiper>
        <span className={classes.Play} />
    </div>
}