import React, { useCallback } from 'react'
import withSource from '../../../../hocs/source-scoll'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getStyleObjectFromString } from '../../../../utils/style'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useApp } from '../../../../hooks/common'
import { getSensorsUrl } from '../../../../utils/sensor'
import { useSelector } from 'react-redux'

const Image = withSource(props => {
    useStyles(classes)
    const isApp = useApp()
    const { image, sensors, innerRef, position } = props
    const { width, height, title } = image || {}
    const href = isApp ? image?.appHref : image?.href

    // let paddingTop
    // if(width && height){
    // 	paddingTop = `${Math.floor(height*100/width)}%`
    // }


    const clickHandle = useCallback(e => {
        e.preventDefault()
        const href = e.currentTarget?.href
        window.location.href = getSensorsUrl(href, {
            resourcepage_title: sensors?.title,
            resource_type: sensors?.type,
            resource_content: image?.refId,
            resource_position: `${sensors?.position || 1}-${(position || 0) + 1}`,
        })
    }, [])


    return <a
        href={href}
        title={title}
        ref={innerRef}
        className={classes.Image}
        data-position={`${sensors?.position || 1}-${(position || 0) + 1}`}
        data-type={sensors?.type}
        data-content={image?.refId}
        data-title={sensors?.title}
        onClick={clickHandle}
    >
        <LazyLoadImage alt={image?.title || image?.refId} src={image.src} />
    </a>
})


export default props => {
    useStyles(classes)
    const isApp = useApp()
    const { data, sensors } = props
    const { images, style, href, appHref, background, slidesPerView, spaceBetween } = data

    const styles = getStyleObjectFromString(style)

    const url = isApp ? appHref : href

    return <div className={classes.Container6} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        {
            data?.styledTitle && <div className={classes.Title}>
                <a href={url}>
                    {
                        data?.styledTitle
                    }
                </a>
            </div>
        }



        <div className={classes.Images} style={{ background }}>
            <Swiper slidesPerView="auto">
                {
                    images.map((image, index) => <SwiperSlide className={classes.AutoWidth} key={index}>
                        <Image image={image} position={index} sensors={{
                            ...sensors,
                            type: '1',
                        }} />
                    </SwiperSlide>)
                }
            </Swiper>
        </div>
    </div>
}