import React from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import GroupImages from '../../../../components/card/images/gourp-images'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { FullSwiper } from '../../components/swiper'
import {Types} from './index'
import withObserver from '../hoc/withObserver'
import {getStyleObjectFromString} from '../../../../utils/style'

const PageImage = props => {
    const {images, sensors} = props
    return <GroupImages images={images} sensors={sensors} render={(item, index) => {
        return <LazyLoadImage alt={item?.title} src={item.src}/>
    }}/>
}



export default withObserver(props => {
    useStyles(classes)
    const {data, sensors, innerRef} = props
    const {groups, styledTitle} = data

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return `
                <span class="${className}">${index}</span>
            `
        },
        clickableClass: classes.SwiperPagination,
        bulletActiveClass: classes.Active, 
        bulletClass:classes.Pagegation
    }

    const styles = getStyleObjectFromString(data?.style)


    return <div ref={innerRef} id={data?.id} className={classes.Container} style={{paddingTop: `${data?.marginTop}`, ...styles}}>
        {
            styledTitle && <div className={classes.Title}>
                <span dangerouslySetInnerHTML={{__html: styledTitle}}/>
            </div>
        }

        <div>
            <FullSwiper className={classes.Swiper} items={groups} options={{
                pagination: groups?.length > 1 && pagination,
                loop: groups?.length > 1,
                autoplay: {
                  delay: 3000,
                },
            }} render={(item, index) => {
                return <PageImage sensors={{
                    ...sensors,
                    type: '1',
                    position: `${sensors.position}-${index+1}`
                }} images={item}/>
            }}/>
        </div>
    </div>
})