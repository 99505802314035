// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3ikXZWY-pH7ckWvYEV8aIH>div{background-color:#f7f7f7;margin-top:.32rem}._3ikXZWY-pH7ckWvYEV8aIH>div:first-child{margin-top:0}._3ikXZWY-pH7ckWvYEV8aIH ._1K_4RkPww-j6iXqbNmLbXR{height:1.06667rem}._3ikXZWY-pH7ckWvYEV8aIH .TTpNxsjqvPrm-u3IUdROB{height:9.33333rem}._3DU2LoM7nR5u7DgpV3QMYk{padding-left:.32rem;padding-right:.32rem;padding-bottom:.32rem}._1K6wvUZ1CLlANbzTjLQb3k{display:flex;align-items:center;justify-content:center;margin-top:.32rem}\n", "",{"version":3,"sources":["webpack://src/client/pages/moduleable/home/style.module.scss","webpack://src/client/base.scss"],"names":[],"mappings":"AAEA,6BAEQ,wBAAyB,CACzB,iBCDgC,CDFxC,yCAKY,YAAa,CALzB,kDAUQ,iBCRgC,CDFxC,gDAcQ,iBCZgC,CDanC,yBAID,mBCjBoC,CDkBpC,oBClBoC,CDmBpC,qBCnBoC,CDoBvC,yBAGG,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,iBC1BoC","sourcesContent":["@import \"../../../base.scss\";\n\n.LoadingHome{\n    & > div{\n        background-color: #f7f7f7;\n        margin-top: actual(12);\n        &:first-child{\n            margin-top: 0;\n        }\n    }\n\n    .Block1{\n        height: actual(40);\n    }\n\n    .Block2{\n        height: actual(350);\n    }\n}\n\n.Search{\n    padding-left: actual(12);\n    padding-right: actual(12);\n    padding-bottom: actual(12);\n}\n\n.PayPalMessage{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: actual(12);\n}\n","$baseFontSize: 75; //基于视觉稿横屏尺寸/10得出的基准font-size\n$IMAGE_GEEKO_LTD: 'https://image.geeko.ltd';\n\n@function actual($px) {\n  @return 2 * $px / $baseFontSize * 1rem;\n}\n\n@function actualPc($px){\n  @return $px / 1920 * 100vw;\n}\n\n@function imageGeekoLtd($url){\n\t@return $IMAGE_GEEKO_LTD + $url;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LoadingHome": "_3ikXZWY-pH7ckWvYEV8aIH",
	"Block1": "_1K_4RkPww-j6iXqbNmLbXR",
	"Block2": "TTpNxsjqvPrm-u3IUdROB",
	"Search": "_3DU2LoM7nR5u7DgpV3QMYk",
	"PayPalMessage": "_1K6wvUZ1CLlANbzTjLQb3k"
};
module.exports = ___CSS_LOADER_EXPORT___;
