import Type5 from './type-5'
import Type3 from './type-3'

export const Types = {
    TYPES_5: 'collections-5',
    TYPES_3: 'collections-3',
}

export default type => {
    switch (type) {
        case Types.TYPES_5:
            return Type5
        case Types.TYPES_3:
            return Type3
        default:
            return
    }
}