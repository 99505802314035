import React, { useEffect,useState } from "react";
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { fetchGetMessage,fetchGetMyPrizes } from "../../../../../../api";
import { useSelector,useDispatch } from "react-redux";
import ReactDOM from 'react-dom'
import { BigButton } from "../../../../../components/button";
import { FormattedMessage } from "react-intl";
import { BlackMask } from "../../../../../components/mask";
import { Iconfont } from "../../../../../components/icon/iconfont";
import { TOGGLE_UI_LOGIN } from "../../../../../../store/actions/ui";
import { useApp } from "../../../../../hooks/common";

const LotteryMyPrizes = props =>{
    const [myPrizes,setMyPrizes] = useState(null);
    const [prizesRules,setPrizesRules] = useState(null);
    const global = useSelector(state => state.global);
    const isApp = useApp();

    const getMyPrizes = async () =>{
        let response = await fetchGetMyPrizes();
        if(response?.code === 200){
            setMyPrizes(response?.result);
        }
    }

    const getDataValue = value =>{
        let date = new Date(value).toString().split(" GMT")[0];
        let dateList = date.split(" ");
        return dateList[1]+"."+dateList[2]+"."+dateList[3]+ " "+dateList[4];
    }

    const getPrizesIcon = type =>{
        let icon;
        switch(type){
            case "1":
                icon = <Iconfont className={classes.Icon}>&#xe7e1;</Iconfont>;
                break;
            case "2":
            case "5":
                icon = <Iconfont className={classes.Icon}>&#xe7e0;</Iconfont>;
                break;
            case "3":
                icon = <Iconfont className={classes.Icon} style={{fontSize:12}}>&#xe6f8;</Iconfont>;
                break;
            case "4":
                icon = <Iconfont className={classes.Icon}>&#xe7e3;</Iconfont>;
                break;
            case "6":
                icon = <Iconfont className={classes.Icon} style={{fontSize:12}}>&#xe6f8;</Iconfont>;
                break;
            default:
                icon = <Iconfont className={classes.Icon}>&#xe7e1;</Iconfont>;
        }

        return icon;
    }

    useEffect(async () =>{
        let response = await fetchGetMessage('M1636');
        if(response?.result?.message){
            let content = response.result.message;
            if(isApp){
                content = content.replace('/policy/bonus-point','/policy/bonus-point?isApp=1')
                content = content.replace("/support", `chic-me://chic.me/web?href=${global?.config?.root}/user/support`)
            } else {
                content = content.replace("/support", `/user/support`)
            }
            setPrizesRules(content);
        }

        if(!(!global?.user || !global?.user?.id)){
            getMyPrizes();
        }
    },[]);

    useEffect(() => {
        if (global?.user || global?.user?.id) {
            getMyPrizes();
        }
    }, [global?.user?.id]);

    return <div className={classes.LotteryMyPrizes}>
        <div className={classes.MyPrizesListContainer}>
        {
            myPrizes?.length > 0 && <ul className={classes.MyPrizesList}>
                {
                    myPrizes.map((item, index) =>{
                        return <li key={index}>
                            {
                                item?.prize?.type && <>
                                    <div>
                                        {getPrizesIcon(item?.prize?.type)}
                                        
                                        {
                                            item?.prize?.type == 6 ? 
                                                (<a className={classes.Icon} style='color: #e64444;' href="/cart">"{item?.prize?.name}</a>) : 
                                                (<span className={classes.Font}>{item?.prize?.name}</span>)
                                        }
                                        
                                    </div>

                                    <div className={classes.luckDrawDate}>{getDataValue(item?.luckDrawDate)}</div>
                                </>
                            }
                        </li>
                    })
                }
                
            </ul>
        }
        
        {
            prizesRules ? 
                <div className={classes.PrizesRules} dangerouslySetInnerHTML={{__html:prizesRules}}></div>
                :
                <div className={classes.Loading}><FormattedMessage id="loading" defaultMessage="loading" />...</div>
        }
        </div>
    </div>
}

const LotteryRules = props => {
    const { handleClick } = props;
    const [rules, setRules] = useState(null);
    const isApp = useApp();

    useEffect(async () => {
        let response = await fetchGetMessage("M1586");

        if (response?.code === 200) {
            if (response?.result?.message) {
                let data = JSON.parse(response?.result?.message);
                setRules(data?.rules);
            }
        }
    }, []);


    return <div className={classes.LotteryRules}>
        <div className={classes.RulesBox}>
            <div className={classes.Content}>
                {
                    rules?.length > 0 ? (
                        rules?.map((rule, index) => {
                            return (
                                <div className={classes.RulesItem} key={rule + index}>
                                    <div className={classes.RulesNum}>
                                        {index + 1}.
                                    </div>

                                    <div className={classes.RulesFont}>
                                        {
                                            rule?.title instanceof Array ? (
                                                rule?.title.map((item, index) => <div key={index}>{item}</div>)
                                            ) : (<div>{rule?.title}</div>)
                                        }

                                        {
                                            !isApp && rule?.hasDownload && <BigButton onClick={handleClick} className={classes.RulesButtonApp}><FormattedMessage id="download_app" defaultMessage="Download APP" /></BigButton>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        <div className={classes.Loading}><FormattedMessage id="loading" defaultMessage="loading" />...</div>
                    )

                }
            </div>
        </div>
    </div>
}

const LotteryRulesPrizesItem = props =>{
    const {onClose} = props;
    const global = useSelector(state => state.global);
    const dispatch = useDispatch();
    const [select,setSelect] = useState(() =>(!global?.user || !global?.user?.id)?'rules':'prizes');
    const isApp = useApp();

    const myPrizesEvent = () =>{
        if((!global?.user || !global?.user?.id)){
            if(isApp){
                window.location.href = "chic-me://chic.me/loginRoot"
            }else{
                dispatch({
                    type:TOGGLE_UI_LOGIN,
                    payload: {
                        showLogin:true
                    }
                });
            }
        }else{
            setSelect('prizes');
        }

        if (window.GeekoSensors) {
            window.GeekoSensors.Track('PopUp', {
                clicks:"forgotpassword",
                page_content: "popup",
                is_view:true
            })
        }
    }

    return <div className={classes.LotteryRulesPrizes}>
        <div className={classes.Hd}>
            <div>
                <span 
                    className={select ==='prizes' ? classes.Active : ""}
                    onClick={myPrizesEvent}
                ><FormattedMessage id="my_prize" defaultMessage="My Prizes" /></span>
            </div>
            <div>
                <span 
                    className={select !=='prizes' ? classes.Active : ""}
                    onClick={() =>setSelect('rules')}
                ><FormattedMessage id="rules" defaultMessage="Rules" /></span>
            </div>

            <Iconfont onClick={onClose} className={classes.CloseIcon}>&#xe69a;</Iconfont>
        </div>

        <div className={classes.Bd}>
            <div style={{ display: `${select ==='prizes' ? "block" : "none"}` }}>
                <LotteryMyPrizes {...props} />
            </div>

            <div style={{ display: `${select !=='prizes' ? "block" : "none"}` }}>
                <LotteryRules {...props} />
            </div>
        </div>
    </div>
}

const LotteryRulesPrizes = props =>{
    useStyles(classes);
    const { showRules,onClose } = props;

    return <React.Fragment>
        {
            showRules && ReactDOM.createPortal(<React.Fragment>
                <LotteryRulesPrizesItem {...props} />
                <BlackMask onClick={() => onClose()} style={{zIndex:12}} />
            </React.Fragment>, typeof document !== 'undefined' ? document.getElementById('root') : null)
        }
    </React.Fragment>
}



export default LotteryRulesPrizes;